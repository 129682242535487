import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCPF, formatTelefone, formatDate, normalizeValue } from './utils';
import { convertSvgToPng } from './convertSvgToPng';
import { getPassengerById } from '../../pages/Passenger/services/PassengerService';
import { auth, db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { getAgencyLogo } from '../../pages/UpdateAgency/services/LogoService';

export const exportReservationToPDF = async (reservation, passenger, travel) => {
  try {
    // Get agency information
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    const agencyRef = doc(db, 'agencies', userDoc.data().agencyId);
    const agencyDoc = await getDoc(agencyRef);
    const agencyData = agencyDoc.data();

    // Get agency logo
    let logoData = null;
    try {
      logoData = await getAgencyLogo(userDoc.data().agencyId);
    } catch (error) {
      console.warn('Unable to fetch agency logo:', error);
    }

    // Get owner's phone
    const ownerRef = doc(db, 'users', agencyData.ownerId);
    const ownerDoc = await getDoc(ownerRef);
    const ownerPhone = ownerDoc.data().phone || 'NÃO INFORMADO';

    const pdfDoc = new jsPDF('portrait', 'pt');
    const pageHeight = pdfDoc.internal.pageSize.height;
    const pageWidth = pdfDoc.internal.pageSize.width;
    const leftMargin = 35;
    const rightMargin = pageWidth - 35;
    const contentWidth = rightMargin - leftMargin;

    // Helper functions
    const drawPageBorder = () => {
      pdfDoc.setDrawColor(200);
      pdfDoc.setLineWidth(0.5);
      pdfDoc.rect(leftMargin, 30, contentWidth, pageHeight - 60);
    };

    const createDataBox = (label, value, x, y, width, height = 35) => {
      try {
        pdfDoc.setDrawColor(200);
        pdfDoc.setLineWidth(0.5);
        pdfDoc.rect(x, y, width, height);

        pdfDoc.setFontSize(8);
        pdfDoc.setFont('helvetica', 'bold');
        pdfDoc.text(label, x + 6, y + 12);

        pdfDoc.setFontSize(9);
        pdfDoc.setFont('helvetica', 'normal');
        pdfDoc.text(String(value || 'NÃO INFORMADO'), x + 6, y + 26);
      } catch (error) {
        console.error('Error in createDataBox:', error);
      }
    };

    // Format current date and time
    const now = new Date();
    const formattedDate = now.toLocaleDateString('pt-BR');
    const formattedTime = now.toLocaleTimeString('pt-BR');
    const dateTimeStr = `${formattedDate} ${formattedTime}`;

    // Draw initial border
    drawPageBorder();

    // Set up header
    pdfDoc.setFontSize(11);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Detalhes da Reserva', pageWidth / 2, 20, { align: 'center' });

    // Start Y position
    let yPos = 45;

    // Handle logo display
    if (logoData && logoData.base64) {
      try {
        let imageData = logoData.base64;
        const imageFormat = imageData.startsWith('data:image/svg') ? 'SVG' : 'PNG';

        if (imageFormat === 'SVG') {
          imageData = await convertSvgToPng(logoData.base64);
        }

        const img = new Image();
        img.src = imageData;

        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });

        const maxHeight = 60;
        const aspectRatio = img.width / img.height;
        const logoHeight = Math.min(maxHeight, img.height);
        const logoWidth = logoHeight * aspectRatio;
        const centerX = leftMargin + (contentWidth - logoWidth) / 2;

        pdfDoc.addImage(imageData, 'PNG', centerX, yPos, logoWidth, logoHeight);
        yPos += logoHeight + 15;
      } catch (error) {
        console.error('Error adding logo to PDF:', error);
      }
    }

    // Agency Information
    pdfDoc.setFontSize(12);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text(agencyData.name || 'NÃO INFORMADO', leftMargin + 5, yPos);
    pdfDoc.setFontSize(8);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.text(`Tel: ${ownerPhone}`, leftMargin + 5, yPos + 12);

    // Reservation ID and Date
    yPos += 25;
    const columnWidth = contentWidth / 2 - 5;

    createDataBox('Número da Reserva', reservation.id || 'NÃO INFORMADO',
      leftMargin + 2, yPos, columnWidth);

    createDataBox('Data e Hora', dateTimeStr,
      leftMargin + columnWidth + 8, yPos, columnWidth);

    // Seat Information
    yPos += 40;
    createDataBox('Assento',
      reservation.lapPassenger ? 'Ocupante de Colo' : (reservation.numeroAssento || 'NÃO INFORMADO'),
      leftMargin + 2, yPos, contentWidth - 4);

    // Travel Information
    yPos += 55;
    pdfDoc.setFontSize(10);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Informações da Viagem', leftMargin + 5, yPos);

    yPos += 5;
    createDataBox('Local de Origem', travel.origem || 'NÃO INFORMADO',
      leftMargin + 2, yPos, columnWidth);

    createDataBox('Local de Destino', travel.destino || 'NÃO INFORMADO',
      leftMargin + columnWidth + 8, yPos, columnWidth);

    // Travel Dates
    yPos += 40;
    const formattedIda = travel.dataIda ? `${formatDate(travel.dataIda)} ${travel.horarioIda || ''}` : 'NÃO INFORMADO';
    createDataBox('Data e Horário de Ida', formattedIda.trim(),
      leftMargin + 2, yPos, columnWidth);

    let returnInfo;
    if (travel.somenteIda) {
      returnInfo = 'SOMENTE IDA';
    } else {
      returnInfo = travel.dataRetorno ?
        `${formatDate(travel.dataRetorno)} ${travel.horarioRetorno || ''}` :
        'NÃO INFORMADO';
    }

    createDataBox('Data e Horário de Retorno', returnInfo.trim(),
      leftMargin + columnWidth + 8, yPos, columnWidth);

    // Passenger Information
    yPos += 55;
    pdfDoc.setFontSize(10);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Informações do Passageiro', leftMargin + 5, yPos);

    yPos += 5;
    createDataBox('Nome', passenger.nome || 'NÃO INFORMADO',
      leftMargin + 2, yPos, contentWidth - 4);

    yPos += 40;
    createDataBox('CPF', passenger.cpf ? formatCPF(passenger.cpf) : 'NÃO INFORMADO',
      leftMargin + 2, yPos, columnWidth);

    const passengerDoc = passenger.estrangeiro ?
      `Passaporte: ${passenger.passaporte || 'NÃO INFORMADO'}` :
      `RG: ${passenger.rg || 'NÃO INFORMADO'}`;

    createDataBox('Documento', passengerDoc,
      leftMargin + columnWidth + 8, yPos, columnWidth);

    if (passenger.telefone) {
      yPos += 40;
      createDataBox('Telefone', formatTelefone(passenger.telefone),
        leftMargin + 2, yPos, columnWidth);
    }

    // Responsible Person Information (if applicable)
    if (passenger.responsavelId) {
      yPos += 55;
      pdfDoc.setFontSize(10);
      pdfDoc.setFont('helvetica', 'bold');
      pdfDoc.text('Informações do Responsável', leftMargin + 5, yPos);

      const responsavel = await getPassengerById(passenger.responsavelId);
      if (responsavel) {
        yPos += 5;
        createDataBox('Nome', responsavel.nome || 'NÃO INFORMADO',
          leftMargin + 2, yPos, contentWidth - 4);

        yPos += 40;
        createDataBox('CPF', responsavel.cpf ? formatCPF(responsavel.cpf) : 'NÃO INFORMADO',
          leftMargin + 2, yPos, columnWidth);

        const respDoc = responsavel.estrangeiro ?
          `Passaporte: ${responsavel.passaporte || 'NÃO INFORMADO'}` :
          `RG: ${responsavel.rg || 'NÃO INFORMADO'}`;

        createDataBox('Documento', respDoc,
          leftMargin + columnWidth + 8, yPos, columnWidth);

        if (responsavel.telefone) {
          yPos += 40;
          createDataBox('Telefone', formatTelefone(responsavel.telefone),
            leftMargin + 2, yPos, columnWidth);
        }
      }
    }

    // Payment Information
    yPos += 55;
    pdfDoc.setFontSize(10);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Informações de Pagamento', leftMargin + 5, yPos);

    yPos += 5;
    const detalhesPagamento = reservation.detalhesPagamento || {};

    createDataBox('Nome do Pagador', detalhesPagamento.nomePagador || 'NÃO INFORMADO',
      leftMargin + 2, yPos, contentWidth - 4);

    yPos += 40;
    createDataBox('Método de Pagamento', detalhesPagamento.metodoPagamento || 'NÃO INFORMADO',
      leftMargin + 2, yPos, columnWidth);

    createDataBox('Status', reservation.status || 'NÃO INFORMADO',
      leftMargin + columnWidth + 8, yPos, columnWidth);

    yPos += 40;
    createDataBox('Valor Total', `R$ ${normalizeValue(detalhesPagamento.valorTotal || 0)}`,
      leftMargin + 2, yPos, columnWidth);

    createDataBox('Valor Pago', `R$ ${normalizeValue(detalhesPagamento.valorPago || 0)}`,
      leftMargin + columnWidth + 8, yPos, columnWidth);

    // Footer
    pdfDoc.setFontSize(8);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.setTextColor(0, 0, 0);

    const prefix = 'Documento gerado automaticamente por ';
    const linkText = 'Travell - Gerenciamento de Viagens e Excursões';
    const prefixWidth = pdfDoc.getTextWidth(prefix);
    const linkWidth = pdfDoc.getTextWidth(linkText);
    const totalWidth = prefixWidth + linkWidth;
    const startX = (pageWidth - totalWidth) / 2;

    pdfDoc.text(prefix, startX, pageHeight - 20);
    pdfDoc.setTextColor(0, 0, 255);
    pdfDoc.link(startX + prefixWidth, pageHeight - 25, linkWidth, 10, { url: 'https://travell.com.br' });
    pdfDoc.text(linkText, startX + prefixWidth, pageHeight - 20);

    // Save the PDF
    const fileName = passenger.nome ?
      `Reserva_${passenger.nome.replace(/[^a-zA-Z0-9]/g, '_')}.pdf` :
      `Reserva_${reservation.id}.pdf`;

    pdfDoc.save(fileName);
    return true;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate reservation PDF');
  }
};

export default exportReservationToPDF;