import React, { useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, InputAdornment, Tooltip, IconButton, Button, Typography, Box, Alert, Autocomplete, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PaymentRecordsHelp from '../helps/PaymentRecordsHelp';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { getBankAccounts } from '../../BankAccount/services/BankAccountService';
import { useCurrencyInput, currencyNames } from './../../../shared/utils/formatCurrency';

const PaymentRecordRow = ({
  record,
  index,
  onValueChange,
  onBlur,
  errors,
  handleRemoveRecord,
  handlePaymentRecordChange,
  bankAccounts,
  loadingAccounts,
  handleFetchAccounts,
  markFieldAsTouched
}) => {
  const theme = useTheme();
  const initialValue = record.valor ?
    String(parseFloat(record.valor.replace(',', '.')).toFixed(2)).replace('.', ',') :
    '0,00';

  const {
    value: valorTemp,
    handleChange: handleValorChange,
    handleBlur: handleValorBlur,
    toNumber: getValorNumber,
    setValue: setValorTemp
  } = useCurrencyInput(initialValue);

  useEffect(() => {
    if (record.valor) {
      const numericValue = parseFloat(record.valor.replace(',', '.'));
      if (!isNaN(numericValue)) {
        setValorTemp(numericValue.toFixed(2).replace('.', ','));
      }
    }
  }, [record.valor]);

  // Handler for field changes that tracks the touched state
  const handleFieldChange = (field, value) => {
    markFieldAsTouched(index, field);
    handlePaymentRecordChange(index, field, value);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={3}>
        <TextField
          label="Data de Pagamento"
          type="date"
          value={record.data || ''}
          onChange={(e) => handleFieldChange('data', e.target.value)}
          fullWidth
          required
          error={!!errors[`data-${index}`]}
          helperText={errors[`data-${index}`]}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ backgroundColor: theme.palette.background.paper }}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          label="Valor do Pagamento"
          type="text"
          value={valorTemp}
          onChange={(e) => {
            handleValorChange(e);
            onValueChange(index, e.target.value);
          }}
          onBlur={() => {
            handleValorBlur();
            const numericValue = getValorNumber();
            const formattedValueForDisplay = numericValue.toFixed(2).replace('.', ',');
            const formattedValueForDB = numericValue.toFixed(2);
            onBlur(index, formattedValueForDB);
            setValorTemp(formattedValueForDisplay);
          }}
          fullWidth
          required
          error={!!errors[`valor-${index}`]}
          helperText={errors[`valor-${index}`]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currencyNames['BRL'].symbol}
              </InputAdornment>
            ),
            inputMode: 'numeric'
          }}
          sx={{ backgroundColor: theme.palette.background.paper }}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <TextField
          select
          label="Método de Pagamento"
          value={record.metodoPagamento || ''}
          onChange={(e) => handleFieldChange('metodoPagamento', e.target.value)}
          fullWidth
          required
          error={!!errors[`metodoPagamento-${index}`]}
          helperText={errors[`metodoPagamento-${index}`]}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <MenuItem value="Dinheiro">Dinheiro</MenuItem>
          <MenuItem value="Pix">Pix</MenuItem>
          <MenuItem value="Cartão">Cartão</MenuItem>
          <MenuItem value="Boleto">Boleto</MenuItem>
          <MenuItem value="Outro">Outro</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} sm={1.5}>
        <TextField
          select
          label="Status"
          value={record.statusPagamento || 'Pago'}
          onChange={(e) => handleFieldChange('statusPagamento', e.target.value)}
          fullWidth
          required
          error={!!errors[`statusPagamento-${index}`]}
          helperText={errors[`statusPagamento-${index}`]}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <MenuItem value="Pago">Pago</MenuItem>
          <MenuItem value="Pendente">Pendente</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} sm={2}>
        <Autocomplete
          fullWidth
          options={bankAccounts[index] || []}
          getOptionLabel={(option) => option.name}
          value={bankAccounts[index]?.find(acc => acc.id === record.bankAccountId) || null}
          onChange={(e, value) => {
            markFieldAsTouched(index, 'bankAccountId');
            handlePaymentRecordChange(index, 'bankAccountId', value?.id || null);
          }}
          onOpen={() => handleFetchAccounts(index)}
          loading={loadingAccounts[index]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Conta Bancária"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingAccounts[index] ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={0.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title="Remover registro de pagamento">
          <IconButton
            onClick={() => handleRemoveRecord(index)}
            sx={{ ml: 'auto' }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const PaymentRecords = ({
  paymentRecords,
  handlePaymentRecordChange,
  handleRemovePaymentRecord,
  handleAddPaymentRecord,
  detalhesPagamento,
  setErrors,
}) => {
  const theme = useTheme();
  const [localErrors, setLocalErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingAccounts, setLoadingAccounts] = useState({});
  const [bankAccounts, setBankAccounts] = useState({});
  // Add a touchedFields state to track which fields have been interacted with
  const [touchedFields, setTouchedFields] = useState({});

  useEffect(() => {
    const loadInitialBankAccounts = async () => {
      try {
        const accounts = await getBankAccounts();
        const initialBankAccounts = {};

        paymentRecords.forEach((_, index) => {
          initialBankAccounts[index] = accounts;
        });

        setBankAccounts(initialBankAccounts);
      } catch (error) {
        console.error('Error loading initial bank accounts:', error);
      }
    };

    loadInitialBankAccounts();
  }, []);

  // We're not running validation on initial load anymore
  // This effect only watches for changes in paymentRecords and validates
  // only touched fields
  useEffect(() => {
    if (Object.keys(touchedFields).length > 0) {
      validateAllPayments(paymentRecords);
    }
  }, [paymentRecords, touchedFields]);

  const handleFetchAccounts = async (index) => {
    if (bankAccounts[index]) return;

    setLoadingAccounts(prev => ({ ...prev, [index]: true }));
    try {
      const accounts = await getBankAccounts();
      setBankAccounts(prev => ({ ...prev, [index]: accounts }));
    } catch (error) {
      console.error('Error fetching bank accounts:', error);
    } finally {
      setLoadingAccounts(prev => ({ ...prev, [index]: false }));
    }
  };

  const markFieldAsTouched = (index, field) => {
    setTouchedFields(prev => ({
      ...prev,
      [`${field}-${index}`]: true
    }));
  };

  const validatePayment = (index, field, value, updatedRecords) => {
    const activeRecords = updatedRecords.filter(record => !record.removed);
    let error = '';

    // Only validate if the field has been touched/modified
    if (touchedFields[`${field}-${index}`]) {
      // Field-specific validation
      if (field === 'valor') {
        const numericValue = parseFloat(value.replace(',', '.')) || 0;
        if (numericValue <= 0) {
          error = 'O valor do pagamento deve ser maior que zero';
        }

        // Check if total exceeds the allowed amount when changing a valor field
        const totalPaid = calculateTotalPaidAmount(activeRecords, index, numericValue);

        const roundedTotalPaid = Number(totalPaid.toFixed(2));
        const valorTotal = Number((parseFloat(detalhesPagamento.valorTotal?.replace(',', '.')) || 0).toFixed(2));

        if (roundedTotalPaid > valorTotal) {
          error = 'O valor ultrapassa o limite do valor total';
        }
      } else if (field === 'data') {
        if (!value) {
          error = 'A data de pagamento é obrigatória';
        }
      } else if (field === 'metodoPagamento') {
        if (!value) {
          error = 'O método de pagamento é obrigatório';
        }
      } else if (field === 'statusPagamento') {
        if (!value) {
          error = 'O status de pagamento é obrigatório';
        }
      }
    }

    // Update local errors state
    setLocalErrors(prevErrors => ({
      ...prevErrors,
      [`${field}-${index}`]: error
    }));

    // Update parent component's error state
    const allErrors = { ...localErrors, [`${field}-${index}`]: error };
    const hasErrors = Object.values(allErrors).some(e => !!e);

    setErrors(prevErrors => ({
      ...prevErrors,
      paymentRecord: hasErrors
    }));

    return error === '';
  };

  // Helper function to calculate total paid amount considering only 'Pago' status
  const calculateTotalPaidAmount = (records, changedIndex = null, changedValue = null) => {
    return records.reduce((total, record, idx) => {
      if (record.statusPagamento === 'Pendente') {
        return total;
      }

      const recordValue = idx === changedIndex
        ? changedValue
        : parseFloat(record.valor?.replace(',', '.')) || 0;

      return total + recordValue;
    }, 0);
  };

  const validateAllPayments = (updatedRecords) => {
    const activeRecords = updatedRecords.filter(record => !record.removed);
    let hasErrors = false;
    let newErrors = { ...localErrors };

    // Only validate fields that have been touched
    activeRecords.forEach((record, index) => {
      // Validate valor
      if (touchedFields[`valor-${index}`]) {
        const numericValue = parseFloat(record.valor?.replace(',', '.')) || 0;
        if (numericValue <= 0) {
          newErrors[`valor-${index}`] = 'O valor do pagamento deve ser maior que zero';
          hasErrors = true;
        } else {
          newErrors[`valor-${index}`] = '';
        }
      }

      // Validate data
      if (touchedFields[`data-${index}`]) {
        if (!record.data) {
          newErrors[`data-${index}`] = 'A data de pagamento é obrigatória';
          hasErrors = true;
        } else {
          newErrors[`data-${index}`] = '';
        }
      }

      // Validate método de pagamento
      if (touchedFields[`metodoPagamento-${index}`]) {
        if (!record.metodoPagamento) {
          newErrors[`metodoPagamento-${index}`] = 'O método de pagamento é obrigatório';
          hasErrors = true;
        } else {
          newErrors[`metodoPagamento-${index}`] = '';
        }
      }

      // Validate status de pagamento
      if (touchedFields[`statusPagamento-${index}`]) {
        if (!record.statusPagamento) {
          newErrors[`statusPagamento-${index}`] = 'O status de pagamento é obrigatório';
          hasErrors = true;
        } else {
          newErrors[`statusPagamento-${index}`] = '';
        }
      }
    });

    // Check if total exceeds allowed amount - only if any valor field has been touched
    const anyValorTouched = activeRecords.some((_, idx) => touchedFields[`valor-${idx}`]);

    if (anyValorTouched) {
      const totalPaid = calculateTotalPaidAmount(activeRecords);

      const roundedTotalPaid = Number(totalPaid.toFixed(2));
      const valorTotal = Number((parseFloat(detalhesPagamento.valorTotal?.replace(',', '.')) || 0).toFixed(2));
      const exceedsTotal = roundedTotalPaid > valorTotal;

      if (exceedsTotal) {
        // Apply this error to the last touched valor field
        const touchedValorIndices = activeRecords
          .map((_, idx) => idx)
          .filter(idx => touchedFields[`valor-${idx}`]);

        if (touchedValorIndices.length > 0) {
          const lastTouchedIndex = touchedValorIndices[touchedValorIndices.length - 1];
          newErrors[`valor-${lastTouchedIndex}`] = 'A soma dos pagamentos excede o valor total';
          hasErrors = true;
        }
      }
    }

    // Update the local errors state
    setLocalErrors(newErrors);

    // Update the parent component's error state
    setErrors(prev => ({
      ...prev,
      paymentRecord: hasErrors
    }));

    setErrorMessage('');
  };

  const handleRemoveRecord = (index) => {
    // Create a copy of records with the specified record marked as removed
    const updatedRecords = paymentRecords.map((record, idx) =>
      idx === index ? { ...record, removed: true } : record
    );

    // Call the parent component's remove handler
    handleRemovePaymentRecord(index);

    // Remove all error entries related to this record
    setLocalErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      // Clean up all error fields for this record
      Object.keys(newErrors).forEach(key => {
        if (key.endsWith(`-${index}`)) {
          delete newErrors[key];
        }
      });
      return newErrors;
    });

    // Remove all touched fields entries for this record
    setTouchedFields(prevTouched => {
      const newTouched = { ...prevTouched };
      Object.keys(newTouched).forEach(key => {
        if (key.endsWith(`-${index}`)) {
          delete newTouched[key];
        }
      });
      return newTouched;
    });

    // Re-validate all remaining active records
    setTimeout(() => {
      const remainingRecords = updatedRecords.filter(record => !record.removed);
      validateAllPayments(remainingRecords);
    }, 0);
  };

  const handleValueChange = (index, value) => {
    markFieldAsTouched(index, 'valor');
    validatePayment(index, 'valor', value, paymentRecords);
  };

  const handleBlur = (index, value) => {
    markFieldAsTouched(index, 'valor');
    handlePaymentRecordChange(index, 'valor', value);
    validatePayment(index, 'valor', value, paymentRecords);
  };

  // Handle new payment record adding - need to reset touched and error states for new record
  const handleAddNewPaymentRecord = () => {
    // First call the parent handler
    handleAddPaymentRecord();

    // After a new record is added, reset touched state for any removed records
    // to prevent old validation errors
    setTimeout(() => {
      const newRecordIndex = paymentRecords.length;

      // Initialize with default status to avoid immediate validation error
      if (paymentRecords[newRecordIndex]) {
        handlePaymentRecordChange(newRecordIndex, 'statusPagamento', 'Pago');
      }
    }, 0);
  };

  return (
    <Box sx={{ mb: 3, p: 3, border: `1px solid ${theme.palette.divider}`, borderRadius: 2, backgroundColor: theme.palette.background.paper }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary, marginRight: 1 }}>
            Registros de Pagamento
          </Typography>
          <PaymentRecordsHelp />
        </Box>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={handleAddNewPaymentRecord}
          sx={{
            borderRadius: '8px',
            color: theme.palette.action,
          }}
        >
          Adicionar Pagamento
        </Button>
      </Box>
      {paymentRecords.map((record, index) => (
        <Box
          key={index}
          sx={{
            mb: 2,
            p: 2,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <PaymentRecordRow
            record={record}
            index={index}
            onValueChange={handleValueChange}
            onBlur={handleBlur}
            errors={localErrors}
            handleRemoveRecord={handleRemoveRecord}
            handlePaymentRecordChange={handlePaymentRecordChange}
            bankAccounts={bankAccounts}
            loadingAccounts={loadingAccounts}
            handleFetchAccounts={handleFetchAccounts}
            markFieldAsTouched={markFieldAsTouched}
          />
        </Box>
      ))}
      {errorMessage && (
        <Alert
          severity="error"
          sx={{
            mt: 2,
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.info
          }}
        >
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
};

export default PaymentRecords;