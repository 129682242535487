import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Drawer,
  IconButton,
  CssBaseline,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  Collapse
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import SidebarNavigation from './SidebarNavigation';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';

import Logo from '../../assets/travell-logo-white.svg';
import SidebarItem from './SidebarItem';
import TrialNotificationModal from './TrialNotificationModal';

import { logout } from '../../pages/Login/services/LoginService';
import { useAuth } from '../../core/contexts/useAuthState';
import { useDrawer } from '../../core/contexts/DrawerContext';
import { useIdleTimer } from 'react-idle-timer';
import { isBusinessAgency } from '../../pages/UpdateAgency/services/AgencyService';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, showSidebar }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: showSidebar ? `-${drawerWidth}px` : 0,
    ...(open && showSidebar && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 26,
  justifyContent: 'flex-end',
  minHeight: 20,
}));

const Layout = ({ children, showSidebar = true, defaultOpenDrawer = true, hideLogout = false, toggleColorMode, mode }) => {
  const theme = useTheme();
  const { openDrawer, toggleDrawer, closeDrawer } = useDrawer();
  const [loading, setLoading] = useState(false);
  const { currentUser, hasPermission } = useAuth();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showAgencyMenu, setShowAgencyMenu] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);

  useEffect(() => {
    if (currentUser?.userData?.agency?.subscription?.planId === 'trial') {
      setShowTrialModal(true);
    }
  }, [currentUser]);

  useEffect(() => {
    const checkAgencyType = async () => {
      if (currentUser?.uid) {
        const isBusiness = await isBusinessAgency(currentUser.uid);
        setShowAgencyMenu(isBusiness);
      }
    };

    checkAgencyType();
  }, [currentUser]);

  useEffect(() => {
    if (!defaultOpenDrawer) {
      closeDrawer();
    }
  }, [defaultOpenDrawer, closeDrawer]);

  const getRemainingDays = () => {
    if (!currentUser?.userData?.agency?.subscription) return 0;

    const endDate = new Date(currentUser.userData.agency.subscription.endDate);
    const now = new Date();
    const diffTime = endDate - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? diffDays : 0;
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logout();
    } catch (error) {
      console.error("Falha ao sair:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnIdle = () => {
    setSnackbarOpen(true);
  };

  const idleTimer = useIdleTimer({
    timeout: 300000,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const handleSnackbarClick = () => {
    navigate('/central-ajuda');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          height: 48,
          justifyContent: 'center'
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: '48px !important',
            height: 48,
            display: 'flex',
            alignItems: 'center',
            px: 2,
            '& .MuiIconButton-root': {
              padding: 1,
              width: 28,
              height: 28,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            },
            '& .MuiSvgIcon-root': {
              fontSize: 20
            }
          }}
        >
          {showSidebar && (
            <Tooltip title="Menu">
              <IconButton
                size="small"
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer}
                sx={{
                  mr: 1,
                  color: 'white',
                }}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}
          <RouterLink
            to="/"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            <img src={Logo} alt="Logo" style={{ height: 20, marginRight: 5 }} />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          {currentUser?.userData?.agency?.subscription?.planId === 'trial' && (
            <Box
              onClick={() => setShowTrialModal(true)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '50px',
                padding: '4px 12px',
                marginRight: 2,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)'
                },
                transition: 'background-color 0.2s ease'
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: 'white',
                  fontWeight: 'medium'
                }}
              >
                {getRemainingDays()} dias restantes de teste grátis
              </Typography>
            </Box>
          )}

          <Tooltip title={mode === 'dark' ? 'Ativar modo claro' : 'Ativar modo escuro'}>
            <IconButton
              onClick={toggleColorMode}
              aria-label="Theme toggle button"
              size="small"
              sx={{
                mr: 1,
                color: 'white'
              }}
            >
              {mode === 'dark' ?
                <WbSunnyRoundedIcon /> :
                <ModeNightRoundedIcon />
              }
            </IconButton>
          </Tooltip>

          <RouterLink
            to="/central-ajuda"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            <Tooltip title="Ajuda">
              <IconButton
                size="small"
                sx={{
                  mr: 1,
                  color: 'white'
                }}
              >
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </RouterLink>

          {!hideLogout && !loading && currentUser && (
            <Tooltip title="Sair">
              <IconButton
                size="small"
                onClick={handleLogout}
                sx={{
                  color: 'white'
                }}
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          )}
          {loading && !hideLogout && <CircularProgress color="inherit" size={16} />}
        </Toolbar>
      </AppBar>

      {showSidebar && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={openDrawer}
        >
          <DrawerHeader>
          </DrawerHeader>
          <SidebarNavigation
            currentUser={currentUser}
            hasPermission={hasPermission}
          />

          <Box sx={{ mt: 'auto', mb: 2, px: 2 }}>
            <Divider sx={{ mb: 1 }} />

            <SidebarItem
              to="/agencia"
              icon={<BusinessIcon />}
              text="Minha Agência"
              sx={{
                py: 1,
                '& .MuiListItemIcon-root': {
                  minWidth: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              }}
            />

            <SidebarItem
              to="/conta"
              icon={<AccountCircleIcon />}
              text={currentUser?.displayName || 'Minha Conta'}
              sx={{
                py: 1.5,
                '& .MuiListItemIcon-root': {
                  minWidth: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              }}
            />
          </Box>
        </Drawer>
      )}

      <Main open={showSidebar && openDrawer} showSidebar={showSidebar}>
        <DrawerHeader />
        {children}
      </Main>

      <TrialNotificationModal
        open={showTrialModal}
        onClose={() => setShowTrialModal(false)}
        daysRemaining={getRemainingDays()}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setSnackbarOpen(false);
        }}
      >
        <Alert
          severity="info"
          sx={{ width: '100%', cursor: 'pointer' }}
          onClick={handleSnackbarClick}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(event) => {
                event.stopPropagation();
                setSnackbarOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          Parece que você está inativo. Caso precise de ajuda, <strong>clique aqui</strong> ou pressione F1 para acessar a Central de Ajuda.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Layout;
