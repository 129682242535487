import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    Alert,
    CircularProgress,
    Divider,
    Paper,
    InputAdornment
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';

import TravelLoading from '../../shared/common/TravelLoading';

import { useCurrencyInput } from '../../shared/utils/formatCurrency';
import {
    getPricingCalculationByTravelId,
    updatePricingCalculation,
    calculateTotals
} from './services/PricingCalculatorService';
import { getTravelById } from '../Travel/services/TravelService';

const PricingCalculatorPage = () => {
    const { travelId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [travelInfo, setTravelInfo] = useState(null);

    const [fixedCosts, setFixedCosts] = useState([
        { description: '', value: '0,00' }
    ]);
    const [variableCosts, setVariableCosts] = useState([
        { description: '', value: '0,00' }
    ]);
    const [totalSeats, setTotalSeats] = useState();
    const [occupiedSeats, setOccupiedSeats] = useState();
    const [profitMargin, setProfitMargin] = useState(30);
    const [lastCalculation, setLastCalculation] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                const [pricingData, travel] = await Promise.all([
                    getPricingCalculationByTravelId(travelId),
                    getTravelById(travelId)
                ]);

                setFixedCosts(pricingData.fixedCosts);
                setVariableCosts(pricingData.variableCosts);
                setTotalSeats(pricingData.totalSeats);
                setOccupiedSeats(pricingData.occupiedSeats);
                setProfitMargin(pricingData.profitMargin);
                setLastCalculation(pricingData.lastCalculation);
                setTravelInfo(travel);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [travelId]);

    const handleAddCost = (type) => {
        if (type === 'fixed') {
            setFixedCosts([...fixedCosts, { description: '', value: '0,00' }]);
        } else {
            setVariableCosts([...variableCosts, { description: '', value: '0,00' }]);
        }
    };

    const handleRemoveCost = (index, type) => {
        if (type === 'fixed') {
            setFixedCosts(fixedCosts.filter((_, i) => i !== index));
        } else {
            setVariableCosts(variableCosts.filter((_, i) => i !== index));
        }
    };

    const handleCostChange = (index, field, value, type) => {
        if (type === 'fixed') {
            const newCosts = [...fixedCosts];
            newCosts[index] = { ...newCosts[index], [field]: value };
            setFixedCosts(newCosts);
        } else {
            const newCosts = [...variableCosts];
            newCosts[index] = { ...newCosts[index], [field]: value };
            setVariableCosts(newCosts);
        }
    };

    const handleSave = async () => {
        try {
            setSaving(true);
            const calculationData = {
                fixedCosts,
                variableCosts,
                totalSeats,
                occupiedSeats,
                profitMargin
            };

            const result = await updatePricingCalculation(travelId, calculationData);
            setLastCalculation(result.lastCalculation);
            setSuccess('Cálculo salvo com sucesso!');
        } catch (err) {
            setError(err.message);
        } finally {
            setSaving(false);
        }
    };

    const totals = calculateTotals({
        fixedCosts,
        variableCosts,
        totalSeats,
        occupiedSeats,
        profitMargin
    });

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <TravelLoading />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            {/* Header */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="h1">
                    Calculadora de Preços
                </Typography>
                <Tooltip title="Calcule o preço ideal baseado nos custos da viagem">
                    <IconButton size="small" sx={{ ml: 1 }}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {/* Travel Info */}
            {travelInfo && (
                <Paper sx={{ p: 2, mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Viagem: {travelInfo.identificador}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {travelInfo.origem} → {travelInfo.destino}
                    </Typography>
                </Paper>
            )}

            {/* Alerts */}
            {error && (
                <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}

            {success && (
                <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess(null)}>
                    {success}
                </Alert>
            )}

            <Grid container spacing={3}>
                {/* Fixed Costs Section */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Despesas Fixas
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell align="right" sx={{ pr: 3 }}>Valor</TableCell>
                                            <TableCell width="50px"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fixedCosts.map((cost, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        value={cost.description}
                                                        onChange={(e) => handleCostChange(index, 'description', e.target.value, 'fixed')}
                                                        placeholder="Descrição"
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        size="small"
                                                        value={cost.value}
                                                        onChange={(e) => handleCostChange(index, 'value', e.target.value, 'fixed')}
                                                        sx={{
                                                            '& input': {
                                                                textAlign: 'right',
                                                                paddingRight: '8px'
                                                            },
                                                            width: '120px'
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleRemoveCost(index, 'fixed')} size="small">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button
                                startIcon={<AddIcon />}
                                onClick={() => handleAddCost('fixed')}
                                sx={{ mt: 2 }}
                            >
                                Adicionar Despesa Fixa
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Variable Costs Section */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Despesas Variáveis
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell align="right" sx={{ pr: 3 }}>Valor</TableCell>
                                            <TableCell width="50px"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {variableCosts.map((cost, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        value={cost.description}
                                                        onChange={(e) => handleCostChange(index, 'description', e.target.value, 'variable')}
                                                        placeholder="Descrição"
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TextField
                                                        size="small"
                                                        value={cost.value}
                                                        onChange={(e) => handleCostChange(index, 'value', e.target.value, 'variable')}
                                                        sx={{
                                                            '& input': {
                                                                textAlign: 'right',
                                                                paddingRight: '8px'
                                                            },
                                                            width: '120px'
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleRemoveCost(index, 'variable')} size="small">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button
                                startIcon={<AddIcon />}
                                onClick={() => handleAddCost('variable')}
                                sx={{ mt: 2 }}
                            >
                                Adicionar Despesa Variável
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Configuration Section */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label="Total de Assentos"
                                        type="number"
                                        value={totalSeats}
                                        onChange={(e) => setTotalSeats(parseInt(e.target.value))}
                                        helperText={travelInfo?.veiculo ?
                                            `Sugestão baseada no veículo: ${travelInfo?.layout?.assentosTotais || 46} assentos` :
                                            "Informe o total de assentos"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label="Assentos Ocupados"
                                        type="number"
                                        value={occupiedSeats}
                                        onChange={(e) => setOccupiedSeats(parseInt(e.target.value))}
                                        InputProps={{
                                            readOnly: !!travelInfo?.numReservas,
                                        }}
                                        helperText={travelInfo?.numReservas ? "Baseado nas reservas atuais" : ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        label="Margem de Lucro (%)"
                                        type="number"
                                        value={profitMargin}
                                        onChange={(e) => setProfitMargin(parseInt(e.target.value))}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Results Section */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Resultados
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <Typography variant="subtitle2">Total Despesas Fixas</Typography>
                                    <Typography variant="h6">R$ {totals.totalFixed}</Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Typography variant="subtitle2">Total Despesas Variáveis</Typography>
                                    <Typography variant="h6">R$ {totals.totalVariable}</Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Typography variant="subtitle2">Custo Total</Typography>
                                    <Typography variant="h6">R$ {totals.totalCost}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="subtitle2">Custo por Assento</Typography>
                                    <Typography variant="h6">R$ {totals.costPerSeat}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="subtitle2" color="primary">
                                        Preço Sugerido por Assento
                                    </Typography>
                                    <Typography variant="h6" color="primary">
                                        R$ {totals.pricePerSeat}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 2 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Mínimo de assentos para cobrir custos:
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            color="text.secondary"
                                        >
                                            {totals.minSeatsToBreakeven} assentos
                                        </Typography>
                                        {totals.minSeatsToBreakeven > totalSeats && (
                                            <Typography variant="body2" color="error">
                                                (Aviso: Este valor é maior que o total de assentos disponíveis)
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Save Button */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        startIcon={saving && <CircularProgress size={20} />}
                        onClick={handleSave}
                        disabled={saving}
                        sx={{ borderRadius: '50px' }}
                    >
                        Salvar Cálculo
                    </Button>
                </Grid>

                {/* Last Calculation Info */}
                {lastCalculation && (
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Último cálculo realizado em: {new Date(lastCalculation.timestamp).toLocaleString()}
                            </Typography>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default PricingCalculatorPage;