import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Divider,
    FormGroup,
    FormControlLabel,
    Switch,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
    Grid,
    Card,
    CardContent,
    IconButton,
    Tooltip,
    useTheme,
    alpha,
    Stack,
    Paper,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Badge,
    styled,
    Container
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BadgeIcon from '@mui/icons-material/Badge';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PassportIcon from '@mui/icons-material/Flight';

import {
    getPassengerFieldSettings,
    updatePassengerFieldSettings,
    resetPassengerFieldSettings
} from '../services/SettingsService';
import TravelLoading from '../../../shared/common/TravelLoading';

// Styled components
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginBottom: theme.spacing(1.5),
    '& .MuiFormControlLabel-label': {
        fontWeight: 500,
    }
}));

const WarningCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: alpha(theme.palette.warning.light, 0.3),
    borderLeft: `4px solid ${theme.palette.warning.main}`,
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius
}));

const AgencySettingsTab = () => {
    const theme = useTheme();
    const [settings, setSettings] = useState({
        requireCPF: true,
        requireRG: true,
        requirePassport: true
    });

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [resetting, setResetting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // Load settings when component mounts
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                setLoading(true);
                const passengerFieldSettings = await getPassengerFieldSettings();
                setSettings({
                    requireCPF: passengerFieldSettings.requireCPF,
                    requireRG: passengerFieldSettings.requireRG,
                    requirePassport: passengerFieldSettings.requirePassport
                });
            } catch (error) {
                console.error('Erro ao carregar configurações:', error);
                showSnackbar('Erro ao carregar configurações', 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setSettings(prev => ({
            ...prev,
            [name]: checked
        }));
    };

    const handleSave = async () => {
        try {
            setSaving(true);
            await updatePassengerFieldSettings(settings);
            showSnackbar('Configurações salvas com sucesso', 'success');
        } catch (error) {
            console.error('Erro ao salvar configurações:', error);
            showSnackbar('Erro ao salvar configurações', 'error');
        } finally {
            setSaving(false);
        }
    };

    const handleReset = async () => {
        try {
            setResetting(true);
            await resetPassengerFieldSettings();
            const defaultSettings = await getPassengerFieldSettings();
            setSettings({
                requireCPF: defaultSettings.requireCPF,
                requireRG: defaultSettings.requireRG,
                requirePassport: defaultSettings.requirePassport
            });
            showSnackbar('Configurações redefinidas com sucesso', 'success');
        } catch (error) {
            console.error('Erro ao redefinir configurações:', error);
            showSnackbar('Erro ao redefinir configurações', 'error');
        } finally {
            setResetting(false);
        }
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <TravelLoading />
            </Box>
        );
    }

    return (
        <Box maxWidth="100%" mx="auto" px={3}>
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>
                    Configurações de Campos Obrigatórios
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Defina quais campos de documentos serão obrigatórios ao cadastrar passageiros no sistema.
                </Typography>
            </Box>

            {(!settings.requireCPF || !settings.requirePassport) && (
                <WarningCard elevation={0}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <WarningAmberIcon color="warning" fontSize="large" />
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                                Atenção - Risco de Duplicação de Cadastros
                            </Typography>
                            <Typography variant="body2">
                                Ao tornar {!settings.requireCPF && !settings.requirePassport ? "CPF e Passaporte" :
                                    !settings.requireCPF ? "CPF" : "Passaporte"} opcional,
                                o sistema terá dificuldade em identificar cadastros duplicados.
                                Isso pode resultar em múltiplos registros para o mesmo passageiro,
                                potencialmente causando problemas na gestão de reservas e alocação de assentos.
                            </Typography>
                        </Box>
                    </Stack>
                </WarningCard>
            )}

            <Grid container spacing={4} mb={4}>
                <Grid item xs={12} md={6}>
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            boxShadow: theme.shadows[1],
                            height: '100%'
                        }}
                    >
                        <CardContent sx={{ p: 3 }}>
                            <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                                <BadgeIcon color="primary" fontSize="small" />
                                <Typography variant="h6">
                                    Documentos Obrigatórios
                                </Typography>
                                <Tooltip title="Essas configurações definem quais documentos serão obrigatórios ao cadastrar passageiros">
                                    <IconButton size="small">
                                        <InfoOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                            <Divider sx={{ mb: 3 }} />

                            <FormGroup>
                                <StyledFormControlLabel
                                    control={
                                        <Switch
                                            checked={settings.requireCPF}
                                            onChange={handleChange}
                                            name="requireCPF"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <FingerprintIcon fontSize="small" color={settings.requireCPF ? "primary" : "disabled"} />
                                            <Typography>CPF obrigatório para brasileiros</Typography>
                                        </Stack>
                                    }
                                />
                                <StyledFormControlLabel
                                    control={
                                        <Switch
                                            checked={settings.requireRG}
                                            onChange={handleChange}
                                            name="requireRG"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <BadgeIcon fontSize="small" color={settings.requireRG ? "primary" : "disabled"} />
                                            <Typography>RG obrigatório para brasileiros</Typography>
                                        </Stack>
                                    }
                                />
                                <StyledFormControlLabel
                                    control={
                                        <Switch
                                            checked={settings.requirePassport}
                                            onChange={handleChange}
                                            name="requirePassport"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <PassportIcon fontSize="small" color={settings.requirePassport ? "primary" : "disabled"} />
                                            <Typography>Passaporte obrigatório para estrangeiros</Typography>
                                        </Stack>
                                    }
                                />
                            </FormGroup>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            boxShadow: theme.shadows[1],
                            bgcolor: alpha(theme.palette.info.light, 0.1),
                            height: '100%'
                        }}
                    >
                        <CardContent sx={{ p: 3 }}>
                            <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                                <VerifiedUserIcon color="info" />
                                <Typography variant="h6">
                                    Recomendações
                                </Typography>
                            </Stack>

                            <Divider sx={{ mb: 3 }} />

                            <List disablePadding>
                                <ListItem sx={{ px: 0 }}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <VerifiedUserIcon fontSize="small" color="info" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Evite duplicações"
                                        secondary="Mantenha CPF e Passaporte como obrigatórios para impedir a criação de cadastros duplicados"
                                    />
                                </ListItem>
                                <ListItem sx={{ px: 0, mt: 1 }}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <VerifiedUserIcon fontSize="small" color="info" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Facilite a identificação"
                                        secondary="Documentos obrigatórios permitem identificar rapidamente passageiros em suas reservas"
                                    />
                                </ListItem>
                                <ListItem sx={{ px: 0, mt: 1 }}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        <VerifiedUserIcon fontSize="small" color="info" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Conformidade legal"
                                        secondary="Manter os documentos adequados contribui para a conformidade com requisitos legais de transporte"
                                    />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box display="flex" gap={2} mt={2} mb={4} justifyContent="flex-end">
                <Button
                    variant="outlined"
                    startIcon={<RestartAltIcon />}
                    onClick={handleReset}
                    disabled={resetting || saving}
                    sx={{
                        borderRadius: '50px',
                        px: 3
                    }}
                >
                    {resetting ? <CircularProgress size={24} /> : 'Restaurar Padrões'}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={saving || resetting}
                    sx={{
                        borderRadius: '50px',
                        px: 3
                    }}
                >
                    {saving ? <CircularProgress size={24} /> : 'Salvar Configurações'}
                </Button>
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AgencySettingsTab;