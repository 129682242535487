import { collection, getDocs } from 'firebase/firestore';

export const adaptBudgetPayments = async (agencyRef, bankAccounts) => {
  const budgetPaymentsRef = collection(agencyRef, 'budgetPayments');
  const budgetSnapshot = await getDocs(budgetPaymentsRef);

  return budgetSnapshot.docs.map(doc => {
    const payment = doc.data();
    return {
      id: `budget-${doc.id}`,
      description: `${payment.description} - Orçamento Viagem ${payment.travelId}`,
      date: payment.date,
      paymentMethod: payment.paymentMethod || '-',
      bankAccount: bankAccounts?.find(acc => acc.id === payment.bankAccountId)?.name || '-',
      amount: parseFloat(payment.amount || 0),
      // Use the status from the payment if available, otherwise default to 'Pago'
      status: payment.status || 'Pago',
      type: 'Orçamento'
    };
  });
};

export const adaptReceivablesData = (rawData, bankAccounts, pageSize = 15, currentPage = 1, filters = {}, getAllRecords = false) => {
  if (!Array.isArray(rawData.travels)) return { items: [], totalItems: 0, totalPages: 0 };

  const uniqueRecordsMap = new Map();

  const isDateInRange = (dateStr) => {
    if (!filters.startDate && !filters.endDate) return true;
    const date = new Date(dateStr);
    const start = filters.startDate ? new Date(filters.startDate) : null;
    const end = filters.endDate ? new Date(filters.endDate) : null;

    if (start && end) return date >= start && date <= end;
    if (start) return date >= start;
    if (end) return date <= end;
    return true;
  };

  const matchesSearchTerm = (description, orderId) => {
    if (!filters.searchTerm) return true;
    const searchTerm = filters.searchTerm.toLowerCase().trim();
    const orderIdStr = orderId?.toString() || '';
    const descriptionStr = description?.toLowerCase() || '';

    return orderIdStr === searchTerm ||
      orderIdStr.includes(searchTerm) ||
      descriptionStr.includes(searchTerm);
  };
  
  // Add budget payments if they exist
  if (Array.isArray(rawData.budgetPayments)) {
    rawData.budgetPayments.forEach(payment => {
      if (isDateInRange(payment.date) && matchesSearchTerm(payment.description)) {
        uniqueRecordsMap.set(payment.id, payment);
      }
    });
  }

  rawData.travels.forEach(receivable => {
    if (Array.isArray(receivable.orders)) {
      receivable.orders
        .filter(order => order.status !== 'Cancelada')
        .forEach(order => {
          const hasPayments = order.detalhesPagamento?.pagamentos?.length > 0;
          const valorTotal = parseFloat(order.detalhesPagamento?.valorTotal?.replace(',', '.') || 0);
          const valorPago = parseFloat(order.detalhesPagamento?.valorPago?.replace(',', '.') || 0);
          const valorRestante = parseFloat(order.detalhesPagamento?.valorRestante?.replace(',', '.') || 0);
          const isPaid = order.status === 'Pago' || valorRestante === 0;

          const orderMatches = matchesSearchTerm('', order.id);

          if (hasPayments) {
            order.detalhesPagamento.pagamentos.forEach(payment => {
              if (isDateInRange(payment.data)) {
                const key = `payment-${order.id}-${payment.data}-${payment.valor}-${Math.random()}`;
                const description = `Pagamento pedido ${order.id} - Viagem ${receivable.identificador}`;

                if (matchesSearchTerm(description, order.id)) {
                  uniqueRecordsMap.set(key, {
                    id: key,
                    description,
                    date: payment.data,
                    paymentMethod: payment.metodoPagamento,
                    bankAccount: bankAccounts?.find(acc => acc.id === payment.bankAccountId)?.name || '-',
                    amount: parseFloat(payment.valor?.replace(',', '.') || 0),
                    status: payment.statusPagamento || 'Pago',
                    type: 'Pedido'
                  });
                }
              }
            });
          }

          if (!isPaid) {
            const date = order.detalhesPagamento?.criadoEm;
            if (isDateInRange(date)) {
              const key = `order-${order.id}`;
              const description = `Pagamento pedido ${order.id} - Viagem ${receivable.identificador}`;

              if (matchesSearchTerm(description, order.id)) {
                uniqueRecordsMap.set(key, {
                  id: order.id,
                  description,
                  date: date || '-',
                  paymentMethod: order.detalhesPagamento?.metodoPagamento || '-',
                  bankAccount: bankAccounts?.find(acc => acc.id === order.detalhesPagamento?.bankAccountId)?.name || '-',
                  amount: valorRestante,
                  status: 'Pendente',
                  type: 'Pedido'
                });
              }
            }
          }
        });
    }

    if (Array.isArray(receivable.costs)) {
      receivable.costs
        .filter(cost => cost.type === 'Recebimento' && cost.estaAtivo)
        .forEach(cost => {
          const costMatches = matchesSearchTerm(cost.description || 'Recebimento');

          if (isDateInRange(cost.date) && costMatches) {
            const key = `cost-${cost.id}`;
            if (!uniqueRecordsMap.has(key)) {
              uniqueRecordsMap.set(key, {
                id: cost.id,
                description: `${cost.description || 'Recebimento'} - Viagem ${receivable.identificador}`,
                date: cost.date,
                paymentMethod: cost.paymentMethod || '-',
                bankAccount: bankAccounts?.find(acc => acc.id === cost.bankAccountId)?.name || '-',
                amount: parseFloat(cost.amount || 0),
                status: cost.status || 'Pago',
                type: 'Recebimento'
              });
            }
          }
        });
    }
  });

  const matchesFilters = (record) => {
    if (filters.searchTerm) {
      const searchTerm = filters.searchTerm.toLowerCase().trim();
      const orderIdStr = record.id?.toString() || '';
      const descriptionStr = record.description?.toLowerCase() || '';

      if (!orderIdStr.includes(searchTerm) && !descriptionStr.includes(searchTerm)) {
        return false;
      }
    }

    if (filters.status && record.status !== filters.status) {
      return false;
    }

    if (filters.bankAccountId) {
      const accountId = bankAccounts.find(acc => acc.name === record.bankAccount)?.id;
      if (accountId !== filters.bankAccountId) {
        return false;
      }
    }

    if (!isDateInRange(record.date)) {
      return false;
    }

    return true;
  };

  const filteredRecords = Array.from(uniqueRecordsMap.values())
    .filter(matchesFilters);

  const sortedRecords = filteredRecords.sort((a, b) => new Date(a.date) - new Date(b.date));

  const totalItems = sortedRecords.length;

  if (getAllRecords) {
    return {
      items: sortedRecords,
      totalItems,
      totalPages: 1
    };
  }

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedItems = sortedRecords.slice(startIndex, startIndex + pageSize);

  return {
    items: paginatedItems,
    totalItems,
    totalPages: Math.max(1, Math.ceil(totalItems / pageSize))
  };
};