import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button, Box, CircularProgress, Snackbar, Alert, IconButton, Card, CardContent, Collapse, Fade, Divider, Chip, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Tooltip, TextField, InputAdornment } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HotelIcon from '@mui/icons-material/Hotel';

import TravelForm from '../Travel/TravelForm';
import SeatSelection from './components/SeatSelection';
import VehicleForm from '../Vehicle/VehicleForm';
import TravelDetailsHelp from './helps/TravelDetailsHelp';
import TravelLoading from '../../shared/common/TravelLoading';

import { usePermissions } from '../../hooks/usePermissions';
import { generateTravelPamphlet } from '../../shared/utils/TravelPamphlet';
import { getTravelById, updateTravel, cancelTravel, deleteTravel, getVehicleById } from '../Travel/services/TravelService';
import { getAvailableSeats, getReservedSeats } from '../Allocation/services/OrderService';
import { addVehicle } from '../Vehicle/services/VehicleService';
import { getLayoutById } from '../LayoutPage/services/LayoutService';
import { formatDate } from '../../shared/utils/utils';
import { useMasterPassword } from '../../shared/hooks/useMasterPassword';
import { useDrawer } from '../../core/contexts/DrawerContext';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 240;

function TravelDetailsPage() {
  const theme = useTheme();
  const { travelId } = useParams();
  const [travel, setTravel] = useState(null);
  const [layoutAndar1, setLayoutAndar1] = useState([]);
  const [layoutAndar2, setLayoutAndar2] = useState([]);
  const [reservedSeats, setReservedSeats] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [availableSeatsAndar1, setAvailableSeatsAndar1] = useState([]);
  const [availableSeatsAndar2, setAvailableSeatsAndar2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editing, setEditing] = useState(false);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [masterPassword, setMasterPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [vehicleModalOpen, setVehicleModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const allocationButtonRef = useRef(null);
  const { openDrawer } = useDrawer();
  const {
    isVerifying,
    error: masterPasswordError,
    clearError,
    masterPasswordActive,
    showPassword,
    togglePasswordVisibility,
    verifyMasterPassword
  } = useMasterPassword();
  const { checkPermission } = usePermissions();

  const canEdit = checkPermission('travels', 'edit');
  const canDelete = checkPermission('travels', 'delete');
  const canCancel = checkPermission('travels', 'cancel');
  const canViewFinancial = checkPermission('travels_transactions', 'view');
  const canViewAccommodations = checkPermission('travels_accommodations', 'view');
  const canCreateVehicle = checkPermission('vehicles', 'create');

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDetails();
  }, [travelId]);

  const fetchDetails = async () => {
    setLoading(true);
    try {
      const travelData = await getTravelById(travelId);
      let assentosAndar1 = 0;
      let assentosAndar2 = 0;
      let totalSeats = 0;

      if (travelData.veiculoId) {
        const layoutData = await fetchLayoutByVehicle(travelData.veiculoId);

        // Adicionar os layouts
        setLayoutAndar1(layoutData.firstFloor || []);
        setLayoutAndar2(layoutData.secondFloor || []);

        // Obter os valores de assentos do layout
        assentosAndar1 = layoutData.assentosAndar1 || 0;
        assentosAndar2 = layoutData.assentosAndar2 || 0;
        totalSeats = layoutData.assentosTotais || 0;
      }

      // Atualizar o estado da viagem com os valores dos assentos
      setTravel({
        ...travelData,
        assentosAndar1,
        assentosAndar2,
        totalSeats,
      });

      const reserved = await getReservedSeats(travelId);

      const normalizedReserved = reserved
        .filter(reservation => reservation.status !== 'Cancelada')
        .map(reservation => ({
          ...reservation,
          // Garantir que number exista em todas as reservas
          number: reservation.number || parseInt(reservation.numeroAssento, 10)
        }));

      setReservedSeats(normalizedReserved);
    } catch (err) {
      setError('Falha ao carregar detalhes da viagem: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchLayoutByVehicle = async (vehicleId) => {
    try {
      const vehicleData = await getVehicleById(vehicleId);
      if (vehicleData && vehicleData.layoutId) {
        const layoutData = await getLayoutById(vehicleData.layoutId);
        return {
          ...layoutData,
          assentosAndar1: layoutData.assentosAndar1 || 0,
          assentosAndar2: layoutData.assentosAndar2 || 0,
        };
      } else {
        throw new Error('Nenhum layoutId associado a este veículo.');
      }
    } catch (err) {
      console.error('Erro ao buscar layout por veículo:', err.message);
      return { firstFloor: [], secondFloor: [], assentosAndar1: 0, assentosAndar2: 0 };
    }
  };

  const handleEditToggle = () => setEditing(!editing);
  const handleGoBack = () => {
    if (location.state?.previousPage) {
      if (!location.state.previousPage.includes('/pedido/')) {
        navigate(location.state.previousPage);
        return;
      }
    }

    navigate('/viagens');
  };

  const saveTravel = useCallback(async (updatedTravel) => {
    setLoading(true);
    try {
      await updateTravel(travelId, updatedTravel);
      const travelData = await getTravelById(travelId);

      let totalSeats = 0;
      if (updatedTravel.veiculoId) {
        const layoutData = await fetchLayoutByVehicle(updatedTravel.veiculoId);
        totalSeats = layoutData.assentosTotais || 0;
        setLayoutAndar1(layoutData.firstFloor || []);
        setLayoutAndar2(layoutData.secondFloor || []);
      }

      setTravel({
        ...travelData,
        totalSeats,
      });

      const reserved = await getReservedSeats(travelId);
      setReservedSeats(reserved.filter(reservation => reservation.status !== 'Cancelada'));

      setEditing(false);
      setError('');
    } catch (err) {
      setError('Erro ao salvar viagem: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [travelId]);

  const handleOpenConfirmCancelDialog = () => setConfirmCancelDialogOpen(true);
  const handleCloseConfirmCancelDialog = () => {
    setConfirmCancelDialogOpen(false);
    setMasterPassword('');
    clearError();
  };

  const handleConfirmCancelTravel = async () => {
    setLoading(true);
    try {
      if (masterPasswordActive) {
        const isValid = await verifyMasterPassword(masterPassword);
        if (!isValid) {
          setSnackbarMessage('Senha master incorreta');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }
      }

      await cancelTravel(travelId);
      const travelData = await getTravelById(travelId);
      setTravel(travelData);
      setConfirmCancelDialogOpen(false);
      setSnackbarMessage('Viagem cancelada com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      if (err.message.includes('master')) {
        setSnackbarMessage('Senha master incorreta');
      } else {
        setSnackbarMessage('Erro ao cancelar viagem: ' + err.message);
      }
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      if (!masterPasswordError) {
        handleCloseConfirmCancelDialog();
      }
    }
  };

  const handleOpenConfirmDeleteDialog = () => setConfirmDeleteDialogOpen(true);
  const handleCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogOpen(false);
    setMasterPassword('');
    clearError();
  };

  const handleConfirmDeleteTravel = async () => {
    setLoading(true);
    try {
      if (masterPasswordActive) {
        const isValid = await verifyMasterPassword(masterPassword);
        if (!isValid) {
          setSnackbarMessage('Senha master incorreta');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }
      }

      await deleteTravel(travelId);
      setConfirmDeleteDialogOpen(false);
      setSnackbarMessage('Viagem excluída com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      navigate('/viagens');
    } catch (err) {
      if (err.message.includes('master')) {
        setSnackbarMessage('Senha master incorreta');
      } else {
        setSnackbarMessage('Erro ao excluir viagem: ' + err.message);
      }
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      if (!masterPasswordError) {
        handleCloseConfirmDeleteDialog();
      }
    }
  };

  const handleSelectSeats = (seats) => setSelectedSeats(seats);
  const handleProceedToAllocation = () => navigate(`/viagens/${travelId}/pedido/novo`, { state: { selectedSeats } });
  const handleViewReservations = () => navigate(`/viagens/${travelId}/reservas`);
  const handleViewCosts = (travelId, e) => navigate(`/viagens/${travelId}/custos`);

  const handleAddVehicle = async (newVehicle) => {
    try {
      await addVehicle(newVehicle);
      const travelData = await getTravelById(travelId);
      setTravel(travelData);

      const totalSeats = parseInt(travelData.assentosAndar1, 10) + parseInt(travelData.assentosAndar2, 10);
      const seats = await getAvailableSeats(travelId, totalSeats);
      const reserved = await getReservedSeats(travelId);

      const activeReservedSeats = reserved.filter(reservation => reservation.status !== 'Cancelada');
      const seatsAndar1 = seats.slice(0, travelData.assentosAndar1);
      const seatsAndar2 = seats.slice(travelData.assentosAndar1, travelData.assentosAndar1 + travelData.assentosAndar2);

      setAvailableSeatsAndar1(seatsAndar1);
      setAvailableSeatsAndar2(seatsAndar2);
      setReservedSeats(activeReservedSeats);

      setVehicleModalOpen(false);
    } catch (error) {
      console.error('Erro ao adicionar veículo:', error);
    }
  };

  const handleOpenGoogleMaps = () => {
    if (travel && travel.origem && travel.destino) {
      const url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(travel.origem)}&destination=${encodeURIComponent(travel.destino)}`;
      window.open(url, '_blank');
    } else {
      setSnackbarMessage('Origem e destino são necessários para gerar a rota.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  if (loading) {
    return <TravelLoading />;
  }

  const handleEditReservation = (reservation, orderId) => {
    // Adiciona o estado ao redirecionar
    navigate(`/viagens/${reservation.travelId}/pedido/${orderId}`, {
      state: {
        editingReservation: reservation, // Inclui a reserva atual para edição
        editingOrderId: orderId, // Inclui o ID do pedido atual
      },
    });
  };

  const totalSeats = travel?.totalSeats || 0;
  const occupiedSeats = travel?.numReservas || 0;

  const getStatusColor = (status) => {
    switch (status) {
      case 'Cancelada':
        return theme.palette.error.light;
      case 'Em andamento':
        return theme.palette.success.main;
      case 'Próxima':
        return theme.palette.info.main;
      case 'Criada':
        return theme.palette.primary.light;
      case 'Encerrada':
        return theme.palette.grey[500];
      default:
        return theme.palette.grey[500];
    }
  };

  return (
    <Box>
      <Box sx={{ p: 0 }}>
        <Box sx={{ p: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Fade in={!loading} mountOnEnter unmountOnExit>
            <IconButton onClick={handleGoBack} sx={{ mb: 2 }}>
              <ArrowBackIcon />
            </IconButton>
          </Fade>
          <TravelDetailsHelp />
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => {
            setSnackbarOpen(false);
            setSnackbarMessage('');
          }}
        >
          <Alert
            onClose={() => {
              setSnackbarOpen(false);
              setSnackbarMessage('');
            }}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Card raised sx={{ mb: 2 }}>
          <CardContent>
            <Collapse in={!editing}>
              {travel ? (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Chip
                      label={travel.status}
                      sx={{
                        backgroundColor: getStatusColor(travel.status),
                      }}
                      color="primary"
                    />
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleOpenGoogleMaps}
                        startIcon={<LocationOnIcon />}
                        sx={{ borderRadius: '50px' }}
                      >
                        Ver Rota no Google Maps
                      </Button>
                      {travel.status !== 'Cancelada' && (
                        <IconButton
                          size="small"
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          sx={{ alignSelf: 'flex-start' }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {travel.identificador && (
                        <Chip label={`Identificador: ${travel.identificador}`} color="primary" />
                      )}
                      {/* <IconButton
                        color="primary"
                        onClick={() => generateTravelPamphlet(travel, reservedSeats)}
                        size="small"
                      >
                        <PictureAsPdfIcon />
                      </IconButton> */}
                    </Box>
                  </Box>
                  {travel.origem && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 2 }}>
                      <LocationOnIcon sx={{ mr: 1 }} color="primary" />
                      <Typography variant="h6" noWrap>{travel.origem}</Typography>
                    </Box>
                  )}
                  {travel.destino && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <LocationOnIcon sx={{ mr: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' }, color: theme.palette.error.light }} />
                      <Typography variant="h6" noWrap>{travel.destino}</Typography>
                    </Box>
                  )}
                  {travel.dataIda && travel.horarioIda && (
                    <>
                      <Divider sx={{ my: 1 }} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <DateRangeIcon sx={{ mr: 1 }} />
                          <Typography variant="body2">Data de Ida: {formatDate(travel.dataIda)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <AccessTimeIcon sx={{ mr: 1 }} />
                          <Typography variant="body2">Hora: {travel.horarioIda}</Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                  {travel.somenteIda ? (
                    <Box sx={{ mb: 1 }}>
                      <Typography variant="body2" color="textSecondary">Viagem somente de ida</Typography>
                    </Box>
                  ) : (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <DateRangeIcon sx={{ mr: 1 }} />
                          <Typography variant="body2">Data de Retorno: {formatDate(travel.dataRetorno)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <AccessTimeIcon sx={{ mr: 1 }} />
                          <Typography variant="body2">Hora: {travel.horarioRetorno}</Typography>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 1 }} />
                    </>
                  )}
                  {travel.veiculo ? (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <DirectionsBusIcon sx={{ mr: 1 }} />
                        <Typography variant="body2">
                          {travel.veiculo.identificadorVeiculo} - {travel.veiculo.placa} ({travel.veiculo.empresa})
                        </Typography>
                      </Box>
                      {totalSeats > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <AirlineSeatReclineNormalIcon sx={{ mr: 1 }} />
                          <Typography variant="body2">
                            Reservas: {occupiedSeats}/{totalSeats}
                          </Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <AirlineSeatReclineNormalIcon sx={{ mr: 1 }} />
                        <Typography variant="body2">Reservas: {occupiedSeats}</Typography>
                      </Box>
                    </>
                  )}
                  {travel.veiculo && typeof travel.assentosAndar1 !== 'undefined' && travel.assentosAndar1 > 0 && (
                    <Typography variant="body2" sx={{ mb: 1 }}>Assentos 1° Andar: {travel.assentosAndar1}</Typography>
                  )}
                  {travel.veiculo && typeof travel.assentosAndar2 !== 'undefined' && travel.assentosAndar2 > 0 && (
                    <Typography variant="body2" sx={{ mb: 1 }}>Assentos 2° Andar: {travel.assentosAndar2}</Typography>
                  )}
                  {travel.descricao && (
                    <Box sx={{ mt: 2, mb: 1 }}>
                      <Typography variant="body2" sx={{
                        fontWeight: 'bold',
                        color: theme.palette.text.secondary,
                        mb: 0.5
                      }}>
                        Descrição:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 1,
                          padding: 1,
                          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
                          borderRadius: 1,
                          borderLeft: `4px solid ${theme.palette.primary.main}`
                        }}
                      >
                        {travel.descricao}
                      </Typography>
                    </Box>
                  )}

                  {travel.informacoesAdicionais && (
                    <Typography variant="body2" sx={{ mb: 1 }}>Informações Adicionais: {travel.informacoesAdicionais}</Typography>
                  )}
                  <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                    <Button
                      startIcon={<ListIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={handleViewReservations}
                      sx={{ flex: 1, borderRadius: '50px' }}
                    >
                      Lista de Reservas
                    </Button>
                    {canViewFinancial && (
                      <Button
                        size="small"
                        variant="outlined"
                        color="success"
                        startIcon={<AttachMoneyIcon />}
                        onClick={(e) => handleViewCosts(travel.id, e)}
                        sx={{ flex: 1, borderRadius: '50px' }}
                      >
                        Financeiro
                      </Button>
                    )}
                    {canViewAccommodations && (
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<ApartmentIcon />}
                        onClick={() => navigate(`/viagens/${travel.id}/hospedagens`)}
                        sx={{ flex: 1, borderRadius: '50px' }}
                      >
                        Hospedagem
                      </Button>
                    )}
                  </Box>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                      },
                    }}
                  >
                    {travel.status !== 'Cancelada' && canEdit && (
                      <MenuItem onClick={() => {
                        handleEditToggle();
                        setAnchorEl(null);
                      }}>
                        <EditIcon fontSize="small" sx={{ mr: 1 }} /> Editar
                      </MenuItem>
                    )}
                    {travel.status !== 'Cancelada' && travel.status !== 'Encerrada' && canCancel && (
                      <MenuItem onClick={() => {
                        handleOpenConfirmCancelDialog();
                        setAnchorEl(null);
                      }}>
                        <CancelIcon fontSize="small" sx={{ mr: 1 }} /> Cancelar
                      </MenuItem>
                    )}
                    {travel.status !== 'Cancelada' && travel.status !== 'Encerrada' && canDelete && (
                      <MenuItem onClick={() => {
                        handleOpenConfirmDeleteDialog();
                        setAnchorEl(null);
                      }}>
                        <DeleteIcon fontSize="small" sx={{ mr: 1 }} /> Excluir
                      </MenuItem>
                    )}
                  </Menu>
                </>
              ) : (
                <Typography variant="body1">Viagem não encontrada.</Typography>
              )}
            </Collapse>
            <Collapse in={editing}>
              <Dialog open={editing} fullWidth maxWidth="md" onClose={handleEditToggle}>
                <DialogContent sx={{ padding: 0 }}>
                  <Box sx={{ padding: 2, overflowY: 'auto', maxHeight: '80vh' }}>
                    <TravelForm travel={travel} saveTravel={saveTravel} cancelForm={handleEditToggle} />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleEditToggle} variant="outlined" sx={{ borderRadius: '50px' }}>
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
            </Collapse>
          </CardContent>
        </Card>
        <Card raised sx={{ mb: 2 }}>
          <CardContent>
            <SeatSelection
              layoutAndar1={layoutAndar1}
              layoutAndar2={layoutAndar2}
              reservedSeats={reservedSeats.map(seat => ({ ...seat, travelId }))} // Inclua o travelId em cada reserva
              onSelectSeat={handleSelectSeats}
              handleEditReservation={handleEditReservation}
            />
            {checkPermission('orders', 'create') && (
              <Box
                sx={{
                  position: 'fixed',
                  bottom: 16,
                  right: 16,
                  zIndex: 1000,
                  display: 'flex',
                  flexDirection: 'column', // Mudança aqui para empilhar verticalmente
                  gap: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'auto',
                  maxWidth: '400px', // Ajustado para melhor layout vertical
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(`/viagens/${travelId}/pedido/novo`, {
                    state: { selectedSeats: [] }
                  })}
                  sx={{
                    borderRadius: '50px',
                    width: '100%', // Garante que o botão ocupe toda a largura disponível
                  }}
                >
                  Criar Reserva Sem Assento
                </Button>

                {travel?.veiculoId && (
                  <Tooltip
                    title={selectedSeats.length === 0 ? 'Selecione ao menos um assento para continuar' : 'Clique para prosseguir com a alocação de passageiros'}
                    placement="top"
                    arrow
                  >
                    <span style={{ width: '100%' }}>
                      <Button
                        ref={allocationButtonRef}
                        variant="contained"
                        color="primary"
                        onClick={handleProceedToAllocation}
                        sx={{
                          width: '100%',
                          borderRadius: '50px',
                          opacity: selectedSeats.length === 0 ? 0.5 : 1,
                          pointerEvents: selectedSeats.length === 0 ? 'none' : 'auto',
                        }}
                      >
                        Prosseguir para Alocação de Passageiros
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
      <Dialog open={confirmCancelDialogOpen} onClose={handleCloseConfirmCancelDialog}>
        <DialogTitle>Confirmar Cancelamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja cancelar a viagem para <strong>{travel?.destino}</strong>?
            <br />
            Data de Ida: <strong>{formatDate(travel?.dataIda)}</strong>
            <br />
            {!travel?.somenteIda && (
              <>
                Data de Retorno: <strong>{formatDate(travel?.dataRetorno)}</strong>
                <br />
              </>
            )}
            {reservedSeats.length > 0 && (
              <strong>Essa viagem tem {reservedSeats.length} reserva(s) associada(s).</strong>
            )}
            <br />
            Cancelar essa viagem também cancelará todas as reservas e pedidos associados. Essa ação não pode ser desfeita.
          </DialogContentText>
          {masterPasswordActive && (
            <TextField
              margin="normal"
              fullWidth
              label="Senha Master"
              type={showPassword ? 'text' : 'password'}
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              error={!!masterPasswordError}
              helperText={masterPasswordError}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              disabled={loading || isVerifying}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmCancelDialog} variant="outlined" disabled={loading} sx={{ borderRadius: '50px' }}>
            Voltar
          </Button>
          <Button
            onClick={handleConfirmCancelTravel}
            variant="contained"
            color="error"
            autoFocus
            disabled={(masterPasswordActive && !masterPassword) || loading || isVerifying}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {loading || isVerifying ? <CircularProgress size={24} /> : 'Cancelar viagem'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDeleteDialogOpen} onClose={handleCloseConfirmDeleteDialog}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir a viagem para <strong>{travel?.destino}</strong>?
            <br />
            Data de Ida: <strong>{formatDate(travel?.dataIda)}</strong>
            <br />
            {!travel?.somenteIda && (
              <>
                Data de Retorno: <strong>{formatDate(travel?.dataRetorno)}</strong>
                <br />
              </>
            )}
            Essa ação excluirá todas as reservas e pedidos relacionados a essa viagem. Isso não pode ser desfeito.
          </DialogContentText>
          {masterPasswordActive && (
            <TextField
              margin="normal"
              fullWidth
              label="Senha Master"
              type={showPassword ? 'text' : 'password'}
              value={masterPassword}
              onChange={(e) => setMasterPassword(e.target.value)}
              error={!!masterPasswordError}
              helperText={masterPasswordError}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              disabled={loading || isVerifying}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDeleteDialog} variant="outlined" disabled={loading} sx={{ borderRadius: '50px' }}>
            Voltar
          </Button>
          <Button
            onClick={handleConfirmDeleteTravel}
            variant="contained"
            color="error"
            autoFocus
            disabled={(masterPasswordActive && !masterPassword) || loading || isVerifying}
            sx={{ color: 'white', borderRadius: '50px' }}
          >
            {loading || isVerifying ? <CircularProgress size={24} /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={vehicleModalOpen} onClose={() => setVehicleModalOpen(false)} fullWidth maxWidth="sm">
        <DialogContent>
          <VehicleForm onSave={handleAddVehicle} onCancel={() => setVehicleModalOpen(false)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default TravelDetailsPage;
