import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Divider,
    Button,
    Chip,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CakeIcon from '@mui/icons-material/Cake';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import FilterListIcon from '@mui/icons-material/FilterList';
import TravelLoading from '../../../shared/common/TravelLoading';

// Import the necessary passenger service
import { getAllPassengers } from '../services/PassengerService';

const PassengerBirthdays = () => {
    const theme = useTheme();
    const [passengers, setPassengers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDays, setSelectedDays] = useState(30); // Default to show next 30 days
    const [messageDialog, setMessageDialog] = useState(false);
    const [selectedPassenger, setSelectedPassenger] = useState(null);
    const [messageText, setMessageText] = useState('');

    // Options for filtering
    const daysOptions = [7, 15, 30, 60, 90];

    // Current date for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Function to fetch passengers - we'll use the existing getAllPassengers function
    // to minimize creating new endpoints and keep request costs low
    useEffect(() => {
        const fetchPassengers = async () => {
            setLoading(true);
            try {
                const allPassengers = await getAllPassengers();
                setPassengers(allPassengers);
            } catch (err) {
                console.error("Error fetching passengers:", err);
                setError("Não foi possível carregar os dados dos passageiros.");
            } finally {
                setLoading(false);
            }
        };

        fetchPassengers();
    }, []);

    // Function to calculate the number of days until the next birthday
    const getDaysUntilBirthday = (birthDateString) => {
        if (!birthDateString) return null;

        const today = new Date();
        const [year, month, day] = birthDateString.split('-').map(Number);

        // Criar datas sem considerar hora para evitar problemas de timezone
        const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const nextBirthday = new Date(today.getFullYear(), month - 1, day);

        // Se o próximo aniversário já passou este ano, use o próximo ano
        if (nextBirthday < todayDate) {
            nextBirthday.setFullYear(today.getFullYear() + 1);
        }

        // Calcular diferença em milissegundos
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const diffTime = nextBirthday.getTime() - todayDate.getTime();

        // Calcular dias de diferença
        return Math.floor(diffTime / millisecondsPerDay);
    };

    const formatBirthDate = (dateString) => {
        if (!dateString) return '';

        const [, month, day] = dateString.split('-');
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}`;
    };

    const getAge = (birthDateString) => {
        if (!birthDateString) return null;

        const today = new Date();
        const [birthYear, birthMonth, birthDay] = birthDateString.split('-').map(Number);

        let age = today.getFullYear() - birthYear;
        const monthDiff = today.getMonth() + 1 - birthMonth;
        const dayDiff = today.getDate() - birthDay;

        // Ajusta a idade se o aniversário ainda não ocorreu este ano
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age;
    };

    // Process passengers to include birthday information and sort by proximity
    const birthdayPassengers = useMemo(() => {
        return passengers
            .filter(passenger => passenger.dataNascimento) // Filter out passengers without birth date
            .map(passenger => {
                const daysUntil = getDaysUntilBirthday(passenger.dataNascimento);
                return {
                    ...passenger,
                    daysUntil,
                    age: getAge(passenger.dataNascimento),
                    nextAge: getAge(passenger.dataNascimento) + 1,
                    formattedDate: formatBirthDate(passenger.dataNascimento)
                };
            })
            .filter(passenger => passenger.daysUntil <= selectedDays) // Filter by selected days
            .sort((a, b) => a.daysUntil - b.daysUntil); // Sort by proximity
    }, [passengers, selectedDays]);

    // Group birthdays by "today", "this week", and "upcoming"
    const groupedBirthdays = useMemo(() => {
        return {
            today: birthdayPassengers.filter(p => p.daysUntil === 0),
            upcoming: birthdayPassengers.filter(p => p.daysUntil > 0)
        };
    }, [birthdayPassengers]);

    const handleMessageOpen = (passenger) => {
        setSelectedPassenger(passenger);

        // Pre-populate message with birthday greeting
        const defaultMessage = `Feliz aniversário, ${passenger.nome}! Desejamos um dia maravilhoso e cheio de alegrias.`;
        setMessageText(defaultMessage);

        setMessageDialog(true);
    };

    const handleSendMessage = () => {
        if (!selectedPassenger) return;

        // For WhatsApp, create a link with the message
        if (selectedPassenger.telefone) {
            const phoneNumber = selectedPassenger.telefone.replace(/\D/g, '');
            const whatsappUrl = `https://wa.me/+55${phoneNumber}?text=${encodeURIComponent(messageText)}`;
            window.open(whatsappUrl, '_blank');
        }

        setMessageDialog(false);
    };

    // Function to copy text to clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(messageText);
        // You could add a snackbar notification here
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <TravelLoading disableAlignItems />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <CakeIcon sx={{ mr: 1 }} /> Aniversários
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FilterListIcon sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ mr: 1 }}>Mostrar:</Typography>
                    {daysOptions.map(days => (
                        <Chip
                            key={days}
                            label={`${days} dias`}
                            onClick={() => setSelectedDays(days)}
                            color={selectedDays === days ? "primary" : "default"}
                            sx={{ mr: 1 }}
                        />
                    ))}
                </Box>
            </Box>

            {groupedBirthdays.today.length === 0 && groupedBirthdays.upcoming.length === 0 ? (
                <Card variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="body1" color="text.secondary" align="center">
                            Não há aniversários nos próximos {selectedDays} dias.
                        </Typography>
                    </CardContent>
                </Card>
            ) : (
                <>
                    {/* Today's Birthdays Section */}
                    {groupedBirthdays.today.length > 0 && (
                        <Card variant="outlined" sx={{ mb: 2, borderColor: theme.palette.primary.main }}>
                            <CardContent>
                                <Typography variant="h6" color="primary" gutterBottom>
                                    🎂 Aniversariantes de Hoje! 🎉
                                </Typography>
                                <List>
                                    {groupedBirthdays.today.map((passenger) => (
                                        <ListItem
                                            key={passenger.id}
                                            secondaryAction={
                                                <Box>
                                                    {passenger.telefone && (
                                                        <Tooltip title="Enviar Mensagem">
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="send message"
                                                                onClick={() => handleMessageOpen(passenger)}
                                                                color="primary"
                                                            >
                                                                <WhatsAppIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    {passenger.email && (
                                                        <Tooltip title="Enviar Email">
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="send email"
                                                                onClick={() => window.location.href = `mailto:${passenger.email}`}
                                                                color="primary"
                                                            >
                                                                <EmailIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Box>
                                            }
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" fontWeight="bold">
                                                        {passenger.nome} - {passenger.age} anos hoje!
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography variant="body2" color="text.secondary">
                                                        Nascimento: {passenger.formattedDate}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    )}

                    {/* Upcoming Birthdays Section */}
                    {groupedBirthdays.upcoming.length > 0 && (
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Próximos Aniversários
                                </Typography>
                                <List>
                                    {groupedBirthdays.upcoming.map((passenger) => (
                                        <React.Fragment key={passenger.id}>
                                            <ListItem
                                                secondaryAction={
                                                    <Box>
                                                        {passenger.telefone && (
                                                            <Tooltip title="Enviar Mensagem">
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="send message"
                                                                    onClick={() => handleMessageOpen(passenger)}
                                                                >
                                                                    <WhatsAppIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        {passenger.email && (
                                                            <Tooltip title="Enviar Email">
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="send email"
                                                                    onClick={() => window.location.href = `mailto:${passenger.email}`}
                                                                >
                                                                    <EmailIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </Box>
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography variant="body1">
                                                                {passenger.nome}
                                                            </Typography>
                                                            <Chip
                                                                size="small"
                                                                label={passenger.daysUntil === 1 ? "Amanhã!" : `Em ${passenger.daysUntil} dias`}
                                                                color={passenger.daysUntil <= 7 ? "primary" : "default"}
                                                                sx={{ ml: 1 }}
                                                            />
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <Typography variant="body2" color="text.secondary">
                                                            {passenger.formattedDate} - Completará {passenger.nextAge} anos
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider component="li" />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    )}
                </>
            )}

            {/* Message Dialog */}
            <Dialog
                open={messageDialog}
                onClose={() => setMessageDialog(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Enviar Mensagem de Aniversário</DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">
                            Para: {selectedPassenger?.nome}
                        </Typography>
                        {selectedPassenger?.telefone && (
                            <Typography variant="body2" color="text.secondary">
                                Telefone: {selectedPassenger.telefone}
                            </Typography>
                        )}
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Mensagem"
                        type="text"
                        fullWidth
                        rows={4}
                        variant="outlined"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={copyToClipboard}
                        sx={{ mt: 1 }}
                    >
                        Copiar texto
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ borderRadius: '50px' }} variant="outlined" onClick={() => setMessageDialog(false)}>Cancelar</Button>
                    <Button
                        onClick={handleSendMessage}
                        variant="contained"
                        color="primary"
                        disabled={!selectedPassenger?.telefone}
                        sx={{ borderRadius: '50px' }}
                    >
                        Enviar via WhatsApp
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PassengerBirthdays;