import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Grid, Typography, Tooltip } from '@mui/material';
import { AirlineSeatReclineNormal, Wc, Stairs, Kitchen, Edit, Block } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const SeatSelection = React.memo(({ layoutAndar1, layoutAndar2, reservedSeats, onSelectSeat, handleEditReservation }) => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [hoveredSeat, setHoveredSeat] = useState(null);
  const theme = useTheme();

  // Normalize reserved seats data for consistent comparison
  const normalizedReservedSeats = useCallback(() => {
    return reservedSeats.map(seat => ({
      ...seat,
      number: parseInt(seat.number || seat.numeroAssento, 10)
    }));
  }, [reservedSeats]);

  useEffect(() => {
    onSelectSeat(selectedSeats);
  }, [selectedSeats, onSelectSeat]);

  // Check if a seat is already reserved
  const isReserved = useCallback((seatNumber) => {
    if (!seatNumber) return false;

    const seatNum = parseInt(seatNumber, 10);
    return normalizedReservedSeats().some(reserved =>
      reserved.number === seatNum &&
      reserved.status !== 'Cancelada'
    );
  }, [normalizedReservedSeats]);

  // Find the reserved seat data
  const getReservedSeatData = useCallback((seatNumber) => {
    if (!seatNumber) return null;

    const seatNum = parseInt(seatNumber, 10);
    return normalizedReservedSeats().find(reserved =>
      reserved.number === seatNum &&
      reserved.status !== 'Cancelada'
    );
  }, [normalizedReservedSeats]);

  const toggleSeatSelection = (seat) => {
    if (!seat || !seat.number) return;

    // Check if this seat is already reserved
    if (isReserved(seat.number)) {
      const reservedSeat = getReservedSeatData(seat.number);
      if (reservedSeat && handleEditReservation) {
        // Redirect to edit the reservation
        handleEditReservation(reservedSeat, reservedSeat.orderId);
      }
    } else {
      // Toggle selection for available seats
      setSelectedSeats(prevSelected => {
        const seatNum = parseInt(seat.number, 10);
        const alreadySelected = prevSelected.some(s => parseInt(s.number, 10) === seatNum);

        if (alreadySelected) {
          return prevSelected.filter(s => parseInt(s.number, 10) !== seatNum);
        } else {
          return [...prevSelected, { ...seat, number: seatNum }];
        }
      });
    }
  };

  const unflattenLayout = (flatLayout) => {
    if (!flatLayout || flatLayout.length === 0) return [];

    const rows = Math.max(...flatLayout.map((cell) => cell.row)) + 1;
    const cols = Math.max(...flatLayout.map((cell) => cell.col)) + 1;
    const layout = Array.from({ length: rows }, () => Array(cols).fill({ type: 'empty', number: null }));

    flatLayout.forEach((cell) => {
      layout[cell.row][cell.col] = {
        type: cell.type || 'empty',
        number: cell.number ?? null,
      };
    });

    return layout;
  };

  const renderLayout = (layout) => {
    if (!Array.isArray(layout) || layout.length === 0) return null;

    return layout.map((row, rowIndex) => (
      <Grid container key={rowIndex} justifyContent="center" sx={{ marginBottom: '8px' }}>
        {row.map((cell, colIndex) => {
          const seatIsReserved = cell.type === 'seat' && isReserved(cell.number);
          const reservedSeatData = seatIsReserved ? getReservedSeatData(cell.number) : null;
          const passengerInfo = reservedSeatData?.passenger
            ? `${reservedSeatData.passenger.nome} (${reservedSeatData.passenger.cpf ||
            reservedSeatData.passenger.passaporte || reservedSeatData.passenger.rg})`
            : '';

          const seatNumber = cell.number ? parseInt(cell.number, 10) : null;
          const isSelected = selectedSeats.some(s => parseInt(s.number, 10) === seatNumber);

          let tooltipText = '';
          if (cell.type === 'seat' && cell.number) {
            tooltipText = seatIsReserved ?
              `${passengerInfo} (Clique para editar)` :
              `Assento ${cell.number}`;
          } else if (cell.type === 'bathroom') {
            tooltipText = 'Banheiro';
          } else if (cell.type === 'stair') {
            tooltipText = 'Escada';
          } else if (cell.type === 'fridge') {
            tooltipText = 'Frigobar';
          }

          return (
            <React.Fragment key={colIndex}>
              {colIndex === 2 && <Grid item key={`aisle-${rowIndex}`} sx={{ width: '20px' }} />}
              <Grid item>
                <Tooltip title={tooltipText} arrow>
                  <span>
                    <Button
                      variant={isSelected ? 'contained' : 'outlined'}
                      onClick={() => toggleSeatSelection(cell)}
                      onMouseEnter={() => setHoveredSeat(cell.number)}
                      onMouseLeave={() => setHoveredSeat(null)}
                      disabled={cell.type !== 'seat'}
                      sx={{
                        width: '48px',
                        height: '48px',
                        margin: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: seatIsReserved
                          ? 'rgba(128, 128, 128, 0.5)'
                          : isSelected
                            ? theme.palette.primary.main
                            : theme.palette.background.paper,
                        color: seatIsReserved
                          ? theme.palette.common.white
                          : isSelected
                            ? theme.palette.mode === 'dark'
                              ? theme.palette.common.black
                              : theme.palette.common.white
                            : theme.palette.text.primary,
                        cursor: cell.type !== 'seat' ? 'default' : 'pointer',
                        visibility: cell.type === 'empty' ? 'hidden' : 'visible',
                        '&:hover': {
                          backgroundColor: seatIsReserved
                            ? 'rgba(128, 128, 128, 0.7)' // Slightly darker on hover for reserved seats
                            : undefined // Use default hover for other states
                        }
                      }}
                    >
                      {renderCellContent(cell, seatIsReserved, hoveredSeat)}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    ));
  };

  const renderCellContent = (cell, isReserved, hoveredSeat) => {
    if (cell.type === 'seat') {
      if (isReserved && hoveredSeat === cell.number) {
        return <Edit />;
      }
      return cell.number ? `${cell.number}` : <AirlineSeatReclineNormal />;
    }

    switch (cell.type) {
      case 'bathroom':
        return <Wc />;
      case 'stair':
        return <Stairs />;
      case 'fridge':
        return <Kitchen />;
      case 'empty':
      default:
        return <Block />;
    }
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: '16px' }}>Selecione os Assentos</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
          <Button
            variant="outlined"
            sx={{
              width: '48px',
              height: '48px',
              margin: '4px',
              backgroundColor: 'rgba(128, 128, 128, 0.5)',
              color: theme.palette.common.white
            }}
          />
          <Typography variant="body2" sx={{ marginLeft: '8px' }}>Reservado</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
          <Button
            variant="outlined"
            sx={{
              width: '48px',
              height: '48px',
              margin: '4px',
              backgroundColor: theme.palette.background.paper,
            }}
          />
          <Typography variant="body2" sx={{ marginLeft: '8px' }}>Disponível</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            sx={{
              width: '48px',
              height: '48px',
              margin: '4px',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.mode === 'dark'
                ? theme.palette.common.black
                : theme.palette.common.white,
            }}

          />
          <Typography variant="body2" sx={{ marginLeft: '8px' }}>Selecionado</Typography>
        </Box>
      </Box>
      {layoutAndar1.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>1º Andar</Typography>
          <Box sx={{ border: `1px solid ${theme.palette.divider}`, padding: '16px', borderRadius: '8px', backgroundColor: theme.palette.background.default }}>
            {renderLayout(unflattenLayout(layoutAndar1))}
          </Box>
        </Box>
      )}
      {layoutAndar2.length > 0 && (
        <Box>
          <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>2º Andar</Typography>
          <Box sx={{ border: `1px solid ${theme.palette.divider}`, padding: '16px', borderRadius: '8px', backgroundColor: theme.palette.background.default }}>
            {renderLayout(unflattenLayout(layoutAndar2))}
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default SeatSelection;