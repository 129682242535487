import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Snackbar,
  Alert,
  Button,
  Typography,
  Tooltip,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Pagination,
  Collapse,
  Tabs,
  Tab
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import SearchIcon from '@mui/icons-material/Search';
import PeopleIcon from '@mui/icons-material/People';
import CakeIcon from '@mui/icons-material/Cake';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import PassengerInactivity from './components/PassengerInactivity';
import PassengerCard from './components/PassengerCard';
import PassengerTable from './components/PassengerTable';
import PassengerForm from './PassengerForm';
import PassengerPageHelp from './helps/PassengerPageHelp';
import PassengerBirthdays from './components/PassengerBirthdays';
import TravelLoading from '../../shared/common/TravelLoading';

import { usePermissions } from '../../hooks/usePermissions';
import { getSavedViewType, saveViewType } from '../../shared/utils/localStorageUtils';
import { getPaginatedPassengers, deletePassengers, getPassengersCount } from './services/PassengerService';

const PassengerPage = () => {
  const { checkPermission } = usePermissions();
  const [passageiros, setPassageiros] = useState([]);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [editedPassenger, setEditedPassenger] = useState({});
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [editing, setEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [passengersPerPage] = useState(12);
  const [order, setOrder] = useState('alphabetical');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [viewMode, setViewMode] = useState(getSavedViewType());
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [passengers, setPassengers] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [tabValue, setTabValue] = useState(0);

  const canView = checkPermission('passengers', 'view');
  const canCreate = checkPermission('passengers', 'create');
  const canEdit = checkPermission('passengers', 'edit');
  const canDelete = checkPermission('passengers', 'delete');

  const fetchPassengers = useCallback(async (isNewFilter = false) => {
    setLoading(true);
    try {
      if (isNewFilter) {
        setPassengers([]);
        setLastDoc(null);
        setCurrentPage(1);
      }

      const filters = {
        searchTerm,
        order,
      };

      const count = await getPassengersCount(filters);
      setTotalCount(count);

      const result = await getPaginatedPassengers(
        passengersPerPage,
        isNewFilter ? null : lastDoc,
        filters
      );

      setPassengers(prev => isNewFilter ? result.passengers : [...prev, ...result.passengers]);
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
    } catch (error) {
      setSnackbarMessage('Erro ao buscar passageiros: ' + error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, [order, lastDoc, passengersPerPage, searchTerm]);

  // Use este useEffect apenas para a carga inicial e mudanças na ordenação
  useEffect(() => {
    fetchPassengers(true);
  }, [order]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchChange = useCallback((event) => {
    setSearchInputValue(event.target.value);
  }, []);

  const handleSearch = useCallback(() => {
    (async () => {
      setSearchTerm(searchInputValue);
      try {
        setLoading(true);

        const filters = {
          searchTerm: searchInputValue, // Use searchInputValue diretamente aqui
          order,
        };

        const count = await getPassengersCount(filters);
        setTotalCount(count);

        const result = await getPaginatedPassengers(
          passengersPerPage,
          null, // Reset pagination on new search
          filters
        );

        setPassengers(result.passengers);
        setLastDoc(result.lastDoc);
        setHasMore(result.hasMore);
        setCurrentPage(1);
      } catch (error) {
        setSnackbarMessage('Erro ao buscar passageiros: ' + error.message);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [searchInputValue, order, passengersPerPage]);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }, [handleSearch]);

  const clearSearch = useCallback(() => {
    setSearchInputValue('');
    // Executar a busca imediatamente com o termo vazio
    (async () => {
      setSearchTerm('');
      try {
        setLoading(true);

        const filters = {
          searchTerm: '',
          order,
        };

        const count = await getPassengersCount(filters);
        setTotalCount(count);

        const result = await getPaginatedPassengers(
          passengersPerPage,
          null,
          filters
        );

        setPassengers(result.passengers);
        setLastDoc(result.lastDoc);
        setHasMore(result.hasMore);
        setCurrentPage(1);
      } catch (error) {
        setSnackbarMessage('Erro ao buscar passageiros: ' + error.message);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [order, passengersPerPage]);

  const handleOrderChange = useCallback((event) => {
    setOrder(event.target.value);
  }, []);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    if (newPage > currentPage && hasMore) {
      fetchPassengers(false);
    }

    window.scrollTo(0, 0);
  };

  // Render your passengers
  const currentPassengers = useMemo(() => {
    const startIdx = (currentPage - 1) * passengersPerPage;
    return passengers.slice(startIdx, startIdx + passengersPerPage);
  }, [currentPage, passengers, passengersPerPage]);

  const handleOpenFormDialog = () => {
    setOpenFormDialog(true);
    setEditedPassenger({});
    setEditing(false);
  };

  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
  };

  const startEditing = (passenger) => {
    setEditedPassenger(passenger);
    setEditing(true);
    setOpenFormDialog(true);
  };

  const handleDeletePassenger = async (id) => {
    setDeleting(true);
    try {
      await deletePassengers([id]);
      setSnackbarMessage('Passageiro excluído com sucesso.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      fetchPassengers(true);
    } catch (error) {
      setSnackbarMessage('Erro ao excluir passageiro: ' + error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setDeleting(false);
    }
  };

  // Função para alternar entre os modos de visualização e salvar no localStorage
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    saveViewType(mode); // Salva a opção de visualização no localStorage
  };

  // Modified pagination component
  const PaginationComponent = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      <Pagination
        count={Math.ceil(totalCount / passengersPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        disabled={loading}
      />
    </Box>
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        <Typography variant="h6" component="div">
          Passageiros
          <Box component="span" sx={{ ml: 1 }}>
            <PassengerPageHelp />
          </Box>
        </Typography>
        {canCreate && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenFormDialog}
            sx={{ borderRadius: '50px' }}
          >
            Adicionar
          </Button>
        )}
        {tabValue === 0 && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FilterListIcon />}
            onClick={() => setFiltersVisible(!filtersVisible)}
            sx={{ borderRadius: '50px' }}
          >
            {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
          </Button>
        )}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {tabValue === 0 && (
            <>
              <IconButton
                onClick={() => handleViewModeChange('card')}
                color={viewMode === 'card' ? 'primary' : 'default'}
              >
                <ViewModuleIcon />
              </IconButton>
              <IconButton
                onClick={() => handleViewModeChange('table')}
                color={viewMode === 'table' ? 'primary' : 'default'}
              >
                <ViewListIcon />
              </IconButton>
            </>
          )}
        </Box>
      </Box>

      {/* Tabs for passengers and birthdays */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="passenger tabs"
        >
          <Tab icon={<PeopleIcon />} label="Todos os Passageiros" id="tab-passengers" />
          <Tab icon={<CakeIcon />} label="Aniversários" id="tab-birthdays" />
          <Tab icon={<AccessTimeIcon />} label="Sem Viajar" id="tab-inactivity" />
        </Tabs>
      </Box>

      {/* First tab: regular passenger management */}
      {tabValue === 0 && (
        <>
          <FilterSection
            order={order}
            searchInputValue={searchInputValue}
            onOrderChange={handleOrderChange}
            onSearchChange={handleSearchChange}
            onSearch={handleSearch}
            onClear={clearSearch}
            onKeyPress={handleKeyPress}
            filtersVisible={filtersVisible}
          />

          {loading && currentPage === 1 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <TravelLoading disableAlignItems />
            </Box>
          ) : (
            <>
              {viewMode === 'card' ? (
                <PassengerCard
                  passengers={currentPassengers}
                  handleDeletePassenger={handleDeletePassenger}
                  startEditing={startEditing}
                  loading={loading}
                  canEdit={canEdit}
                  canDelete={canDelete}
                />
              ) : (
                <PassengerTable
                  passengers={currentPassengers}
                  handleDeletePassenger={handleDeletePassenger}
                  startEditing={startEditing}
                  loading={loading}
                  canEdit={canEdit}
                  canDelete={canDelete}
                />
              )}
              <PaginationComponent />
              {loading && currentPage > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <TravelLoading disableAlignItems />
                </Box>
              )}
            </>
          )}
        </>
      )}

      {tabValue === 1 && (
        <PassengerBirthdays />
      )}

      {tabValue === 2 && (
        <PassengerInactivity />
      )}

      <Dialog open={openFormDialog} onClose={handleCloseFormDialog} aria-labelledby="form-dialog-title">
        <DialogContent>
          <PassengerForm
            editedPassenger={editedPassenger}
            setEditedPassenger={setEditedPassenger}
            errors={errors}
            setErrors={setErrors}
            handleCloseFormDialog={handleCloseFormDialog}
            onSuccess={() => {
              handleCloseFormDialog();
              fetchPassengers(true);
              setOpenSnackbar(true);
            }}
            editing={editing}
            passageiros={passageiros}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            fetchPassengers={fetchPassengers}
          />
        </DialogContent>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      </Dialog>
    </Box>
  );
};

const FilterSection = React.memo(({
  order,
  searchInputValue,
  onOrderChange,
  onSearchChange,
  onSearch,
  onClear,
  onKeyPress,
  filtersVisible
}) => (
  <Collapse in={filtersVisible}>
    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2, alignItems: 'center', flexWrap: 'wrap' }}>
      <FormControl variant="outlined" sx={{ minWidth: 240, flexGrow: 1 }}>
        <InputLabel id="order-select-label">Ordenar Por</InputLabel>
        <Select
          labelId="order-select-label"
          id="order-select"
          value={order}
          label="Ordenar Por"
          onChange={onOrderChange}
        >
          <MenuItem value="alphabetical">Ordem Alfabética</MenuItem>
          <MenuItem value="newest">Adicionado recentemente</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ flexGrow: 1, minWidth: '240px', position: 'relative' }}>
        <TextField
          fullWidth
          label="Busca"
          variant="outlined"
          value={searchInputValue}
          onChange={onSearchChange}
          onKeyPress={onKeyPress}
          placeholder="Digite qualquer informação para buscar passageiros"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchInputValue && (
                  <Tooltip title="Limpar">
                    <IconButton onClick={onClear} size="small">
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Buscar">
                  <IconButton
                    onClick={onSearch}
                    size="small"
                    sx={{ ml: searchInputValue ? 1 : 0 }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
          autoComplete="off"
        />
      </Box>
    </Box>
  </Collapse >
));

export default PassengerPage;