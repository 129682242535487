import { db, auth } from '../../../firebaseConfig';
import { collection, doc, getDocs, query, where, getDoc, limit, startAfter, orderBy, getCountFromServer } from 'firebase/firestore';
import { getTravelById } from '../../Travel/services/TravelService';
import { getPassengerById } from '../../Passenger/services/PassengerService';

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getPaginatedReservationsAndOrders = async ({
  page = 1,
  filterType = 'reservas',
  searchTerm = '',
  paymentStatusFilter = '',
  travelId = null
}) => {
  const agencyRef = await getAgencyRef();
  const ITEMS_PER_PAGE = 9;

  try {
    // Buscar todos os passageiros ativos uma única vez
    const passengersSnapshot = await getDocs(
      query(collection(agencyRef, 'passengers'), where('estaAtivo', '==', true))
    );

    // Criar um Map com os passageiros para acesso rápido
    const passengers = new Map();
    passengersSnapshot.docs.forEach(doc => {
      passengers.set(doc.id, { id: doc.id, ...doc.data() });
    });

    // Buscar viagens ativas
    const travelQuery = query(
      collection(agencyRef, 'travels'),
      where('estaAtivo', '==', true)
    );
    const travelSnapshot = await getDocs(travelQuery);
    const travels = {};
    const allData = [];

    // Armazenar dados das viagens em memória
    travelSnapshot.docs.forEach(doc => {
      if (!travelId || doc.id === travelId) {
        travels[doc.id] = { id: doc.id, ...doc.data() };
      }
    });

    // Processar cada viagem
    for (const travelDoc of travelSnapshot.docs) {
      if (travelId && travelDoc.id !== travelId) continue;

      // Buscar pedidos relacionados à viagem
      const ordersRef = collection(agencyRef, 'orders');
      const ordersQuery = query(ordersRef, where('travelId', '==', travelDoc.id));
      const ordersSnapshot = await getDocs(ordersQuery);

      // Processar pedidos de forma assíncrona
      const processOrders = ordersSnapshot.docs.map(async (orderDoc) => {
        const orderData = orderDoc.data();

        // Buscar reservas relacionadas ao pedido
        const reservationsRef = collection(agencyRef, 'reservations');
        const reservationsQuery = query(
          reservationsRef,
          where('orderId', '==', orderDoc.id),
          where('travelId', '==', travelDoc.id)
        );
        const reservationsSnapshot = await getDocs(reservationsQuery);

        // Processar reservas com dados dos passageiros
        const reservations = reservationsSnapshot.docs.map(doc => {
          const reservationData = doc.data();
          const passengerId = reservationData.passengerId;

          return {
            id: doc.id,
            ...reservationData,
            orderId: orderDoc.id,
            travelId: travelDoc.id,
            passenger: passengers.get(passengerId),
            detalhesPagamento: orderData.detalhesPagamento,
            status: getReservationStatus(reservationData, orderData)
          };
        });

        if (filterType === 'reservas') {
          return reservations;
        } else {
          return [{
            id: orderDoc.id,
            ...orderData,
            reservations,
            travelId: travelDoc.id,
            status: getOrderStatus(orderData)
          }];
        }
      });

      // Aguardar processamento de todos os pedidos
      const processedOrders = await Promise.all(processOrders);
      allData.push(...processedOrders.flat());
    }

    // Aplicar filtros
    const filteredData = allData.filter(item => {
      const matchesSearch = searchTerm === '' || (
        filterType === 'reservas' ? (
          item.passenger?.nome?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.passenger?.cpf?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.id.toLowerCase().includes(searchTerm.toLowerCase())
        ) : (
          item.detalhesPagamento?.nomePagador?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.detalhesPagamento?.cpfPagador?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.reservations.some(res =>
            res.passenger?.nome?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            res.passenger?.cpf?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      );

      const matchesStatus = paymentStatusFilter === '' || item.status === paymentStatusFilter;

      return matchesSearch && matchesStatus;
    });

    // Ordenar dados
    const sortedData = filteredData.sort((a, b) => {
      if (a.status === 'Cancelada' && b.status !== 'Cancelada') return 1;
      if (a.status !== 'Cancelada' && b.status === 'Cancelada') return -1;
      return filterType === 'reservas'
        ? (a.numeroAssento || 0) - (b.numeroAssento || 0)
        : new Date(b.dataAdicionado || 0) - new Date(a.dataAdicionado || 0);
    });

    // Paginar resultados
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const paginatedData = sortedData.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    return {
      data: paginatedData,
      totalPages: Math.max(1, Math.ceil(sortedData.length / ITEMS_PER_PAGE)),
      currentPage: page,
      totalItems: sortedData.length,
      travels,
      passengers: Array.from(passengers.values())
    };
  } catch (error) {
    console.error('Error fetching paginated data:', error);
    throw error;
  }
};

const getReservationStatus = (reservation, order) => {
  if (reservation.status === 'Cancelada' || order.status === 'Cancelada') {
    return 'Cancelada';
  }
  return order.detalhesPagamento?.valorRestante > 0 ? 'Pagamento Pendente' : 'Pago';
};

const getOrderStatus = (order) => {
  if (order.status === 'Cancelada') return 'Cancelada';
  return order.detalhesPagamento?.valorRestante > 0 ? 'Pagamento Pendente' : 'Pago';
};

// Função para obter todas as reservas
export const getAllReservations = async () => {
  const agencyRef = await getAgencyRef();
  const travelsRef = collection(agencyRef, 'travels');
  const travelsSnapshot = await getDocs(travelsRef);

  const allReservations = [];
  const passengerMap = new Map();

  // Buscar todos os passageiros uma vez
  const passengersRef = collection(agencyRef, 'passengers');
  const passengersSnapshot = await getDocs(passengersRef);
  passengersSnapshot.docs.forEach(doc => {
    passengerMap.set(doc.id, { id: doc.id, ...doc.data() });
  });

  for (const travelDoc of travelsSnapshot.docs) {
    const travelData = travelDoc.data();
    if (!travelData.estaAtivo) continue;

    const travelId = travelDoc.id;
    const ordersRef = collection(doc(agencyRef, 'travels', travelId), 'orders');
    const ordersSnapshot = await getDocs(ordersRef);

    for (const orderDoc of ordersSnapshot.docs) {
      const orderData = orderDoc.data();
      const detalhesPagamento = orderData.detalhesPagamento || {};
      const reservationsRef = collection(orderDoc.ref, 'reservations');
      const reservationsSnapshot = await getDocs(reservationsRef);

      reservationsSnapshot.docs.forEach((doc) => {
        const reservationData = doc.data();
        const passengerId = reservationData.passengerId;
        const passenger = passengerMap.get(passengerId) || null;

        const status = orderData.status === 'Cancelada' ? 'Cancelada' :
          parseFloat(detalhesPagamento.valorRestante || 0) > 0 ? 'Pagamento Pendente' : 'Pago';

        allReservations.push({
          id: doc.id,
          ...reservationData,
          orderId: orderDoc.id,
          detalhesPagamento,
          passenger,
          travelId,
          status
        });
      });
    }
  }

  return allReservations;
};

// Função para obter todos os pedidos
export const getAllOrders = async () => {
  const agencyRef = await getAgencyRef();
  const travelsRef = collection(agencyRef, 'travels');
  const travelsSnapshot = await getDocs(travelsRef);

  const allOrders = [];
  const passengerMap = new Map();

  // Buscar todos os passageiros uma vez
  const passengersRef = collection(agencyRef, 'passengers');
  const passengersSnapshot = await getDocs(passengersRef);
  passengersSnapshot.docs.forEach(doc => {
    passengerMap.set(doc.id, { id: doc.id, ...doc.data() });
  });

  for (const travelDoc of travelsSnapshot.docs) {
    const travelData = travelDoc.data();
    if (!travelData.estaAtivo) continue;

    const travelId = travelDoc.id;
    const ordersRef = collection(doc(agencyRef, 'travels', travelId), 'orders');
    const ordersSnapshot = await getDocs(ordersRef);

    for (const orderDoc of ordersSnapshot.docs) {
      const orderData = orderDoc.data();
      const detalhesPagamento = orderData.detalhesPagamento || {};
      const reservationsRef = collection(orderDoc.ref, 'reservations');
      const reservationsSnapshot = await getDocs(reservationsRef);

      const reservations = reservationsSnapshot.docs.map(doc => {
        const reservationData = doc.data();
        const passengerId = reservationData.passengerId;
        const passenger = passengerMap.get(passengerId) || null;

        return {
          id: doc.id,
          ...reservationData,
          passenger,
          status: reservationData.status || 'Indefinido',
        };
      });

      allOrders.push({
        id: orderDoc.id,
        ...orderData,
        detalhesPagamento,
        reservations,
        travelId,
        status: orderData.status || 'Indefinido'
      });
    }
  }

  return allOrders;
};

const fetchReservationsWithSeats = async (orderDocRef) => {
  const reservationCollectionRef = collection(orderDocRef, 'reservations');
  const reservationSnapshot = await getDocs(reservationCollectionRef);

  return reservationSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    numeroAssento: doc.data().numeroAssento || null,
    status: doc.data().status || 'Indefinido'
  }));
};

// Função atualizada para buscar pedidos pendentes com reservas e assentos
export const getPendingOrders = async () => {
  const agencyRef = await getAgencyRef();
  const ordersRef = collection(agencyRef, 'orders');
  const ordersSnapshot = await getDocs(ordersRef);

  // Calcular intervalo de datas
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const thirtyDaysAhead = new Date(today);
  thirtyDaysAhead.setDate(today.getDate() + 30);

  const pendingOrders = [];

  for (const orderDoc of ordersSnapshot.docs) {
    const orderData = orderDoc.data();
    const valorRestante = parseFloat(orderData.detalhesPagamento?.valorRestante || '0');

    if (orderData.status !== 'Cancelada' && valorRestante > 0) {
      const travelRef = doc(agencyRef, 'travels', orderData.travelId);
      const travelSnap = await getDoc(travelRef);

      if (travelSnap.exists()) {
        const travelData = travelSnap.data();
        const travelDate = new Date(travelData.dataIda);

        if (travelDate >= thirtyDaysAgo && travelDate <= thirtyDaysAhead) {
          const reservationsRef = collection(agencyRef, 'reservations');
          const reservationsQuery = query(
            reservationsRef,
            where('orderId', '==', orderDoc.id),
            where('travelId', '==', orderData.travelId)
          );
          const reservationsSnapshot = await getDocs(reservationsQuery);

          const reservations = reservationsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          pendingOrders.push({
            id: orderDoc.id,
            ...orderData,
            travelId: orderData.travelId,
            travel: { id: travelSnap.id, ...travelData },
            reservations
          });
        }
      }
    }
  }

  return pendingOrders;
};

export const getPaidOrders = async () => {
  const agencyRef = await getAgencyRef();
  const ordersRef = collection(agencyRef, 'orders');
  const ordersSnapshot = await getDocs(ordersRef);

  // Calcular intervalo de datas
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const thirtyDaysAhead = new Date(today);
  thirtyDaysAhead.setDate(today.getDate() + 30);

  const paidOrders = [];

  for (const orderDoc of ordersSnapshot.docs) {
    const orderData = orderDoc.data();
    const valorRestante = parseFloat(orderData.detalhesPagamento?.valorRestante || '0');

    if (orderData.status !== 'Cancelada' && valorRestante === 0) {
      const travelRef = doc(agencyRef, 'travels', orderData.travelId);
      const travelSnap = await getDoc(travelRef);

      if (travelSnap.exists()) {
        const travelData = travelSnap.data();
        const travelDate = new Date(travelData.dataIda);

        if (travelDate >= thirtyDaysAgo && travelDate <= thirtyDaysAhead) {
          const reservationsRef = collection(agencyRef, 'reservations');
          const reservationsQuery = query(
            reservationsRef,
            where('orderId', '==', orderDoc.id),
            where('travelId', '==', orderData.travelId)
          );
          const reservationsSnapshot = await getDocs(reservationsQuery);

          const reservations = reservationsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          paidOrders.push({
            id: orderDoc.id,
            ...orderData,
            travelId: orderData.travelId,
            travel: { id: travelSnap.id, ...travelData },
            reservations
          });
        }
      }
    }
  }

  return paidOrders;
};

export const calculateMonthlyTotals = async (month, year) => {
  const pendingOrders = await getPendingOrders();
  const paidOrders = await getPaidOrders();
  const allOrders = [...pendingOrders, ...paidOrders];

  let totalReceivable = 0;
  let totalReceived = 0;

  for (const order of allOrders) {
    if (order.status !== 'Cancelada') {
      totalReceived += parseFloat(order.detalhesPagamento?.valorPago || 0);
      totalReceivable += parseFloat(order.detalhesPagamento?.valorRestante || 0);
    }
  }

  return { totalReceivable, totalReceived };
};

// Função para obter todos os passageiros
export const getAllPassengers = async () => {
  const agencyRef = await getAgencyRef();
  const passengerCollectionRef = collection(agencyRef, 'passengers');
  const q = query(passengerCollectionRef, where("estaAtivo", "==", true));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

const ITEMS_PER_PAGE = 8;
const travelCache = new Map();

const calculateTotals = (orders) => {
  return orders.reduce((totals, order) => {
    if (order.status !== 'Cancelada') {
      totals.totalReceivable += parseFloat(order.detalhesPagamento?.valorRestante || 0);
      totals.totalReceived += parseFloat(order.detalhesPagamento?.valorPago || 0);
    }
    return totals;
  }, { totalReceivable: 0, totalReceived: 0 });
};

// Helper para obter viagens com cache
const getFilteredTravels = async (month = null, year = null) => {
  try {
    const agencyRef = await getAgencyRef();

    // Query base para viagens ativas
    const travelsRef = collection(agencyRef, 'travels');
    let travelsQuery = query(travelsRef, where('estaAtivo', '==', true));

    // Se temos mês e ano, vamos criar as datas de início e fim do período
    if (month && year) {
      const startDate = `${year}-${month.toString().padStart(2, '0')}-01`;
      const nextMonth = month === 12 ? 1 : month + 1;
      const nextYear = month === 12 ? year + 1 : year;
      const endDate = `${nextYear}-${nextMonth.toString().padStart(2, '0')}-01`;

      travelsQuery = query(
        travelsRef,
        where('estaAtivo', '==', true),
        where('dataIda', '>=', startDate),
        where('dataIda', '<', endDate)
      );
    }

    const travelsSnapshot = await getDocs(travelsQuery);
    return travelsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error getting filtered travels:', error);
    throw error;
  }
};

const getOrdersByStatus = async (travels, isPending = true, searchTerm = '') => {
  try {
    const agencyRef = await getAgencyRef();
    const travelIds = travels.map(travel => travel.id);

    // Se não há viagens no período, retorna array vazio
    if (travelIds.length === 0) {
      return [];
    }

    // Buscar passageiros ativos
    const passengersSnapshot = await getDocs(
      query(collection(agencyRef, 'passengers'),
        where('estaAtivo', '==', true))
    );

    const passengerMap = new Map();
    passengersSnapshot.docs.forEach(doc => {
      passengerMap.set(doc.id, { id: doc.id, ...doc.data() });
    });

    // Buscar todos os pedidos das viagens filtradas
    const ordersRef = collection(agencyRef, 'orders');
    const ordersQuery = query(
      ordersRef,
      where('travelId', 'in', travelIds)
    );
    const ordersSnapshot = await getDocs(ordersQuery);

    const ordersPromises = ordersSnapshot.docs.map(async orderDoc => {
      const orderData = orderDoc.data();
      const orderStatus = getOrderStatus(orderData);

      // Verificar se o status corresponde ao filtro
      if (orderStatus === 'Cancelada') return null;
      if (isPending ? orderStatus !== 'Pagamento Pendente' : orderStatus !== 'Pago') return null;

      // Buscar reservas do pedido
      const reservationsRef = collection(agencyRef, 'reservations');
      const reservationsQuery = query(
        reservationsRef,
        where('orderId', '==', orderDoc.id),
        where('travelId', '==', orderData.travelId)
      );
      const reservationsSnapshot = await getDocs(reservationsQuery);

      // Processar reservas com dados dos passageiros
      const reservations = reservationsSnapshot.docs.map(doc => {
        const reservationData = doc.data();
        const passenger = passengerMap.get(reservationData.passengerId);
        return {
          id: doc.id,
          ...reservationData,
          passenger,
          orderId: orderDoc.id,
          travelId: orderData.travelId,
          status: getReservationStatus(reservationData, orderData)
        };
      });

      // Encontrar a viagem correspondente
      const travel = travels.find(t => t.id === orderData.travelId);

      // Aplicar filtro de busca
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = !searchTerm ||
        orderDoc.id.toLowerCase().includes(searchLower) ||
        orderData.detalhesPagamento?.nomePagador?.toLowerCase().includes(searchLower) ||
        orderData.detalhesPagamento?.cpfPagador?.toLowerCase().includes(searchLower) ||
        reservations.some(res =>
          res.passenger?.nome?.toLowerCase().includes(searchLower) ||
          res.passenger?.cpf?.toLowerCase().includes(searchLower)
        );

      if (!matchesSearch) return null;

      return {
        id: orderDoc.id,
        ...orderData,
        travel,
        reservations,
        status: orderStatus
      };
    });

    const orders = await Promise.all(ordersPromises);

    // Filtrar nulls e ordenar por data
    return orders
      .filter(Boolean)
      .sort((a, b) => new Date(b.dataAdicionado || 0) - new Date(a.dataAdicionado || 0));
  } catch (error) {
    console.error('Error getting orders by status:', error);
    throw error;
  }
};

// Get paginated pending orders
export const getPaginatedPendingOrders = async ({
  page = 1,
  month = null,
  year = null,
  searchTerm = ''
}) => {
  try {
    const travels = await getFilteredTravels(month, year);
    const allOrders = await getOrdersByStatus(travels, true, searchTerm);

    // Calculate totals from ALL orders, not just current page
    const { totalReceivable } = calculateTotals(allOrders);

    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Get orders for current page
    const paginatedOrders = allOrders.slice(startIndex, endIndex);

    return {
      orders: paginatedOrders,
      totalPages: Math.max(1, Math.ceil(allOrders.length / ITEMS_PER_PAGE)),
      currentPage: page,
      totalItems: allOrders.length,
      totalReceivable
    };
  } catch (error) {
    console.error('Error fetching pending orders:', error);
    throw error;
  }
};

export const getPaginatedPaidOrders = async ({
  page = 1,
  month = null,
  year = null,
  searchTerm = ''
}) => {
  try {
    const travels = await getFilteredTravels(month, year);
    const allOrders = await getOrdersByStatus(travels, false, searchTerm);

    // Calculate totals from ALL orders, not just current page
    const { totalReceived } = calculateTotals(allOrders);

    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Get orders for current page
    const paginatedOrders = allOrders.slice(startIndex, endIndex);

    return {
      orders: paginatedOrders,
      totalPages: Math.max(1, Math.ceil(allOrders.length / ITEMS_PER_PAGE)),
      currentPage: page,
      totalItems: allOrders.length,
      totalReceived
    };
  } catch (error) {
    console.error('Error fetching paid orders:', error);
    throw error;
  }
};

// Limpar cache
export const clearTravelCache = () => {
  travelCache.clear();
};