import { db, auth } from '../../../firebaseConfig';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';

const getAgencyRef = async () => {
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    return doc(db, 'agencies', userDoc.data().agencyId);
};

export const getPricingCalculationByTravelId = async (travelId) => {
    try {
        const agencyRef = await getAgencyRef();

        // Get travel data for seats info
        const travelRef = doc(agencyRef, 'travels', travelId);
        const travelDoc = await getDoc(travelRef);

        if (!travelDoc.exists()) {
            throw new Error('Viagem não encontrada');
        }

        const travelData = travelDoc.data();
        let suggestedSeats = 46; // valor padrão

        // Get vehicle and layout information if available
        if (travelData.veiculoId) {
            const vehicleRef = doc(agencyRef, 'vehicles', travelData.veiculoId);
            const vehicleDoc = await getDoc(vehicleRef);

            if (vehicleDoc.exists()) {
                const vehicleData = vehicleDoc.data();

                if (vehicleData.layoutId) {
                    const layoutRef = doc(agencyRef, 'layouts', vehicleData.layoutId);
                    const layoutDoc = await getDoc(layoutRef);

                    if (layoutDoc.exists()) {
                        suggestedSeats = layoutDoc.data().assentosTotais || 46;
                    }
                }
            }
        }

        // Get pricing calculation from the dedicated collection
        const pricingCalculationsRef = collection(agencyRef, 'pricingCalculations');
        const calculationQuery = query(pricingCalculationsRef, where('travelId', '==', travelId));
        const calculationSnapshot = await getDocs(calculationQuery);

        let pricingData = null;
        if (!calculationSnapshot.empty) {
            pricingData = calculationSnapshot.docs[0].data();
        }

        // Return existing data or default structure
        return {
            fixedCosts: pricingData?.fixedCosts || [
                { description: '', value: '0,00' }
            ],
            variableCosts: pricingData?.variableCosts || [
                { description: '', value: '0,00' }
            ],
            totalSeats: pricingData?.totalSeats || suggestedSeats, // usar valor existente ou sugestão
            suggestedSeats: suggestedSeats, // nova propriedade para sugestão
            occupiedSeats: travelData.numReservas || 0,
            profitMargin: pricingData?.profitMargin || 30,
            suggestedPrice: pricingData?.suggestedPrice || '0,00',
            lastCalculation: pricingData?.lastCalculation || null
        };
    } catch (error) {
        console.error('Erro ao buscar cálculo de preços:', error);
        throw error;
    }
};

export const updatePricingCalculation = async (travelId, calculationData) => {
    try {
        const agencyRef = await getAgencyRef();
        const pricingCalculationsRef = collection(agencyRef, 'pricingCalculations');

        // Get current travel data to ensure we have the latest seat numbers
        const travelRef = doc(agencyRef, 'travels', travelId);
        const travelDoc = await getDoc(travelRef);
        const travelData = travelDoc.data();

        const timestamp = new Date().toISOString();

        // Use the actual number of occupied seats from travel data
        const occupiedSeats = travelData.numReservas || calculationData.occupiedSeats;

        // Calculate totals
        const totalFixed = calculationData.fixedCosts.reduce((sum, cost) =>
            sum + parseFloat(cost.value.replace(',', '.') || 0), 0);

        const totalVariable = calculationData.variableCosts.reduce((sum, cost) =>
            sum + parseFloat(cost.value.replace(',', '.') || 0), 0);

        const totalCost = totalFixed + totalVariable;
        const costPerSeat = totalCost / occupiedSeats;
        const pricePerSeat = costPerSeat * (1 + (calculationData.profitMargin / 100));

        // First check if a calculation already exists
        const calculationQuery = query(pricingCalculationsRef, where('travelId', '==', travelId));
        const calculationSnapshot = await getDocs(calculationQuery);

        const pricingCalculation = {
            travelId,
            fixedCosts: calculationData.fixedCosts,
            variableCosts: calculationData.variableCosts,
            totalSeats: calculationData.totalSeats,
            occupiedSeats: occupiedSeats,
            profitMargin: calculationData.profitMargin,
            suggestedPrice: pricePerSeat.toFixed(2),
            lastCalculation: {
                timestamp,
                totalFixedCosts: totalFixed.toFixed(2),
                totalVariableCosts: totalVariable.toFixed(2),
                totalCost: totalCost.toFixed(2),
                costPerSeat: costPerSeat.toFixed(2),
                suggestedPricePerSeat: pricePerSeat.toFixed(2)
            },
            metadata: {
                updatedAt: timestamp,
                calculationHistory: [
                    {
                        timestamp,
                        totalFixed: totalFixed.toFixed(2),
                        totalVariable: totalVariable.toFixed(2),
                        totalCost: totalCost.toFixed(2),
                        costPerSeat: costPerSeat.toFixed(2),
                        pricePerSeat: pricePerSeat.toFixed(2),
                        profitMargin: calculationData.profitMargin,
                        occupiedSeats: occupiedSeats
                    }
                ]
            }
        };

        if (!calculationSnapshot.empty) {
            // Update existing document
            const existingDoc = calculationSnapshot.docs[0];
            const existingData = existingDoc.data();

            // Append to calculation history
            pricingCalculation.metadata.calculationHistory = [
                ...existingData.metadata.calculationHistory || [],
                pricingCalculation.metadata.calculationHistory[0]
            ].slice(-10); // Keep last 10 calculations

            await setDoc(doc(pricingCalculationsRef, existingDoc.id), pricingCalculation);
        } else {
            // Create new document
            pricingCalculation.metadata.createdAt = timestamp;
            const docRef = doc(pricingCalculationsRef, `${travelId}_pricing`);
            await setDoc(docRef, pricingCalculation);
        }

        return pricingCalculation;
    } catch (error) {
        console.error('Erro ao atualizar cálculo de preços:', error);
        throw error;
    }
};

export const formatCurrency = (value) => {
    if (!value) return '0,00';
    return Number(value).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

export const calculateTotals = (calculationData) => {
    try {
        // Calcula total de custos fixos
        const totalFixed = calculationData.fixedCosts.reduce((sum, cost) =>
            sum + parseFloat(cost.value.replace(',', '.') || 0), 0);

        // Calcula total de custos variáveis
        const totalVariable = calculationData.variableCosts.reduce((sum, cost) =>
            sum + parseFloat(cost.value.replace(',', '.') || 0), 0);

        // Calcula o custo total
        const totalCost = totalFixed + totalVariable;

        // Verifica se há assentos antes de fazer a divisão
        if (!calculationData.totalSeats || calculationData.totalSeats <= 0) {
            throw new Error('Total de assentos inválido');
        }

        // Calcula custo por assento (custo total dividido pelo total de assentos)
        const costPerSeat = totalCost / calculationData.totalSeats;

        // Calcula preço sugerido aplicando a margem de lucro
        const marginMultiplier = 1 + (calculationData.profitMargin / 100);
        const pricePerSeat = costPerSeat * marginMultiplier;

        // Calcula quantidade mínima de assentos para cobrir custos
        // Precisamos dividir o custo total pelo preço por assento com margem
        const minSeatsToBreakeven = Math.ceil(totalCost / pricePerSeat);

        return {
            totalFixed: formatCurrency(totalFixed),
            totalVariable: formatCurrency(totalVariable),
            totalCost: formatCurrency(totalCost),
            costPerSeat: formatCurrency(costPerSeat),
            pricePerSeat: formatCurrency(pricePerSeat),
            minSeatsToBreakeven: minSeatsToBreakeven || 0
        };
    } catch (error) {
        console.error('Erro ao calcular totais:', error);
        return {
            totalFixed: formatCurrency(0),
            totalVariable: formatCurrency(0),
            totalCost: formatCurrency(0),
            costPerSeat: formatCurrency(0),
            pricePerSeat: formatCurrency(0),
            minSeatsToBreakeven: 0
        };
    }
};

export const getPricingCalculationHistory = async (travelId) => {
    try {
        const agencyRef = await getAgencyRef();
        const pricingCalculationsRef = collection(agencyRef, 'pricingCalculations');

        const calculationQuery = query(pricingCalculationsRef, where('travelId', '==', travelId));
        const calculationSnapshot = await getDocs(calculationQuery);

        if (calculationSnapshot.empty) {
            return [];
        }

        const calculationData = calculationSnapshot.docs[0].data();
        return calculationData.metadata.calculationHistory || [];
    } catch (error) {
        console.error('Erro ao buscar histórico de cálculos:', error);
        throw error;
    }
};