import { db, auth } from '../../../firebaseConfig';
import { collection, doc, addDoc, getDocs, query, where, updateDoc, getDoc, writeBatch, orderBy, limit, startAfter } from 'firebase/firestore';
import { getReservationsByTravelId, updateReservationCountForTravel } from '../../Allocation/services/OrderService';
import { getLayoutById } from '../../LayoutPage/services/LayoutService';
import { updateTravelStats, updateAgencyStats } from '../../MyAccount/services/AgencyStatistics';

export const TRAVEL_TYPES = {
  REGULAR: 'regular',
  FREIGHT: 'freight'
};

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

const getTravelsCollectionRef = async () => {
  const agencyRef = await getAgencyRef();
  return collection(agencyRef, 'travels');
};

const getStatus = (travel) => {
  const now = new Date();
  const startDate = new Date(travel.dataIda + "T" + travel.horarioIda);
  let endDate = new Date(travel.dataRetorno + "T" + travel.horarioRetorno);
  const thirtyDaysFromNow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 30);

  if (travel.somenteIda) {
    endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
  }

  if (travel.status === 'Cancelada') {
    return 'Cancelada';
  }
  if (startDate > thirtyDaysFromNow) {
    return 'Criada';
  } else if (startDate <= now && endDate >= now) {
    return 'Em andamento';
  } else if (startDate > now && startDate <= thirtyDaysFromNow) {
    return 'Próxima';
  } else if (endDate < now) {
    return 'Encerrada';
  }
  return 'Indefinido';
};

const getStatusPriority = (status) => {
  const priorities = {
    'Em andamento': 1,
    'Próxima': 2,
    'Criada': 3,
    'Encerrada': 4,
    'Cancelada': 5
  };
  return priorities[status] || 6;
};

export const addTravel = async (travel) => {
  const travelsRef = await getTravelsCollectionRef();
  const timestamp = new Date().toISOString();

  const travelData = {
    ...travel,
    type: travel.type || TRAVEL_TYPES.REGULAR,
    allowedUsers: travel.allowedUsers || [],
    status: getStatus(travel),
    estaAtivo: true,
    numReservas: 0,
    metadata: {
      createdAt: timestamp,
      updatedAt: timestamp,
      deletedAt: null
    }
  };

  const travelDoc = await addDoc(travelsRef, travelData);
  await updateTravelStats(1);
  return { id: travelDoc.id, createdAt: timestamp };
};

export const updateTravel = async (travelId, travelData) => {
  const travelsRef = await getTravelsCollectionRef();
  const travelRef = doc(travelsRef, travelId);
  const timestamp = new Date().toISOString();

  const dataToUpdate = {
    ...travelData,
    type: travelData.type || TRAVEL_TYPES.REGULAR,
    status: getStatus(travelData),
    estaAtivo: travelData.hasOwnProperty('estaAtivo') ? travelData.estaAtivo : true,
    metadata: {
      ...travelData.metadata,
      updatedAt: timestamp
    }
  };

  return await updateDoc(travelRef, dataToUpdate);
};

export const deleteTravel = async (travelId) => {
  const travelsRef = await getTravelsCollectionRef();
  const travelRef = doc(travelsRef, travelId);
  const timestamp = new Date().toISOString();
  await updateTravelStats(-1);

  return await updateDoc(travelRef, {
    estaAtivo: false,
    metadata: {
      updatedAt: timestamp,
      deletedAt: timestamp
    }
  });
};

export const cancelTravel = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();
    const travelRef = doc(agencyRef, 'travels', travelId);
    const batch = writeBatch(db);

    batch.update(travelRef, { status: 'Cancelada' });

    const ordersRef = collection(agencyRef, 'orders');
    const ordersQuery = query(ordersRef, where('travelId', '==', travelId));
    const ordersSnapshot = await getDocs(ordersQuery);

    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(reservationsRef, where('travelId', '==', travelId));
    const reservationsSnapshot = await getDocs(reservationsQuery);

    ordersSnapshot.docs.forEach(orderDoc => {
      const orderRef = doc(agencyRef, 'orders', orderDoc.id);
      batch.update(orderRef, { status: 'Cancelada' });
    });

    reservationsSnapshot.docs.forEach(reservationDoc => {
      const reservationRef = doc(agencyRef, 'reservations', reservationDoc.id);
      batch.update(reservationRef, { status: 'Cancelada' });
    });

    await batch.commit();
    await updateReservationCountForTravel(travelId);

  } catch (error) {
    console.error('Error canceling travel:', error);
    throw error;
  }
};

export const getPaginatedTravels = async (pageSize = 9, lastDoc = null, filters = {}) => {
  try {
    const agencyRef = await getAgencyRef();
    if (!agencyRef) {
      throw new Error('Referência da agência não encontrada');
    }

    const travelsRef = collection(agencyRef, 'travels');

    let preQueryConstraints = [where("estaAtivo", "==", true)];

    if (filters.type) {
      preQueryConstraints.push(where("type", "==", filters.type));
    }
    if (filters.status) {
      preQueryConstraints.push(where("status", "==", filters.status));
    }
    if (filters.startDate) {
      preQueryConstraints.push(where("dataIda", ">=", filters.startDate));
    }
    if (filters.endDate) {
      preQueryConstraints.push(where("dataRetorno", "<=", filters.endDate));
    }

    // Pegar o usuário atual do auth
    const currentUser = auth.currentUser;
    if (currentUser) {
      const memberRef = doc(agencyRef, 'members', currentUser.uid);
      const memberDoc = await getDoc(memberRef);

      if (memberDoc.exists()) {
        const memberData = memberDoc.data();
        // Verificar se não é owner/admin e aplicar filtro de allowedUsers
        if (!['owner', 'admin'].includes(memberData.roleId)) {
          preQueryConstraints.push(
            where("allowedUsers", "array-contains", currentUser.uid)
          );
        }
      }
    }

    const preQuery = query(travelsRef, ...preQueryConstraints);
    const preSnapshot = await getDocs(preQuery);

    let allTravels = await Promise.all(
      preSnapshot.docs.map(async (document) => {
        const travel = { id: document.id, ...document.data() };
        travel.statusPriority = getStatusPriority(travel.status);

        if (travel.veiculoId) {
          const vehicleRef = collection(agencyRef, 'vehicles');
          const vehicleDoc = await getDoc(doc(vehicleRef, travel.veiculoId));

          if (vehicleDoc.exists()) {
            travel.veiculo = { id: vehicleDoc.id, ...vehicleDoc.data() };

            if (travel.veiculo.layoutId) {
              const layoutRef = collection(agencyRef, 'layouts');
              const layoutDoc = await getDoc(doc(layoutRef, travel.veiculo.layoutId));

              if (layoutDoc.exists()) {
                travel.layout = { id: layoutDoc.id, ...layoutDoc.data() };
                travel.totalSeats = travel.layout.assentosTotais || 0;
              }
            }
          }
        }
        return travel;
      })
    );

    if (filters.searchTerm && filters.searchTerm.trim()) {
      const searchTerm = filters.searchTerm.toLowerCase();
      allTravels = allTravels.filter(travel =>
        (travel.origem?.toLowerCase().includes(searchTerm)) ||
        (travel.destino?.toLowerCase().includes(searchTerm)) ||
        (travel.identificador?.toLowerCase().includes(searchTerm)) ||
        (travel.descricao?.toLowerCase().includes(searchTerm))
      );
    }

    allTravels.sort((a, b) => {
      if (a.statusPriority !== b.statusPriority) {
        return a.statusPriority - b.statusPriority;
      }
      return new Date(a.dataIda) - new Date(b.dataIda);
    });

    const currentStartIndex = lastDoc
      ? allTravels.findIndex(t => t.id === lastDoc.id) + 1
      : 0;
    const paginatedTravels = allTravels.slice(
      currentStartIndex,
      currentStartIndex + pageSize
    );
    const lastDocument = preSnapshot.docs.find(
      document => document.id === paginatedTravels[paginatedTravels.length - 1]?.id
    );

    return {
      travels: paginatedTravels,
      lastDoc: lastDocument,
      hasMore: allTravels.length > currentStartIndex + pageSize,
      totalCount: allTravels.length,
    };
  } catch (error) {
    console.error("Falha ao buscar viagens paginadas:", error);
    throw error;
  }
};

export const getAllTravels = async () => {
  try {
    const agencyRef = await getAgencyRef();

    const travelsRef = collection(agencyRef, 'travels');
    const q = query(travelsRef, where('estaAtivo', '==', true));
    const snapshot = await getDocs(q);

    const travels = await Promise.all(
      snapshot.docs.map(async (document) => {
        const travel = { id: document.id, ...document.data() };

        await updateReservationCountForTravel(travel.id);

        if (travel.veiculoId) {
          const vehicleRef = doc(agencyRef, 'vehicles', travel.veiculoId);
          const vehicleDoc = await getDoc(vehicleRef);

          if (vehicleDoc.exists()) {
            travel.veiculo = { id: vehicleDoc.id, ...vehicleDoc.data() };

            if (travel.veiculo.layoutId) {
              const layoutRef = doc(agencyRef, 'layouts', travel.veiculo.layoutId);
              const layoutDoc = await getDoc(layoutRef);

              if (layoutDoc.exists()) {
                travel.layout = { id: layoutDoc.id, ...layoutDoc.data() };
                travel.totalSeats = travel.layout.assentosTotais || 0;
              }
            }
          }
        }

        return travel;
      })
    );

    for (const travel of travels) {
      const newStatus = getStatus(travel);
      if (newStatus !== travel.status) {
        const travelRef = doc(agencyRef, 'travels', travel.id);
        await updateDoc(travelRef, { status: newStatus });
        travel.status = newStatus;
      }
    }

    return travels;
  } catch (error) {
    console.error("Falha ao buscar todas as viagens:", error);
    throw error;
  }
};

export const getTravelById = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();

    const travelRef = doc(agencyRef, 'travels', travelId);
    const travelDoc = await getDoc(travelRef);

    if (!travelDoc.exists()) {
      throw new Error('Viagem não encontrada com este ID');
    }

    const travel = { id: travelDoc.id, ...travelDoc.data() };

    const newStatus = getStatus(travel);
    if (travel.status !== newStatus) {
      await updateDoc(travelRef, { status: newStatus });
      travel.status = newStatus;
    }

    if (travel.veiculoId) {
      const vehicleRef = doc(agencyRef, 'vehicles', travel.veiculoId);
      const vehicleDoc = await getDoc(vehicleRef);

      if (vehicleDoc.exists()) {
        travel.veiculo = { id: vehicleDoc.id, ...vehicleDoc.data() };

        if (travel.veiculo.layoutId) {
          const layoutRef = doc(agencyRef, 'layouts', travel.veiculo.layoutId);
          const layoutDoc = await getDoc(layoutRef);

          if (layoutDoc.exists()) {
            travel.layout = { id: layoutDoc.id, ...layoutDoc.data() };
            travel.totalSeats = travel.layout.assentosTotais || 0;
          }
        }
      }
    }

    return travel;
  } catch (error) {
    console.error("Falha ao buscar viagem por ID:", error);
    throw error;
  }
};

export const getFilteredTravels = async () => {
  const travelsRef = await getTravelsCollectionRef();

  const q = query(travelsRef, where('estaAtivo', '==', true));
  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    return [];
  }

  let travels = await Promise.all(
    snapshot.docs.map(async (doc) => {
      const travel = { id: doc.id, ...doc.data() };

      await updateReservationCountForTravel(travel.id);

      if (travel.veiculoId) {
        const vehicle = await getVehicleById(travel.veiculoId);
        travel.veiculo = vehicle;

        if (vehicle?.layoutId) {
          const layout = await getLayoutById(vehicle.layoutId);
          travel.layout = layout;
          travel.totalSeats = layout?.assentosTotais || 0;
        }
      }

      return travel;
    })
  );

  travels = travels.filter(travel =>
    travel.status !== 'Cancelada' && travel.status !== 'Encerrada'
  );

  for (const travel of travels) {
    const newStatus = getStatus(travel);
    if (newStatus !== travel.status) {
      const travelRef = doc(travelsRef, travel.id);
      await updateDoc(travelRef, { status: newStatus });
      travel.status = newStatus;
    }
  }

  travels.sort((a, b) => new Date(a.dataIda) - new Date(b.dataIda));
  const finalTravels = travels.slice(0, 3);

  return finalTravels;
};

export const getVehicleById = async (vehicleId) => {
  const agencyRef = await getAgencyRef();
  const vehicleRef = doc(agencyRef, 'vehicles', vehicleId);
  const vehicleSnapshot = await getDoc(vehicleRef);

  return vehicleSnapshot.exists() ? { id: vehicleId, ...vehicleSnapshot.data() } : null;
};

export const checkTravelIdentifierUnique = async (identifier) => {
  const travelsRef = await getTravelsCollectionRef();
  const q = query(travelsRef, where("identificador", "==", identifier));
  const snapshot = await getDocs(q);
  return snapshot.empty;
}

export const validateSeatChange = async (travelId, newVehicleId) => {
  try {
    const agencyRef = await getAgencyRef();

    const vehicleRef = doc(agencyRef, 'vehicles', newVehicleId);
    const vehicleDoc = await getDoc(vehicleRef);

    if (!vehicleDoc.exists()) {
      throw new Error('Veículo não encontrado');
    }

    const vehicle = { id: vehicleDoc.id, ...vehicleDoc.data() };

    let totalSeats = 0;
    if (vehicle.layoutId) {
      const layoutRef = doc(agencyRef, 'layouts', vehicle.layoutId);
      const layoutDoc = await getDoc(layoutRef);

      if (layoutDoc.exists()) {
        const layout = { id: layoutDoc.id, ...layoutDoc.data() };
        totalSeats = layout.assentosTotais || 0;
      }
    }

    if (totalSeats === 0) {
      throw new Error('Layout do veículo não encontrado ou sem assentos definidos');
    }

    const reservationsRef = collection(agencyRef, 'reservations');
    const q = query(
      reservationsRef,
      where('travelId', '==', travelId),
      where('estaAtivo', '==', true)
    );

    const reservationsSnapshot = await getDocs(q);
    const reservations = reservationsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const invalidReservations = reservations.filter(
      reservation => reservation.numeroAssento && reservation.numeroAssento > totalSeats
    );

    if (invalidReservations.length > 0) {
      const invalidSeats = invalidReservations
        .map(reservation => reservation.numeroAssento)
        .join(', ');
      return `Existem reservas em assentos que não existem no novo ônibus. Assentos inválidos: ${invalidSeats}`;
    }

    return null;
  } catch (error) {
    console.error('Erro ao validar mudança de veículo:', error);
    throw error;
  }
};

export const getMaxTravelIdentifier = async () => {
  const travelsRef = await getTravelsCollectionRef();
  const q = query(travelsRef, orderBy('identificador', 'desc'), limit(1));
  const snapshot = await getDocs(q);

  return snapshot.empty ? 0 : parseInt(snapshot.docs[0].data().identificador, 10);
};
