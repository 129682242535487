import React, { useState } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Paper,
    Typography,
    useTheme
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SecurityIcon from '@mui/icons-material/Security';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';

import UpdateAgencyPage from './components/UpdateAgencyPage';
import AgencySettingsTab from './components/AgencySettingsTab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`agency-tabpanel-${index}`}
            aria-labelledby={`agency-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `agency-tab-${index}`,
        'aria-controls': `agency-tabpanel-${index}`,
    };
}

const AgencyTabsPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const theme = useTheme();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ width: '100%', px: 2, pt: 2 }}>
            <Paper elevation={0}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="agency settings tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                            '& .MuiTab-root': {
                                minHeight: '48px',
                                py: 1,
                                textTransform: 'none',
                                fontSize: '0.875rem'
                            }
                        }}
                    >
                        <Tab
                            icon={<InfoIcon fontSize="small" />}
                            iconPosition="start"
                            label="Informações Gerais"
                            {...a11yProps(0)}
                        />
                        <Tab
                            icon={<SettingsIcon fontSize="small" />}
                            iconPosition="start"
                            label="Configurações"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </Box>

                <TabPanel value={activeTab} index={0}>
                    <UpdateAgencyPage />
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    <AgencySettingsTab />
                </TabPanel>

                <TabPanel value={activeTab} index={2}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="150px">
                        <Typography color="text.secondary" variant="body2">
                            Configurações de segurança da agência (em desenvolvimento)
                        </Typography>
                    </Box>
                </TabPanel>

                <TabPanel value={activeTab} index={3}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="150px">
                        <Typography color="text.secondary" variant="body2">
                            Informações de faturamento e assinatura (em desenvolvimento)
                        </Typography>
                    </Box>
                </TabPanel>

                <TabPanel value={activeTab} index={4}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="150px">
                        <Typography color="text.secondary" variant="body2">
                            Gestão de colaboradores da agência (em desenvolvimento)
                        </Typography>
                    </Box>
                </TabPanel>
            </Paper>
        </Box>
    );
};

export default AgencyTabsPage;