import { db, auth } from '../../../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, getDoc, writeBatch, arrayUnion, arrayRemove, query, where } from 'firebase/firestore';

// Obtém a referência do documento do usuário autenticado
const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

const getCurrentUserId = () => {
  const user = auth.currentUser;
  if (!user) throw new Error('Usuário não autenticado');
  return user.uid;
};

// Função para manipular o status de pedidos e reservas
const updateOrderAndReservationStatus = async (orderId, travelId) => {
  const agencyRef = await getAgencyRef();
  const orderRef = doc(agencyRef, 'orders', orderId);

  // Buscar todas as reservas do pedido
  const reservationsRef = collection(agencyRef, 'reservations');
  const reservationsQuery = query(
    reservationsRef,
    where('orderId', '==', orderId),
    where('travelId', '==', travelId)
  );
  const reservationsSnapshot = await getDocs(reservationsQuery);

  // Verificar status das reservas
  let allReservationsCancelled = true;
  const orderDoc = await getDoc(orderRef);
  const orderData = orderDoc.data();

  const valorTotal = Number(orderData.detalhesPagamento?.valorTotal || 0);
  const valorPago = Number(orderData.detalhesPagamento?.valorPago || 0);
  const valorRestante = valorTotal - valorPago;

  reservationsSnapshot.forEach((reservationDoc) => {
    const reservation = reservationDoc.data();
    if (reservation.status !== 'Cancelada') {
      allReservationsCancelled = false;
    }
  });

  // Determinar novo status do pedido
  let newOrderStatus = 'Indefinido';

  if (allReservationsCancelled) {
    newOrderStatus = 'Cancelada';
  } else if (valorRestante > 0) {
    newOrderStatus = 'Pagamento Pendente';
  } else if (valorPago === valorTotal && valorRestante === 0) {
    newOrderStatus = 'Pago';
  } else if (valorTotal === 0 && valorPago === 0) {
    newOrderStatus = 'Pago';
  }

  // Atualizar status do pedido
  await updateDoc(orderRef, { status: newOrderStatus });

  // Atualizar status das reservas em lote
  const batch = writeBatch(db);
  reservationsSnapshot.forEach((reservationDoc) => {
    const reservation = reservationDoc.data();
    if (reservation.status !== 'Cancelada') {
      const reservationRef = doc(agencyRef, 'reservations', reservationDoc.id);
      batch.update(reservationRef, { status: newOrderStatus });
    }
  });

  await batch.commit();
};

const updatePassengerReservationRefs = async (passengerId, reservation, operation = 'add') => {
  const agencyRef = await getAgencyRef();
  const passengerRef = doc(agencyRef, 'passengers', passengerId);

  const reservationRef = {
    id: reservation.id,
    travelId: reservation.travelId,
    orderId: reservation.orderId,
    dataAdicionado: new Date().toISOString(),
  };

  try {
    const passengerDoc = await getDoc(passengerRef);
    if (!passengerDoc.exists()) {
      throw new Error('Passageiro não encontrado');
    }

    if (operation === 'add') {
      await updateDoc(passengerRef, {
        reservationRefs: arrayUnion(reservationRef)
      });
    } else if (operation === 'remove' || operation === 'update') {
      const currentData = passengerDoc.data();
      const currentRefs = currentData.reservationRefs || [];

      const updatedRefs = currentRefs.filter(ref =>
        ref.id !== reservation.id ||
        ref.travelId !== reservation.travelId ||
        ref.orderId !== reservation.orderId
      );

      if (operation === 'update') {
        updatedRefs.push(reservationRef);
      }

      await updateDoc(passengerRef, {
        reservationRefs: updatedRefs
      });
    }
  } catch (error) {
    console.error('Erro ao atualizar referências de reserva do passageiro:', error);
    throw error;
  }
};

export const addOrder = async (order) => {
  const agencyRef = await getAgencyRef();
  const ordersRef = collection(agencyRef, 'orders');
  const userId = getCurrentUserId();

  if (order.detalhesPagamento) {
    ['valorTotal', 'valorPago', 'valorRestante'].forEach(field => {
      if (order.detalhesPagamento[field]) {
        order.detalhesPagamento[field] = Number(
          order.detalhesPagamento[field].replace(',', '.')
        ).toFixed(2);
      }
    });
  }

  const timestamp = new Date().toISOString();
  const orderWithMetadata = {
    ...order,
    status: order.status || 'Pendente',
    metadata: {
      createdAt: timestamp,
      updatedAt: timestamp,
      deletedAt: null,
      createdBy: userId,
      lastUpdatedBy: userId
    }
  };

  const orderDoc = await addDoc(ordersRef, orderWithMetadata);
  return orderDoc.id;
};

export const updateOrder = async (orderId, orderData) => {
  const agencyRef = await getAgencyRef();
  const orderRef = doc(agencyRef, 'orders', orderId);
  const userId = getCurrentUserId();

  const orderSnapshot = await getDoc(orderRef);
  if (!orderSnapshot.exists()) {
    throw new Error('Order not found');
  }

  if (orderData.detalhesPagamento) {
    ['valorTotal', 'valorPago', 'valorRestante'].forEach(field => {
      if (orderData.detalhesPagamento[field]) {
        orderData.detalhesPagamento[field] = Number(
          orderData.detalhesPagamento[field].replace(',', '.')
        ).toFixed(2);
      }
    });
  }

  const currentOrder = orderSnapshot.data();
  const currentMetadata = currentOrder.metadata || {};

  const timestamp = new Date().toISOString();
  const orderWithMetadata = {
    ...orderData,
    metadata: {
      ...currentMetadata,
      updatedAt: timestamp,
      lastUpdatedBy: userId
    }
  };

  await updateDoc(orderRef, orderWithMetadata);
  await updateOrderAndReservationStatus(orderId, orderData.travelId);
};

export const cancelOrder = async (travelId, orderId) => {
  const agencyRef = await getAgencyRef();
  const orderRef = doc(agencyRef, 'orders', orderId);
  const userId = getCurrentUserId();

  const batch = writeBatch(db);
  const timestamp = new Date().toISOString();

  // Buscar reservas associadas ao pedido
  const reservationsRef = collection(agencyRef, 'reservations');
  const reservationsQuery = query(
    reservationsRef,
    where('orderId', '==', orderId),
    where('travelId', '==', travelId)
  );
  const reservationsSnapshot = await getDocs(reservationsQuery);

  // Atualizar status das reservas
  reservationsSnapshot.forEach((reservationDoc) => {
    const reservationRef = doc(agencyRef, 'reservations', reservationDoc.id);
    batch.update(reservationRef, {
      status: 'Cancelada',
      'metadata.cancellationInfo': {
        canceledAt: timestamp,
        canceledBy: userId
      }
    });
  });

  // Atualizar status do pedido
  batch.update(orderRef, {
    status: 'Cancelada',
    metadata: {
      ...orderDoc.data().metadata,
      updatedAt: timestamp,
      lastUpdatedBy: userId,
      cancellationInfo: {
        canceledAt: timestamp,
        canceledBy: userId
      }
    }
  });

  await batch.commit();
  await updateReservationCountForTravel(travelId);
};

export const addReservation = async (orderId, reservation) => {
  const agencyRef = await getAgencyRef();
  const reservationsRef = collection(agencyRef, 'reservations');
  const userId = getCurrentUserId();

  try {
    const timestamp = new Date().toISOString();
    const reservationWithMetadata = {
      ...reservation,
      orderId,
      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
        deletedAt: null,
        createdBy: userId,
        lastUpdatedBy: userId
      }
    };

    const reservationDoc = await addDoc(reservationsRef, reservationWithMetadata);

    // Atualizar referências no documento do passageiro
    await updatePassengerReservationRefs(reservation.passengerId, {
      id: reservationDoc.id,
      travelId: reservation.travelId,
      orderId: orderId,
      status: reservation.status || 'Pendente'
    }, 'add');

    // Atualizar status do pedido e contagem de reservas
    await updateOrderAndReservationStatus(orderId, reservation.travelId);
    await updateReservationCountForTravel(reservation.travelId);

    return {
      reservationId: reservationDoc.id,
      metadata: {
        createdAt: timestamp
      }
    };
  } catch (error) {
    console.error('Erro ao adicionar reserva:', error);
    throw error;
  }
};

export const updateReservation = async (reservationId, reservation) => {
  const agencyRef = await getAgencyRef();
  const reservationRef = doc(agencyRef, 'reservations', reservationId);
  const userId = getCurrentUserId();

  if (!reservationId || !reservation.travelId || !reservation.orderId) {
    throw new Error('Dados da reserva inválidos');
  }

  try {
    const oldReservationSnap = await getDoc(reservationRef);
    if (!oldReservationSnap.exists()) {
      throw new Error('Reserva não encontrada');
    }

    const currentMetadata = oldReservationSnap.data().metadata || {};
    const timestamp = new Date().toISOString();

    const reservationWithMetadata = {
      ...reservation,
      metadata: {
        ...currentMetadata,
        updatedAt: timestamp,
        lastUpdatedBy: userId
      }
    };

    await updateDoc(reservationRef, reservationWithMetadata);

    // Atualizar referências do passageiro
    await updatePassengerReservationRefs(reservation.passengerId, {
      id: reservationId,
      travelId: reservation.travelId,
      orderId: reservation.orderId,
      status: reservation.status
    }, 'update');

    await updateOrderAndReservationStatus(reservation.orderId, reservation.travelId);
    await updateReservationCountForTravel(reservation.travelId);
  } catch (error) {
    console.error('Erro ao atualizar reserva:', error);
    throw error;
  }
};

export const cancelReservation = async (travelId, orderId, reservationId) => {
  const agencyRef = await getAgencyRef();
  const reservationRef = doc(agencyRef, 'reservations', reservationId);
  const userId = getCurrentUserId();

  try {
    const reservationSnap = await getDoc(reservationRef);
    if (!reservationSnap.exists()) {
      throw new Error('Reserva não encontrada');
    }

    const currentMetadata = reservationSnap.data().metadata || {};
    const timestamp = new Date().toISOString();

    await updateDoc(reservationRef, {
      status: 'Cancelada',
      metadata: {
        ...currentMetadata,
        updatedAt: timestamp,
        lastUpdatedBy: userId,
        cancellationInfo: {
          canceledAt: timestamp,
          canceledBy: userId
        }
      }
    });

    const reservationData = reservationSnap.data();

    // Atualizar status da reserva
    await updateDoc(reservationRef, { status: 'Cancelada' });

    // Atualizar referências do passageiro
    await updatePassengerReservationRefs(reservationData.passengerId, {
      id: reservationId,
      travelId,
      orderId,
      status: 'Cancelada'
    }, 'update');

    await updateOrderAndReservationStatus(orderId, travelId);
    await updateReservationCountForTravel(travelId);
  } catch (error) {
    console.error('Erro ao cancelar reserva:', error);
    throw error;
  }
};

// Função para obter todas as reservas por ID de viagem
export const getReservationsByTravelId = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);
    const allReservations = [];

    for (const reservationDoc of reservationsSnapshot.docs) {
      const reservationData = reservationDoc.data();

      // Buscar dados do pedido associado
      const orderRef = doc(agencyRef, 'orders', reservationData.orderId);
      const orderSnap = await getDoc(orderRef);
      const orderData = orderSnap.data();

      // Buscar dados do passageiro
      const passengerRef = doc(agencyRef, 'passengers', reservationData.passengerId);
      const passengerSnap = await getDoc(passengerRef);
      const passengerData = passengerSnap.exists() ? passengerSnap.data() : null;

      // Combinar dados da reserva com dados do pedido e passageiro
      allReservations.push({
        id: reservationDoc.id,
        ...reservationData,
        orderId: reservationData.orderId,
        detalhesPagamento: orderData?.detalhesPagamento,
        status: reservationData.status || orderData?.status,
        passenger: passengerData ? { id: passengerSnap.id, ...passengerData } : null
      });
    }

    // Atualizar status dos pedidos
    const uniqueOrderIds = [...new Set(allReservations.map(r => r.orderId))];
    await Promise.all(uniqueOrderIds.map(orderId =>
      updateOrderAndReservationStatus(orderId, travelId)
    ));

    return allReservations;
  } catch (error) {
    console.error('Erro ao buscar reservas por ID de viagem:', error);
    throw error;
  }
};

export const getSortedReservationsByTravelId = async (travelId, sortConfig = { type: 'seatNumber', order: 'asc' }) => {
  try {
    const agencyRef = await getAgencyRef();

    // Buscar todas as reservas da viagem
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    const allReservations = [];

    for (const reservationDoc of reservationsSnapshot.docs) {
      const reservationData = reservationDoc.data();

      // Buscar dados do pedido associado
      const orderRef = doc(agencyRef, 'orders', reservationData.orderId);
      const orderSnap = await getDoc(orderRef);
      const orderData = orderSnap.data();

      // Combinar dados da reserva com dados do pedido
      allReservations.push({
        id: reservationDoc.id,
        ...reservationData,
        orderId: reservationData.orderId,
        detalhesPagamento: orderData?.detalhesPagamento,
        status: reservationData.status || orderData?.status
      });
    }

    // Atualizar status dos pedidos
    const uniqueOrderIds = [...new Set(allReservations.map(r => r.orderId))];
    await Promise.all(uniqueOrderIds.map(orderId =>
      updateOrderAndReservationStatus(orderId, travelId)
    ));

    // Aplicar ordenação baseada na configuração
    allReservations.sort((a, b) => {
      // Sempre manter reservas canceladas por último
      if (a.status === 'Cancelada' && b.status !== 'Cancelada') return 1;
      if (a.status !== 'Cancelada' && b.status === 'Cancelada') return -1;

      // Se ambas estão canceladas ou ambas não estão canceladas, aplicar a ordenação escolhida
      switch (sortConfig.type) {
        case 'createdAt':
          const dateA = new Date(a.metadata?.createdAt || 0);
          const dateB = new Date(b.metadata?.createdAt || 0);
          return sortConfig.order === 'asc'
            ? dateA - dateB
            : dateB - dateA;

        case 'seatNumber':
        default:
          return (a.numeroAssento || 0) - (b.numeroAssento || 0);
      }
    });

    return allReservations;
  } catch (error) {
    console.error('Erro ao buscar reservas por ID de viagem:', error);
    throw error;
  }
};

export const getSortedOrdersByTravelId = async (travelId, sortConfig = { type: 'orderId', order: 'asc' }) => {
  try {
    const agencyRef = await getAgencyRef();

    // Buscar todos os pedidos da viagem
    const ordersRef = collection(agencyRef, 'orders');
    const ordersQuery = query(ordersRef, where('travelId', '==', travelId));
    const ordersSnapshot = await getDocs(ordersQuery);

    const sortedOrders = [];

    for (const orderDoc of ordersSnapshot.docs) {
      const orderData = { id: orderDoc.id, ...orderDoc.data() };

      // Buscar reservas associadas ao pedido
      const reservationsRef = collection(agencyRef, 'reservations');
      const reservationsQuery = query(
        reservationsRef,
        where('orderId', '==', orderDoc.id),
        where('travelId', '==', travelId)
      );
      const reservationsSnapshot = await getDocs(reservationsQuery);

      // Adicionar reservas ao pedido
      orderData.reservations = reservationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Atualizar status do pedido e reservas
      await updateOrderAndReservationStatus(orderDoc.id, travelId);

      sortedOrders.push(orderData);
    }

    // Aplicar ordenação baseada na configuração
    sortedOrders.sort((a, b) => {
      // Sempre manter pedidos cancelados por último
      if (a.status === 'Cancelada' && b.status !== 'Cancelada') return 1;
      if (a.status !== 'Cancelada' && b.status === 'Cancelada') return -1;

      // Se ambos estão cancelados ou ambos não estão cancelados, aplicar a ordenação escolhida
      switch (sortConfig.type) {
        case 'createdAt':
          const dateA = new Date(a.metadata?.createdAt || 0);
          const dateB = new Date(b.metadata?.createdAt || 0);
          return sortConfig.order === 'asc'
            ? dateA - dateB
            : dateB - dateA;

        case 'total':
          const totalA = Number(a.detalhesPagamento?.valorTotal || 0);
          const totalB = Number(b.detalhesPagamento?.valorTotal || 0);
          return sortConfig.order === 'asc'
            ? totalA - totalB
            : totalB - totalA;

        case 'payerName':
          const nameA = (a.detalhesPagamento?.nomePagador || '').toLowerCase();
          const nameB = (b.detalhesPagamento?.nomePagador || '').toLowerCase();
          return sortConfig.order === 'asc'
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);

        case 'orderId':
        default:
          return sortConfig.order === 'asc'
            ? a.id.localeCompare(b.id)
            : b.id.localeCompare(a.id);
      }
    });

    return sortedOrders;
  } catch (error) {
    console.error('Erro ao buscar e ordenar pedidos por ID de viagem:', error);
    throw error;
  }
};

export const calculateTotalsByTravelId = async (travelId) => {
  const agencyRef = await getAgencyRef();

  // Buscar todos os pedidos da viagem
  const ordersRef = collection(agencyRef, 'orders');
  const ordersQuery = query(ordersRef, where('travelId', '==', travelId));
  const ordersSnapshot = await getDocs(ordersQuery);

  let totalReceivable = 0;
  let totalReceived = 0;

  ordersSnapshot.forEach((orderDoc) => {
    const orderData = orderDoc.data();
    if (orderData.status !== 'Cancelada') {
      const valorPago = parseFloat(orderData.detalhesPagamento?.valorPago || 0);
      const valorRestante = parseFloat(orderData.detalhesPagamento?.valorRestante || 0);

      totalReceived += valorPago;
      totalReceivable += valorRestante;
    }
  });

  return { totalReceivable, totalReceived };
};

// Função para obter todos os assentos disponíveis
export const getAvailableSeats = async (travelId, quantidadeAssentos = 40) => {
  const agencyRef = await getAgencyRef();

  // Buscar todas as reservas da viagem
  const reservationsRef = collection(agencyRef, 'reservations');
  const reservationsQuery = query(reservationsRef, where('travelId', '==', travelId));
  const reservationsSnapshot = await getDocs(reservationsQuery);

  // Coletar assentos reservados ativos
  const reservedSeats = reservationsSnapshot.docs
    .map(doc => doc.data())
    .filter(reservation => reservation.status !== 'Cancelada')
    .map(reservation => reservation.numeroAssento);

  // Gerar lista de todos os assentos
  const allSeats = Array.from(
    { length: quantidadeAssentos },
    (_, i) => ({ id: i + 1, number: i + 1 })
  );

  return allSeats;
};

export const getReservedSeats = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();

    if (!travelId) {
      console.error("TravelId inválido:", travelId);
      return [];
    }

    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(reservationsRef, where('travelId', '==', travelId));
    const reservationsSnapshot = await getDocs(reservationsQuery);

    const reservedSeats = [];

    for (const reservationDoc of reservationsSnapshot.docs) {
      try {
        const reservationData = reservationDoc.data();

        if (!reservationData.passengerId) {
          console.warn(`Reserva ${reservationDoc.id} não tem passengerId`);
          continue;
        }

        let passengerInfo = null;
        try {
          const passengerRef = doc(agencyRef, 'passengers', reservationData.passengerId);
          const passengerSnap = await getDoc(passengerRef);
          passengerInfo = passengerSnap.exists()
            ? { id: passengerSnap.id, ...passengerSnap.data() }
            : null;
        } catch (passengerError) {
          console.error('Erro ao buscar passageiro:', passengerError);
        }

        reservedSeats.push({
          id: reservationDoc.id,
          number: parseInt(reservationData.numeroAssento, 10) || null,
          numeroAssento: reservationData.numeroAssento,
          status: reservationData.status || 'Indefinido',
          passenger: passengerInfo,
          orderId: reservationData.orderId || ''
        });
      } catch (reservationError) {
        console.error(`Erro processando reserva ${reservationDoc.id}:`, reservationError);
      }
    }

    return reservedSeats;
  } catch (error) {
    console.error('Erro ao buscar assentos reservados:', error);
    return [];  // Retorna array vazio em caso de erro
  }
};

export const getOrderById = async (travelId, orderId) => {
  if (!orderId) {
    return null;
  }

  if (!travelId) {
    throw new Error('travelId não fornecido');
  }

  try {
    const agencyRef = await getAgencyRef();
    const orderRef = doc(agencyRef, 'orders', orderId);

    const orderSnapshot = await getDoc(orderRef);
    if (!orderSnapshot.exists()) {
      return null;
    }

    const order = { id: orderSnapshot.id, ...orderSnapshot.data() };

    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('orderId', '==', orderId),
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    order.reservations = reservationsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return order;
  } catch (error) {
    console.error('Erro ao buscar pedido:', error);
    throw error;
  }
};

export const getOrdersByTravelId = async (travelId) => {
  const agencyRef = await getAgencyRef();

  // Buscar todos os pedidos da viagem
  const ordersRef = collection(agencyRef, 'orders');
  const ordersQuery = query(ordersRef, where('travelId', '==', travelId));
  const ordersSnapshot = await getDocs(ordersQuery);

  const orders = [];

  for (const orderDoc of ordersSnapshot.docs) {
    const orderData = { id: orderDoc.id, ...orderDoc.data() };

    // Buscar reservas associadas ao pedido
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('orderId', '==', orderDoc.id),
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    orderData.reservations = reservationsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    await updateOrderAndReservationStatus(orderDoc.id, travelId);

    orders.push(orderData);
  }

  return orders;
};

export const getReservationById = async (travelId, orderId, reservationId) => {
  if (!travelId || !orderId || !reservationId) {
    throw new Error('travelId, orderId, ou reservationId não fornecido');
  }

  const agencyRef = await getAgencyRef();
  const reservationRef = doc(agencyRef, 'reservations', reservationId);

  const reservationSnapshot = await getDoc(reservationRef);
  if (!reservationSnapshot.exists()) {
    throw new Error('Reserva não encontrada');
  }

  const reservation = { id: reservationSnapshot.id, ...reservationSnapshot.data() };

  // Buscar dados do pedido
  const orderRef = doc(agencyRef, 'orders', orderId);
  const orderSnapshot = await getDoc(orderRef);

  if (orderSnapshot.exists()) {
    const orderData = orderSnapshot.data();
    reservation.detalhesPagamento = orderData.detalhesPagamento;
    reservation.orderStatus = orderData.status;
  }

  // Buscar dados do passageiro
  const passengerRef = doc(agencyRef, 'passengers', reservation.passengerId);
  const passengerSnapshot = await getDoc(passengerRef);

  if (passengerSnapshot.exists()) {
    reservation.passengerInfo = {
      id: passengerSnapshot.id,
      ...passengerSnapshot.data()
    };
  }

  return reservation;
};

export const updateReservationCountForTravel = async (travelId) => {
  try {
    const agencyRef = await getAgencyRef();
    const travelRef = doc(agencyRef, 'travels', travelId);

    // Verificar se a viagem existe
    const travelDoc = await getDoc(travelRef);
    if (!travelDoc.exists()) {
      console.warn(`Travel with ID ${travelId} not found`);
      return;
    }

    // Buscar todas as reservas da viagem
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('travelId', '==', travelId)
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    const totalReservations = reservationsSnapshot.docs.filter(doc => {
      const data = doc.data();
      return data.status !== 'Cancelada' && !data.lapPassenger;
    }).length;

    // Atualizar contagem na viagem
    await updateDoc(travelRef, {
      numReservas: totalReservations,
    });
  } catch (error) {
    console.error('Error updating reservation count:', error);
  }
};