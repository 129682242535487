import { db } from '../../../firebaseConfig';
import { collection, doc, addDoc, getDocs, updateDoc, query, orderBy, where, writeBatch, getDoc, limit, startAfter } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { cancelReservation } from '../../Allocation/services/OrderService';

const auth = getAuth();

const getAgencyRef = async () => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  return doc(db, 'agencies', userDoc.data().agencyId);
};

// Adiciona um novo passageiro à coleção de passageiros do usuário
export const addPassenger = async (passenger) => {
  try {
    const agencyRef = await getAgencyRef();
    const passengersRef = collection(agencyRef, 'passengers');

    // Valida a duplicidade dos documentos
    await validateDocumentDuplication(passenger);

    passenger = await checkAndUpdateAdultStatus(passenger);

    const timestamp = new Date().toISOString();
    const passengerWithMetadata = {
      ...passenger,
      estaAtivo: true,
      metadata: {
        createdAt: timestamp,
        updatedAt: timestamp,
        deletedAt: null
      }
    };

    return await addDoc(passengersRef, passengerWithMetadata);
  } catch (error) {
    console.error('Erro ao adicionar passageiro:', error);
    throw error;
  }
};

export const updatePassenger = async (passengerId, passengerData) => {
  try {
    const agencyRef = await getAgencyRef();
    const passengerRef = doc(agencyRef, 'passengers', passengerId);

    // Valida a duplicidade dos documentos
    await validateDocumentDuplication(passengerData, passengerId);

    passengerData = await checkAndUpdateAdultStatus(passengerData);

    const timestamp = new Date().toISOString();
    const passengerWithMetadata = {
      ...passengerData,
      metadata: {
        ...passengerData.metadata,
        updatedAt: timestamp
      }
    };

    await updateDoc(passengerRef, passengerWithMetadata);
  } catch (error) {
    console.error('Erro ao atualizar passageiro:', error);
    throw error;
  }
};

// Exclui um ou mais passageiros, marcando-os como inativos e cancelando suas reservas
export const deletePassengers = async (passengerIds) => {
  try {
    const agencyRef = await getAgencyRef();
    const batch = writeBatch(db);
    const timestamp = new Date().toISOString();

    for (const id of passengerIds) {
      const passengerRef = doc(agencyRef, 'passengers', id);

      // Buscar reservas do passageiro
      const reservationsResponse = await getPassengerReservations(id);
      const reservations = reservationsResponse.reservations;

      // Cancelar cada reserva
      for (const reservation of reservations) {
        await cancelReservation(
          reservation.travelId,
          reservation.orderId,
          reservation.id
        );
      }

      // Marcar passageiro como inativo e atualizar metadata
      batch.update(passengerRef, {
        estaAtivo: false,
        metadata: {
          updatedAt: timestamp,
          deletedAt: timestamp
        }
      });
    }

    await batch.commit();
  } catch (error) {
    console.error("Erro ao excluir passageiros:", error);
    throw error;
  }
};

export const validateDocumentDuplication = async (passenger, excludeId = null, entity = 'passageiro') => {
  try {
    const agencyRef = await getAgencyRef();
    const passengersRef = collection(agencyRef, 'passengers');

    const queries = [];

    if (passenger.cpf) {
      queries.push({
        field: 'cpf',
        query: query(
          passengersRef,
          where('cpf', '==', passenger.cpf),
          where('estaAtivo', '==', true),
        )
      });
    }

    if (passenger.rg) {
      queries.push({
        field: 'rg',
        query: query(
          passengersRef,
          where('rg', '==', passenger.rg),
          where('estaAtivo', '==', true),
        )
      });
    }

    if (passenger.passaporte) {
      queries.push({
        field: 'passaporte',
        query: query(
          passengersRef,
          where('passaporte', '==', passenger.passaporte),
          where('estaAtivo', '==', true),
        )
      });
    }

    for (const { field, query: documentQuery } of queries) {
      const snapshot = await getDocs(documentQuery);
      const existingDoc = snapshot.docs.find(doc => doc.id !== excludeId);

      if (existingDoc) {
        throw new Error(`O ${field.toUpperCase()} já está cadastrado para o ${entity} nesta agência.`);
      }
    }
  } catch (error) {
    console.error('Erro ao validar documentos:', error);
    throw error;
  }
};

export const getPassengerById = async (passengerId) => {
  try {
    const agencyRef = await getAgencyRef();
    const passengerRef = doc(agencyRef, 'passengers', passengerId);
    const passengerDoc = await getDoc(passengerRef);

    if (passengerDoc.exists()) {
      return {
        id: passengerDoc.id,
        ...passengerDoc.data()
      };
    } else {
      throw new Error('Passageiro não encontrado');
    }
  } catch (error) {
    console.error('Erro ao buscar dados do passageiro:', error);
    throw error;
  }
};

export const getAllPassengers = async (sortOrder = 'desc') => {
  try {
    const agencyRef = await getAgencyRef();
    const passengersRef = collection(agencyRef, 'passengers');

    const q = query(
      passengersRef,
      where("estaAtivo", "==", true),
      orderBy("dataAdicionado", sortOrder)
    );

    const snapshot = await getDocs(q);
    const passengers = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Verificar e atualizar o status de menor de idade
    const updatedPassengers = await Promise.all(
      passengers.map(checkAndUpdateAdultStatus)
    );

    return updatedPassengers;
  } catch (error) {
    console.error("Erro ao buscar passageiros:", error);
    throw error;
  }
};

export const getPaginatedPassengers = async (pageSize = 12, lastDoc = null, filters = {}) => {
  try {
    const agencyRef = await getAgencyRef();
    const passengersRef = collection(agencyRef, 'passengers');

    // Base query constraints
    let baseConstraints = [
      where("estaAtivo", "==", true)
    ];

    // Adicionar ordenação
    if (filters.order === 'alphabetical') {
      baseConstraints.push(orderBy("nome"));
    } else {
      baseConstraints.push(orderBy("dataAdicionado", "desc"));
    }

    // Se houver termo de busca
    if (filters.searchTerm?.trim()) {
      const searchTerm = filters.searchTerm.trim().toLowerCase();

      // Buscar todos os passageiros ativos da agência
      const q = query(
        passengersRef,
        where("estaAtivo", "==", true),
      );
      const snapshot = await getDocs(q);

      // Filtrar os resultados
      let filteredPassengers = snapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(passenger => {
          const searchFields = [
            passenger.nome,
            passenger.cpf,
            passenger.rg,
            passenger.passaporte,
            passenger.telefone
          ].filter(Boolean);

          return searchFields.some(field =>
            String(field).toLowerCase().includes(searchTerm)
          );
        });

      // Ordenar resultados
      if (filters.order === 'alphabetical') {
        filteredPassengers.sort((a, b) => a.nome.localeCompare(b.nome));
      } else {
        filteredPassengers.sort((a, b) =>
          new Date(b.dataAdicionado) - new Date(a.dataAdicionado)
        );
      }

      // Aplicar paginação
      const startIndex = lastDoc ?
        filteredPassengers.findIndex(p => p.id === lastDoc.id) + 1 : 0;
      const paginatedPassengers = filteredPassengers.slice(
        startIndex,
        startIndex + pageSize
      );

      return {
        passengers: paginatedPassengers,
        lastDoc: paginatedPassengers[paginatedPassengers.length - 1],
        hasMore: startIndex + pageSize < filteredPassengers.length,
        totalCount: filteredPassengers.length
      };
    }

    // Se não houver termo de busca
    else {
      baseConstraints.push(limit(pageSize));
      if (lastDoc) {
        baseConstraints.push(startAfter(lastDoc));
      }

      const q = query(passengersRef, ...baseConstraints);
      const snapshot = await getDocs(q);

      const passengers = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Buscar contagem total
      const totalQuery = query(
        passengersRef,
        where("estaAtivo", "==", true),
      );
      const totalSnapshot = await getDocs(totalQuery);

      return {
        passengers,
        lastDoc: snapshot.docs[snapshot.docs.length - 1],
        hasMore: passengers.length === pageSize,
        totalCount: totalSnapshot.size
      };
    }
  } catch (error) {
    console.error("Erro ao buscar passageiros paginados:", error);
    throw error;
  }
};

export const getPassengersCount = async (filters = {}) => {
  try {
    const agencyRef = await getAgencyRef();
    const passengersRef = collection(agencyRef, 'passengers');

    // Query base com filtros de ativo e agencyId
    const q = query(
      passengersRef,
      where("estaAtivo", "==", true),
    );

    const snapshot = await getDocs(q);

    if (filters.searchTerm?.trim()) {
      const searchTerm = filters.searchTerm.trim().toLowerCase();

      // Filtrar resultados
      const filteredDocs = snapshot.docs.filter(doc => {
        const data = doc.data();
        const searchFields = [
          data.nome,
          data.cpf,
          data.rg,
          data.passaporte,
          data.telefone
        ].filter(Boolean);

        return searchFields.some(field =>
          String(field).toLowerCase().includes(searchTerm)
        );
      });

      return filteredDocs.length;
    }

    return snapshot.size;
  } catch (error) {
    console.error("Erro ao obter contagem de passageiros:", error);
    throw error;
  }
};

// Função para obter todos os passageiros do usuário, sem considerar o status "estaAtivo"
export const getAllPassengersWithoutStatusFilter = async (sortOrder = 'desc') => {
  try {
    const agencyRef = await getAgencyRef();
    const passengersRef = collection(agencyRef, 'passengers');

    // Query ordenada por data de adição, incluindo todos os passageiros da agência
    const q = query(
      passengersRef,
      orderBy('dataAdicionado', sortOrder)
    );

    const snapshot = await getDocs(q);

    const passengers = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Verificar e atualizar o status de menor de idade
    const updatedPassengers = await Promise.all(
      passengers.map(checkAndUpdateAdultStatus)
    );

    return updatedPassengers;
  } catch (error) {
    console.error('Erro ao buscar todos os passageiros:', error);
    throw error;
  }
};

// Update the searchResponsiblePassengers function in PassengerService.js
export const searchResponsiblePassengers = async (searchTerm) => {
  try {
    // Limpa o termo de busca
    const cleanSearchTerm = searchTerm.trim().toLowerCase();
    const numericSearchTerm = searchTerm.replace(/\D/g, ''); // Remove não-dígitos
    const isSearchingCPF = /^[\d..-]*$/.test(searchTerm);

    // Obter referência da agência e coleção de passageiros
    const agencyRef = await getAgencyRef();
    const passengersRef = collection(agencyRef, 'passengers');

    // Query para passageiros ativos que não são menores de idade
    const q = query(
      passengersRef,
      where('estaAtivo', '==', true),
      where('menorDeIdade', '==', false)
    );

    const snapshot = await getDocs(q);

    // Filtrar passageiros com base no termo de busca
    return snapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(passenger => {
        // Limpar dados do passageiro para comparação
        const cleanName = (passenger.nome || '').toLowerCase();
        const cleanPassport = (passenger.passaporte || '').toLowerCase();
        const cleanRG = (passenger.rg || '').toLowerCase();

        // Tratamento especial para CPF
        if (passenger.cpf) {
          const cpfNumerico = passenger.cpf.replace(/\D/g, '');
          const searchTermNumerico = searchTerm.replace(/\D/g, '');

          if (isSearchingCPF && searchTermNumerico && cpfNumerico.includes(searchTermNumerico)) {
            return true;
          }
        }

        // Verificar correspondência em qualquer campo
        return cleanName.includes(cleanSearchTerm) ||
          cleanRG.includes(cleanSearchTerm) ||
          cleanPassport.includes(cleanSearchTerm);
      });
  } catch (error) {
    console.error('Erro ao buscar passageiros responsáveis:', error);
    throw error;
  }
};

// Função para verificar se o passageiro atingiu 18 anos ou mais
const checkAndUpdateAdultStatus = async (passenger) => {
  try {
    const today = new Date();
    const birthDate = new Date(passenger.dataNascimento);

    // Cálculo da idade
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Ajuste da idade se o mês e o dia ainda não tiverem ocorrido este ano
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    // Verificação se o passageiro é menor de idade
    const menorDeIdade = age < 18;

    // Atualizar no banco se houver mudança no status
    if (passenger.menorDeIdade !== menorDeIdade) {
      const agencyRef = await getAgencyRef();
      const passengerRef = doc(agencyRef, 'passengers', passenger.id);

      await updateDoc(passengerRef, {
        menorDeIdade,
        updatedAt: new Date().toISOString()
      });
    }

    // Retornar passageiro com status atualizado
    return {
      ...passenger,
      menorDeIdade
    };
  } catch (error) {
    console.error('Erro ao atualizar status de menor de idade:', error);
    // Retorna o passageiro sem alteração em caso de erro
    return passenger;
  }
};

export default checkAndUpdateAdultStatus;

export const getPassengerReservations = async (passengerId, page = 1, pageSize = 5) => {
  try {
    const agencyRef = await getAgencyRef();

    // Buscar passageiro
    const passengerRef = doc(agencyRef, 'passengers', passengerId);
    const passengerDoc = await getDoc(passengerRef);

    if (!passengerDoc.exists()) {
      throw new Error('Passageiro não encontrado');
    }

    // Buscar todas as reservas do passageiro
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('passengerId', '==', passengerId),
    );
    const reservationsSnapshot = await getDocs(reservationsQuery);

    // Ordenar e paginar reservas
    const allReservations = reservationsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedReservations = allReservations.slice(startIndex, endIndex);

    // Buscar detalhes adicionais para cada reserva
    const reservationsWithDetails = await Promise.all(
      paginatedReservations.map(async reservation => {
        try {
          // Buscar viagem
          const travelRef = doc(agencyRef, 'travels', reservation.travelId);
          const travelDoc = await getDoc(travelRef);

          if (!travelDoc.exists()) {
            console.warn(`Viagem ${reservation.travelId} não encontrada para reserva ${reservation.id}`);
            return null;
          }

          const travel = {
            id: travelDoc.id,
            ...travelDoc.data()
          };

          // Buscar pedido
          const orderRef = doc(agencyRef, 'orders', reservation.orderId);
          const orderDoc = await getDoc(orderRef);

          if (!orderDoc.exists()) {
            console.warn(`Pedido ${reservation.orderId} não encontrado para reserva ${reservation.id}`);
            return null;
          }

          const order = orderDoc.data();

          return {
            ...reservation,
            travel,
            detalhesPagamento: order.detalhesPagamento,
            status: reservation.status || order.status
          };
        } catch (error) {
          console.error(`Erro ao buscar detalhes da reserva ${reservation.id}:`, error);
          return null;
        }
      })
    );

    const validReservations = reservationsWithDetails.filter(Boolean);

    return {
      reservations: validReservations,
      pagination: {
        currentPage: page,
        totalPages: Math.ceil(allReservations.length / pageSize),
        totalItems: allReservations.length,
        pageSize
      }
    };
  } catch (error) {
    console.error('Erro ao buscar reservas do passageiro:', error);
    throw error;
  }
};

export const getPassengerReservationIds = async (passengerId) => {
  try {
    const agencyRef = await getAgencyRef();
    const reservationsRef = collection(agencyRef, 'reservations');
    const reservationsQuery = query(
      reservationsRef,
      where('passengerId', '==', passengerId)
    );

    const snapshot = await getDocs(reservationsQuery);
    return snapshot.docs.map(doc => doc.id);
  } catch (error) {
    console.error('Erro ao buscar IDs das reservas:', error);
    return [];
  }
};

export const getInactivePassengers = async (inactiveDays = 90) => {
  try {
    const agencyRef = await getAgencyRef();
    const passengersRef = collection(agencyRef, 'passengers');
    const reservationsRef = collection(agencyRef, 'reservations');

    // Buscar todos os passageiros
    const passengersSnapshot = await getDocs(
      query(passengersRef, where('estaAtivo', '==', true))
    );

    const inactivePassengers = [];

    // Processar cada passageiro
    for (const passengerDoc of passengersSnapshot.docs) {
      const passenger = {
        id: passengerDoc.id,
        ...passengerDoc.data()
      };

      // Buscar reservas deste passageiro
      const reservationsQuery = query(
        reservationsRef,
        where('passengerId', '==', passenger.id),
        where('status', '!=', 'Cancelada'),
        orderBy('status', 'desc'),
        orderBy('metadata.createdAt', 'desc'),
        limit(1)
      );

      const reservationsSnapshot = await getDocs(reservationsQuery);

      if (reservationsSnapshot.empty) {
        // Passageiro nunca viajou
        passenger.lastTravelDays = null;
        passenger.status = 'Nunca viajou';
        inactivePassengers.push(passenger);
      } else {
        const lastReservation = {
          id: reservationsSnapshot.docs[0].id,
          ...reservationsSnapshot.docs[0].data()
        };

        const lastTravelDate = new Date(lastReservation.metadata.createdAt);
        const today = new Date();
        const daysSinceLastTravel = Math.floor(
          (today.getTime() - lastTravelDate.getTime()) / (1000 * 3600 * 24)
        );

        if (daysSinceLastTravel > inactiveDays) {
          passenger.lastTravelDays = daysSinceLastTravel;
          passenger.lastTravelDate = lastTravelDate.toISOString().split('T')[0];
          inactivePassengers.push(passenger);
        }
      }
    }

    // Ordenar por mais tempo sem viajar
    return inactivePassengers.sort((a, b) =>
      (b.lastTravelDays || 0) - (a.lastTravelDays || 0)
    );
  } catch (error) {
    console.error('Erro ao buscar passageiros inativos:', error);
    throw error;
  }
};
