import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField, Button, Box, Typography, Grid, Snackbar, Alert, Dialog, DialogContent, IconButton, InputAdornment, Tooltip, CircularProgress, Checkbox, FormControlLabel, Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import debounce from 'lodash.debounce';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';

import LocationSelector from '../../components/Travel/LocationSelector';
import VehicleForm from '../Vehicle/VehicleForm';
import TravelFormHelp from './helps/TravelFormHelp';

import { checkTravelIdentifierUnique, getMaxTravelIdentifier, TRAVEL_TYPES } from '../Travel/services/TravelService';
import { getReservationsByTravelId } from '../Allocation/services/OrderService';
import { getAllVehicles, checkVehicleTravelConflict, addVehicle } from '../Vehicle/services/VehicleService';
import { getAllLayouts } from '../LayoutPage/services/LayoutService';
import { getUsersForSelection } from '../UserManagement/services/UserManagementService';

const debounceCheckIdentifier = debounce(async (identifier, setErrors) => {
  const isAvailable = await checkTravelIdentifierUnique(identifier);
  setErrors((currentErrors) => ({
    ...currentErrors,
    identificador: isAvailable ? '' : 'Identificador já em uso.',
  }));
}, 500);

function TravelForm({ travel: initialTravel, saveTravel, cancelForm }) {
  const [travel, setTravel] = useState({
    identificador: '',
    descricao: '',
    origem: '',
    destino: '',
    dataIda: '',
    horarioIda: '',
    dataRetorno: '',
    horarioRetorno: '',
    informacoesAdicionais: '',
    veiculoId: '',
    somenteIda: false,
    allowedUsers: [],
    type: TRAVEL_TYPES.REGULAR,
  });
  const theme = useTheme();
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [vehicleModalOpen, setVehicleModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [identifierLoading, setIdentifierLoading] = useState(false);
  const [seatValidationLoading, setSeatValidationLoading] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const generateNextIdentifier = async () => {
    setIdentifierLoading(true);
    try {
      const maxIdentifier = await getMaxTravelIdentifier();
      let nextIdentifier = (maxIdentifier + 1).toString();

      while (!(await checkTravelIdentifierUnique(nextIdentifier))) {
        nextIdentifier = (parseInt(nextIdentifier) + 1).toString();
      }

      return nextIdentifier;
    } finally {
      setIdentifierLoading(false);
    }
  };

  useEffect(() => {
    const initializeForm = async () => {
      if (!initialTravel) {
        const newIdentifier = await generateNextIdentifier();
        setTravel((prevTravel) => ({
          ...prevTravel,
          identificador: newIdentifier,
        }));
      } else {
        setTravel({
          identificador: initialTravel.identificador || '',
          descricao: initialTravel.descricao || '',
          origem: initialTravel.origem || '',
          destino: initialTravel.destino || '',
          dataIda: initialTravel.dataIda || '',
          horarioIda: initialTravel.horarioIda || '',
          dataRetorno: initialTravel.dataRetorno || '',
          horarioRetorno: initialTravel.horarioRetorno || '',
          informacoesAdicionais: initialTravel.informacoesAdicionais || '',
          veiculoId: initialTravel.veiculoId || '',
          somenteIda: initialTravel.somenteIda || false,
        });
        setErrors(validateDatesAndTimes(initialTravel));
      }

      const fetchVehiclesAndLayouts = async () => {
        try {
          const fetchedVehicles = await getAllVehicles();
          fetchedVehicles.sort(
            (a, b) => new Date(b.dataAdicionado) - new Date(a.dataAdicionado)
          );
          setVehicles(fetchedVehicles);
          setFilteredVehicles(fetchedVehicles);

          const fetchedLayouts = await getAllLayouts();
          setLayouts(fetchedLayouts);
        } catch (err) {
          console.error('Erro ao buscar veículos e layouts:', err);
        }
      };

      fetchVehiclesAndLayouts();
    };

    initializeForm();
  }, [initialTravel]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const users = await getUsersForSelection();
        setAvailableUsers(users);

        if (initialTravel?.allowedUsers) {
          setSelectedUsers(initialTravel.allowedUsers);
        }
      } catch (error) {
        console.error('Erro ao carregar usuários:', error);
      }
    };

    loadUsers();
  }, [initialTravel]);

  const handleInputChange = useCallback(
    async (event) => {
      const { name, value, type, checked } = event.target;
      setTravel((prevTravel) => {
        const updatedTravel = {
          ...prevTravel,
          [name]: type === 'checkbox' ? checked : value,
        };

        if (name === 'veiculoId') {
          const selectedVehicle = vehicles.find(
            (vehicle) => vehicle.id === value
          );

          validateVehicleAvailability(selectedVehicle?.id, updatedTravel);
        }

        if (name === 'somenteIda' && checked) {
          updatedTravel.dataRetorno = '';
          updatedTravel.horarioRetorno = '';
          setErrors((prevErrors) => ({
            ...prevErrors,
            dataRetorno: '',
            horarioRetorno: '',
          }));
        }

        const newErrors = validateField(name, value);
        const dateAndTimeErrors = validateDatesAndTimes(updatedTravel);

        setErrors((prevErrors) => ({
          ...prevErrors,
          ...newErrors,
          ...dateAndTimeErrors,
        }));

        if (
          ['dataIda', 'horarioIda', 'dataRetorno', 'horarioRetorno'].includes(
            name
          )
        ) {
          validateVehicleAvailability(updatedTravel.veiculoId, updatedTravel);
        }

        if (name === 'identificador') {
          debounceCheckIdentifier(value, setErrors);
        }
        return updatedTravel;
      });
    },
    [debounceCheckIdentifier, vehicles]
  );

  const handleVehicleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    const filtered = vehicles.filter(
      (vehicle) =>
        vehicle.identificadorVeiculo.toLowerCase().includes(value) ||
        vehicle.placa.toLowerCase().includes(value) ||
        vehicle.empresa.toLowerCase().includes(value)
    );
    setFilteredVehicles(filtered);
  };

  const handleVehicleChange = async (event, value) => {
    if (!value) {
      setTravel(prevTravel => ({
        ...prevTravel,
        veiculoId: '',
        assentosAndar1: '',
        assentosAndar2: '',
      }));

      setErrors(prevErrors => ({
        ...prevErrors,
        veiculoId: ''
      }));
      return;
    }

    const selectedVehicle = vehicles.find((vehicle) => vehicle.id === value?.id);
    let layout = null;

    if (selectedVehicle?.layoutId) {
      layout = layouts.find((layout) => layout.id === selectedVehicle.layoutId);
    }

    setTravel((prevTravel) => ({
      ...prevTravel,
      veiculoId: value?.id || '',
      assentosAndar1: layout ? layout.assentosAndar1.toString() : selectedVehicle?.assentosAndar1.toString(),
      assentosAndar2: layout ? layout.assentosAndar2.toString() : selectedVehicle?.assentosAndar2.toString(),
    }));

    validateVehicleAvailability(value?.id, {
      ...travel,
      veiculoId: value?.id || '',
    });

    if (initialTravel) {
      validateSeatChange(selectedVehicle, initialTravel.id);
    }
  };

  const handleLocationChange = (name, value) => {
    setTravel((prevTravel) => {
      const updatedTravel = { ...prevTravel, [name]: value || '' };
      const newErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
      return updatedTravel;
    });
  };

  const handleUserSelectionChange = (event, newValue) => {
    setSelectedUsers(newValue.map(user => user.id));
    setTravel(prev => ({
      ...prev,
      allowedUsers: newValue.map(user => user.id)
    }));
  };

  const validateVehicleAvailability = async (vehicleId, travel) => {
    if (vehicleId) {
      const isConflict = await checkVehicleTravelConflict(
        vehicleId,
        travel.dataIda,
        travel.horarioIda,
        travel.dataRetorno,
        travel.horarioRetorno,
        travel.somenteIda,
        initialTravel?.id
      );
      setErrors((prevErrors) => ({
        ...prevErrors,
        veiculoId: isConflict
          ? 'Veículo já alocado para outra viagem neste período.'
          : '',
      }));
    }
  };

  const validateSeatChange = async (newVehicle, travelId) => {
    if (!newVehicle || !travelId) return;
    setSeatValidationLoading(true);
    try {
      const reservations = await getReservationsByTravelId(travelId);

      const totalSeats =
        parseInt(newVehicle.assentosAndar1, 10) +
        parseInt(newVehicle.assentosAndar2, 10);

      const invalidSeats = reservations.filter(
        (reservation) => reservation.numeroAssento > totalSeats
      );

      if (invalidSeats.length > 0) {
        const invalidSeatNumbers = invalidSeats.map(
          (res) => res.numeroAssento
        ).join(', ');

        setErrors((prevErrors) => ({
          ...prevErrors,
          veiculoId: `Há na viagem, reservas em assentos não disponíveis neste ônibus. Assentos: ${invalidSeatNumbers}`,
        }));

      } else {
        setErrors((prevErrors) => ({ ...prevErrors, veiculoId: '' }));
      }
    } catch (error) {
      console.error('Erro ao validar alteração de veículo:', error);
    } finally {
      setSeatValidationLoading(false);
    }
  };

  const validateDatesAndTimes = (travel) => {
    let errors = {};
    if (
      travel.dataRetorno &&
      new Date(travel.dataIda) > new Date(travel.dataRetorno)
    ) {
      errors.dataRetorno = 'Data de retorno não pode ser antes da data de ida.';
    } else {
      errors.dataRetorno = '';
    }

    if (
      travel.dataIda === travel.dataRetorno &&
      travel.horarioIda &&
      travel.horarioRetorno &&
      travel.horarioRetorno <= travel.horarioIda
    ) {
      errors.horarioRetorno =
        'Horário de retorno deve ser após o horário de ida no mesmo dia.';
    } else {
      errors.horarioRetorno = '';
    }
    return errors;
  };

  const validateField = (name, value) => {
    let errors = {};
    switch (name) {
      case 'identificador':
        errors.identificador = !/^\d*$/.test(value)
          ? 'Apenas números são permitidos.'
          : '';
        errors.identificador =
          value.length > 10 ? 'Máximo de 10 dígitos.' : errors.identificador;
        break;
      case 'descricao':
        errors.descricao = value.length > 400 ? 'Máximo de 400 caracteres.' : '';
        break;
      case 'origem':
      case 'destino':
        errors[name] = value.length > 55 ? 'Máximo de 55 caracteres.' : '';
        break;
      case 'informacoesAdicionais':
        errors.informacoesAdicionais =
          value.length > 255 ? 'Máximo de 255 caracteres.' : '';
        break;
      default:
        break;
    }
    return errors;
  };

  const isFormValid = () => {
    const requiredFields = [
      'identificador',
      'dataIda',
      'horarioIda',
      'origem',
      'destino',
    ];

    if (!travel.somenteIda) {
      requiredFields.push('dataRetorno', 'horarioRetorno');
    }

    const requiredFieldsFilled = requiredFields.every((field) => !!travel[field]);
    const noErrors = Object.values(errors).every((error) => !error);

    return requiredFieldsFilled && noErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isFormValid()) {
      setLoading(true);
      await saveTravel(travel);
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleAddVehicle = async (newVehicle) => {
    try {
      await addVehicle(newVehicle);
      const updatedVehicles = await getAllVehicles();
      updatedVehicles.sort(
        (a, b) => new Date(b.dataAdicionado) - new Date(a.dataAdicionado)
      );
      const mostRecentVehicle = updatedVehicles[0];
      setVehicles(updatedVehicles);
      setFilteredVehicles(updatedVehicles);
      setVehicleModalOpen(false);
      setTravel((prevTravel) => ({
        ...prevTravel,
        veiculoId: mostRecentVehicle.id,
      }));
      validateVehicleAvailability(mostRecentVehicle.id, {
        ...travel,
        veiculoId: mostRecentVehicle.id,
      });
    } catch (error) {
      console.error('Erro ao adicionar veículo:', error);
    }
  };

  return (
    <Dialog open onClose={cancelForm} fullWidth maxWidth="md">
      <DialogContent sx={{ padding: 0, backgroundColor: theme.palette.background.default }}>
        <Box sx={{ padding: theme.spacing(2), overflowY: 'auto', maxHeight: '80vh' }}>
          <form onSubmit={handleSubmit} noValidate>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: theme.spacing(2),
                flexDirection: { xs: 'column', md: 'row' },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: { xs: theme.spacing(1), md: 0 }, color: theme.palette.text.primary }}
              >
                {initialTravel ? 'Editar Viagem' : 'Nova Viagem'}
                <Box component="span" sx={{ ml: 1 }}>
                  <TravelFormHelp />
                </Box>
              </Typography>
              <Typography variant="caption" display="block" gutterBottom sx={{ color: theme.palette.text.secondary }}>
                * Campos Obrigatórios
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled
                  error={!!errors.identificador}
                  helperText={errors.identificador}
                  label="Identificador da Viagem"
                  name="identificador"
                  value={travel.identificador}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (isNaN(Number(event.key)) || event.target.value.length >= 10) {
                      event.preventDefault();
                    }
                  }}
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: identifierLoading ? (
                      <InputAdornment position="start">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!errors.descricao}
                  helperText={errors.descricao}
                  label="Descrição da Viagem"
                  name="descricao"
                  value={travel.descricao}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="somenteIda"
                      checked={travel.somenteIda}
                      onChange={handleInputChange}
                      color="primary"
                    />
                  }
                  label="Viagem somente de ida"
                  sx={{ margin: 0 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocationSelector
                  label="Local de Origem"
                  value={travel.origem}
                  onChange={(value) => handleLocationChange('origem', value)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocationSelector
                  label="Local de Destino"
                  value={travel.destino}
                  onChange={(value) => handleLocationChange('destino', value)}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={!!errors.dataIda}
                  helperText={errors.dataIda}
                  label="Data de Ida da Viagem"
                  name="dataIda"
                  type="date"
                  value={travel.dataIda}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={!!errors.horarioIda}
                  helperText={errors.horarioIda}
                  label="Horário de Ida da Viagem"
                  name="horarioIda"
                  type="time"
                  value={travel.horarioIda}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={!!errors.dataRetorno}
                  helperText={errors.dataRetorno}
                  label="Data de Retorno da Viagem"
                  name="dataRetorno"
                  type="date"
                  value={travel.dataRetorno}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required={!travel.somenteIda}
                  disabled={travel.somenteIda}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={!!errors.horarioRetorno}
                  helperText={errors.horarioRetorno}
                  label="Horário de Retorno da Viagem"
                  name="horarioRetorno"
                  type="time"
                  value={travel.horarioRetorno}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required={!travel.somenteIda}
                  disabled={travel.somenteIda}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="vehicle-select"
                  options={filteredVehicles}
                  getOptionLabel={(option) =>
                    `${option.identificadorVeiculo} - Placa: ${option.placa} - Empresa: ${option.empresa}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Veículo da Viagem (Opcional)"
                      placeholder="Busque por Identificador, Placa ou Empresa"
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.veiculoId}
                      helperText={errors.veiculoId}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Tooltip title="Adicionar Veículo">
                              <IconButton
                                onClick={() => setVehicleModalOpen(true)}
                                edge="start"
                                sx={{ color: theme.palette.primary.main, marginRight: theme.spacing(1) }}
                              >
                                <AddIcon
                                  sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: '50%',
                                    color: theme.palette.common.white,
                                    padding: 0.5,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            {params.InputProps.startAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  noOptionsText="Nenhuma opção encontrada."
                  value={vehicles.find((vehicle) => vehicle.id === travel.veiculoId) || null}
                  onChange={handleVehicleChange}
                  onInputChange={(event, newInputValue) => handleVehicleSearch({ target: { value: newInputValue } })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!errors.informacoesAdicionais}
                  helperText={errors.informacoesAdicionais}
                  label="Informações Adicionais da Viagem"
                  name="informacoesAdicionais"
                  value={travel.informacoesAdicionais}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  maxRows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="user-select"
                  options={availableUsers}
                  value={availableUsers.filter(user => selectedUsers.includes(user.id))}
                  onChange={handleUserSelectionChange}
                  getOptionLabel={(option) => `${option.name} (${option.email})`}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={`${option.name} (${option.email})`}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecionar Guias"
                      placeholder="Selecione os guias que poderão visualizar esta viagem"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', md: 'row' },
                  }}
                >
                  <Button
                    onClick={cancelForm}
                    variant="outlined"
                    sx={{
                      borderRadius: theme.shape.borderRadius,
                      marginBottom: { xs: theme.spacing(2), md: 0 },
                    }}
                  >
                    {initialTravel ? 'Descartar alterações' : 'Voltar'}
                  </Button>
                  <Tooltip title={!isFormValid() ? "Preencha todos os campos obrigatórios para prosseguir" : ""} disableHoverListener={isFormValid() || loading || seatValidationLoading}>
                    <span>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!isFormValid() || loading || seatValidationLoading}
                        sx={{
                          marginBottom: { xs: theme.spacing(2), md: 0 },
                          marginRight: { md: theme.spacing(1) },
                          borderRadius: theme.shape.borderRadius,
                          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
                          color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.primary.contrastText,
                          '&:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
                          },
                          '&:disabled': {
                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.text.secondary,
                          },
                        }}
                      >
                        {loading || seatValidationLoading ? (
                          <CircularProgress size={24} />
                        ) : initialTravel ? (
                          'Salvar alterações'
                        ) : (
                          'Adicionar viagem'
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
              <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                Viagem salva com sucesso!
              </Alert>
            </Snackbar>
          </form>
        </Box>
      </DialogContent>
      <Dialog open={vehicleModalOpen} onClose={() => setVehicleModalOpen(false)} fullWidth maxWidth="sm">
        <DialogContent>
          <VehicleForm onSave={handleAddVehicle} onCancel={() => setVehicleModalOpen(false)} />
        </DialogContent>
      </Dialog>
    </Dialog>
  );
}

export default React.memo(TravelForm);