import { db, auth } from '../../../firebaseConfig';
import { doc, getDoc, setDoc, collection, updateDoc } from 'firebase/firestore';

// Default settings for passenger fields
const DEFAULT_PASSENGER_FIELD_SETTINGS = {
    requireCPF: true,
    requireRG: true,
    requirePassport: true,
    updatedAt: new Date().toISOString()
};

// Get the agency reference based on the current user
const getAgencyRef = async () => {
    const user = auth.currentUser;
    if (!user) throw new Error('Usuário não autenticado');

    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) throw new Error('Usuário não encontrado');
    return doc(db, 'agencies', userDoc.data().agencyId);
};

/**
 * Get passenger field settings for the current agency
 * @returns {Promise<Object>} The passenger field settings
 */
export const getPassengerFieldSettings = async () => {
    try {
        const agencyRef = await getAgencyRef();
        const settingsDocRef = doc(collection(agencyRef, 'settings'), 'passengerFields');
        const settingsDoc = await getDoc(settingsDocRef);

        if (!settingsDoc.exists()) {
            // If settings don't exist yet, create them with default values
            await setDoc(settingsDocRef, DEFAULT_PASSENGER_FIELD_SETTINGS);
            return DEFAULT_PASSENGER_FIELD_SETTINGS;
        }

        return settingsDoc.data();
    } catch (error) {
        console.error('Erro ao buscar configurações de campos de passageiro:', error);
        throw error;
    }
};

/**
 * Update passenger field settings
 * @param {Object} settings - The updated settings
 * @returns {Promise<boolean>} True if successful
 */
export const updatePassengerFieldSettings = async (settings) => {
    try {
        const agencyRef = await getAgencyRef();
        const settingsDocRef = doc(collection(agencyRef, 'settings'), 'passengerFields');

        const updatedSettings = {
            ...settings,
            updatedAt: new Date().toISOString()
        };

        await updateDoc(settingsDocRef, updatedSettings);
        return true;
    } catch (error) {
        console.error('Erro ao atualizar configurações de campos de passageiro:', error);
        throw error;
    }
};

/**
 * Reset passenger field settings to default values
 * @returns {Promise<boolean>} True if successful
 */
export const resetPassengerFieldSettings = async () => {
    try {
        const agencyRef = await getAgencyRef();
        const settingsDocRef = doc(collection(agencyRef, 'settings'), 'passengerFields');

        await setDoc(settingsDocRef, DEFAULT_PASSENGER_FIELD_SETTINGS);
        return true;
    } catch (error) {
        console.error('Erro ao redefinir configurações de campos de passageiro:', error);
        throw error;
    }
};

/**
 * Get all agency settings
 * @returns {Promise<Object>} All agency settings
 */
export const getAllAgencySettings = async () => {
    try {
        const settings = {};

        // Get passenger field settings
        const passengerFieldSettings = await getPassengerFieldSettings();
        settings.passengerFields = passengerFieldSettings;

        // Add other settings as needed

        return settings;
    } catch (error) {
        console.error('Erro ao buscar todas as configurações da agência:', error);
        throw error;
    }
};