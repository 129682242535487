import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Divider,
    Chip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    IconButton,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import { getInactivePassengers } from '../services/PassengerService';
import TravelLoading from '../../../shared/common/TravelLoading';
import { exportPassengersToExcel } from '../utils/exportPassengersToExcel';

const PassengerInactivity = () => {
    const theme = useTheme();
    const [passengers, setPassengers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDays, setSelectedDays] = useState(90); // Default to 90 days
    const [messageDialog, setMessageDialog] = useState(false);
    const [selectedPassenger, setSelectedPassenger] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [exportLoading, setExportLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    // Options for filtering
    const daysOptions = [60, 90, 180, 365];

    // Function to fetch inactive passengers
    useEffect(() => {
        const fetchInactivePassengers = async () => {
            setLoading(true);
            try {
                const result = await getInactivePassengers(selectedDays);
                setPassengers(result);
            } catch (err) {
                console.error("Error fetching inactive passengers:", err);
                setError("Não foi possível carregar os dados dos passageiros.");
            } finally {
                setLoading(false);
            }
        };

        fetchInactivePassengers();
    }, [selectedDays]);

    const handleMessageOpen = (passenger) => {
        setSelectedPassenger(passenger);

        // Pre-populate message with reactivation greeting
        const defaultMessage = `Olá, ${passenger.nome}! Sentimos sua falta. Que tal planejar uma nova viagem com a gente?`;
        setMessageText(defaultMessage);

        setMessageDialog(true);
    };

    const handleSendMessage = () => {
        if (!selectedPassenger) return;

        // For WhatsApp, create a link with the message
        if (selectedPassenger.telefone) {
            const phoneNumber = selectedPassenger.telefone.replace(/\D/g, '');
            const whatsappUrl = `https://wa.me/+55${phoneNumber}?text=${encodeURIComponent(messageText)}`;
            window.open(whatsappUrl, '_blank');
        }

        setMessageDialog(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(messageText);
    };

    const formatLastTravelDate = (dateString) => {
        if (!dateString) return 'Sem informação';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const handleExportToExcel = async () => {
        if (passengers.length === 0) {
            setSnackbar({
                open: true,
                message: 'Não há dados para exportar',
                severity: 'warning'
            });
            return;
        }

        setExportLoading(true);
        try {
            const fileName = `passageiros-inativos-${selectedDays}-dias`;
            const success = exportPassengersToExcel(passengers, fileName);

            if (success) {
                setSnackbar({
                    open: true,
                    message: 'Dados exportados com sucesso!',
                    severity: 'success'
                });
            } else {
                throw new Error('Falha na exportação');
            }
        } catch (error) {
            console.error('Error exporting data:', error);
            setSnackbar({
                open: true,
                message: 'Erro ao exportar dados. Tente novamente.',
                severity: 'error'
            });
        } finally {
            setExportLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({
            ...snackbar,
            open: false
        });
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <TravelLoading disableAlignItems />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ mb: 4 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                flexWrap: 'wrap',
                gap: 2
            }}>
                <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeIcon sx={{ mr: 1 }} /> Passageiros Inativos
                </Typography>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 1
                }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>Mostrar sem viajar há:</Typography>
                    {daysOptions.map(days => (
                        <Chip
                            key={days}
                            label={`${days} dias`}
                            onClick={() => setSelectedDays(days)}
                            color={selectedDays === days ? "primary" : "default"}
                            sx={{ mr: 1 }}
                        />
                    ))}
                </Box>
            </Box>

            {/* Export Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={handleExportToExcel}
                    disabled={passengers.length === 0 || exportLoading}
                    sx={{ borderRadius: '50px' }}
                >
                    {exportLoading ? 'Exportando...' : 'Exportar Planilha'}
                </Button>
            </Box>

            {passengers.length === 0 ? (
                <Card variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="body1" color="text.secondary" align="center">
                            Não há passageiros inativos nos últimos {selectedDays} dias.
                        </Typography>
                    </CardContent>
                </Card>
            ) : (
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {passengers.length} Passageiros sem viajar
                        </Typography>
                        <List>
                            {passengers.map((passenger, index) => (
                                <React.Fragment key={passenger.id}>
                                    <ListItem
                                        secondaryAction={
                                            <Box>
                                                {passenger.telefone && (
                                                    <Tooltip title="Enviar Mensagem">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="send message"
                                                            onClick={() => handleMessageOpen(passenger)}
                                                        >
                                                            <WhatsAppIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {passenger.email && (
                                                    <Tooltip title="Enviar Email">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="send email"
                                                            onClick={() => window.location.href = `mailto:${passenger.email}`}
                                                        >
                                                            <EmailIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        }
                                    >
                                        <ListItemText
                                            primary={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1">
                                                        {passenger.nome}
                                                    </Typography>
                                                    <Chip
                                                        size="small"
                                                        label={
                                                            passenger.lastTravelDays === null
                                                                ? "Nunca viajou"
                                                                : `${passenger.lastTravelDays} dias`
                                                        }
                                                        color="warning"
                                                        sx={{
                                                            ml: 1,
                                                            color: 'white', // Force white text color
                                                            '& .MuiChip-label': {
                                                                color: 'white' // Ensure label is also white
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            }
                                            secondary={
                                                <Box>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {passenger.lastTravelDays !== null
                                                            ? `Última viagem em: ${formatLastTravelDate(passenger.lastTravelDate)}`
                                                            : 'Sem registro de viagens'}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {passenger.telefone && `Tel: ${passenger.telefone}`}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </ListItem>
                                    {index < passengers.length - 1 && <Divider component="li" />}
                                </React.Fragment>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            )}

            {/* Message Dialog */}
            <Dialog
                open={messageDialog}
                onClose={() => setMessageDialog(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Reativar Passageiro</DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">
                            Para: {selectedPassenger?.nome}
                        </Typography>
                        {selectedPassenger?.telefone && (
                            <Typography variant="body2" color="text.secondary">
                                Telefone: {selectedPassenger.telefone}
                            </Typography>
                        )}
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Mensagem"
                        type="text"
                        fullWidth
                        rows={4}
                        variant="outlined"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={copyToClipboard}
                        sx={{ mt: 1 }}
                    >
                        Copiar texto
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ borderRadius: '50px' }} variant="outlined" onClick={() => setMessageDialog(false)}>Cancelar</Button>
                    <Button
                        onClick={handleSendMessage}
                        variant="contained"
                        color="primary"
                        disabled={!selectedPassenger?.telefone}
                        sx={{ borderRadius: '50px' }}
                    >
                        Enviar via WhatsApp
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PassengerInactivity;