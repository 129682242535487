import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCPF, formatDate, formatDateForFilename, normalizeValue } from './utils';
import { convertSvgToPng } from './convertSvgToPng';
import { auth, db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { getAgencyLogo } from '../../pages/UpdateAgency/services/LogoService';

export const generateReceiptPDF = async (order, passengers, travel) => {
    // Get agency information
    const user = auth.currentUser;
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    const agencyRef = doc(db, 'agencies', userDoc.data().agencyId);
    const agencyDoc = await getDoc(agencyRef);
    const agencyData = agencyDoc.data();

    // Get agency logo
    let logoData = null;
    try {
        logoData = await getAgencyLogo(userDoc.data().agencyId);
    } catch (error) {
        console.warn('Unable to fetch agency logo:', error);
        // Continue without logo
    }

    const getImageFormat = (base64String, contentType) => {
        if (contentType) {
            if (contentType.includes('jpeg') || contentType.includes('jpg')) return 'JPEG';
            if (contentType.includes('png')) return 'PNG';
            if (contentType.includes('svg')) return 'SVG';
        }

        // Fallback to checking base64 header if content type is not reliable
        if (base64String.startsWith('data:image/jpeg') || base64String.startsWith('data:image/jpg')) return 'JPEG';
        if (base64String.startsWith('data:image/png')) return 'PNG';
        if (base64String.startsWith('data:image/svg')) return 'SVG';

        // Default to PNG if unable to determine
        return 'PNG';
    };

    // Get owner's phone
    const ownerRef = doc(db, 'users', agencyData.ownerId);
    const ownerDoc = await getDoc(ownerRef);
    const ownerPhone = ownerDoc.data().phone;

    const pdfDoc = new jsPDF('portrait', 'pt');

    const detalhesPagamento = order.detalhesPagamento || {};
    const valorTotal = Number(detalhesPagamento.valorTotal || 0);
    const valorPago = Number(detalhesPagamento.valorPago || 0);
    const valorRestante = valorTotal - valorPago;

    const pageHeight = pdfDoc.internal.pageSize.height;
    const pageWidth = pdfDoc.internal.pageSize.width;
    const leftMargin = 30;
    const rightMargin = pageWidth - 30;
    const contentWidth = rightMargin - leftMargin;

    // Function to draw page border
    const drawPageBorder = () => {
        pdfDoc.setDrawColor(200);
        pdfDoc.setLineWidth(0.5);
        pdfDoc.rect(leftMargin, 30, contentWidth, pageHeight - 60);
    };

    // Function to handle page breaks
    const addPageIfNeeded = (currentY) => {
        if (currentY >= pageHeight - 60) {
            pdfDoc.addPage();
            drawPageBorder();
            return 60; // Return new Y position at top of page
        }
        return currentY;
    };

    // Draw border on first page
    drawPageBorder();

    pdfDoc.setFontSize(10);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Recibo de Pagamento', pageWidth / 2, 20, { align: 'center' });

    // Format current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('pt-BR');
    const formattedTime = currentDate.toLocaleTimeString('pt-BR');

    // Helper function to safely format dates
    const safeFormatDate = (date) => {
        if (!date) return 'Não informado';
        try {
            if (typeof date === 'string') {
                if (date.includes('T')) {
                    date = date.split('T')[0];
                }
                const [year, month, day] = date.split('-');
                if (year && month && day) {
                    return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
                }
            } else if (date instanceof Date) {
                return date.toLocaleDateString('pt-BR');
            }
            return 'Não informado';
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Não informado';
        }
    };

    // Handle logo display and header
    let yPos = 45;
    const headerColumnWidth = contentWidth / 3;

    // Add logo if available with centered positioning and larger size
    if (logoData && logoData.base64) {
        try {
            let imageData = logoData.base64;
            let imageFormat = getImageFormat(imageData, logoData.contentType);

            // Convert SVG to PNG if needed
            if (imageFormat === 'SVG') {
                imageData = await convertSvgToPng(logoData.base64);
                imageFormat = 'PNG';
            }

            // Create image element for size calculation
            const img = new Image();
            img.src = imageData;

            // Wait for image to load
            await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = (error) => {
                    console.error('Error loading image:', error);
                    reject(error);
                };
            });

            // Calculate logo dimensions (max height 80px)
            const maxHeight = 80;
            const aspectRatio = img.width / img.height;
            const logoHeight = Math.min(maxHeight, img.height);
            const logoWidth = logoHeight * aspectRatio;

            // Calculate center position
            const centerX = leftMargin + (contentWidth - logoWidth) / 2;

            // Add logo to PDF with correct format
            pdfDoc.addImage(
                imageData,
                imageFormat, // Use detected format instead of hardcoding PNG
                centerX,
                yPos,
                logoWidth,
                logoHeight
            );

            // Adjust yPos for text placement after centered logo
            yPos += logoHeight + 20;
        } catch (error) {
            console.error('Error adding logo to PDF:', error, {
                contentType: logoData.contentType,
                base64Preview: logoData.base64.substring(0, 100) + '...'
            });
        }
    }

    // Add agency info
    pdfDoc.setFontSize(12);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text(agencyData.name, leftMargin + 10, yPos);
    pdfDoc.setFontSize(8);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.text(`Tel: ${agencyData.phone}`, leftMargin + 10, yPos + 12);

    pdfDoc.setFontSize(10);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text(`Pedido: ${order.id}`, leftMargin + 10, yPos + 40);

    // Center Column - Date/Time
    pdfDoc.text(`Data e Hora do Recibo:`, leftMargin + headerColumnWidth + 10, yPos);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.text(`${formattedDate} ${formattedTime}`,
        leftMargin + headerColumnWidth + 10, yPos + 15);

    // Right Column - Order Values
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Valor do Pedido:', leftMargin + (headerColumnWidth * 2) + 10, yPos);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.text(`R$ ${normalizeValue(valorTotal.toFixed(2))}`,
        leftMargin + (headerColumnWidth * 2) + 10, yPos + 15);

    // Update yPos for next section
    yPos += 60;

    // Create a box for each data group
    const createDataBox = (label, value, x, y, width, height = 35) => {
        pdfDoc.setDrawColor(200);
        pdfDoc.setLineWidth(0.5);
        pdfDoc.rect(x, y, width, height);

        pdfDoc.setFontSize(8);
        pdfDoc.setFont('helvetica', 'bold');
        pdfDoc.text(label, x + 5, y + 12);

        pdfDoc.setFontSize(10);
        pdfDoc.setFont('helvetica', 'normal');
        pdfDoc.text(value || 'NÃO INFORMADO', x + 5, y + 27);
    };

    // Payer Information Section
    createDataBox('Nome do Pagador', detalhesPagamento.nomePagador || '',
        leftMargin + 10, yPos, contentWidth - 20);

    yPos += 45;
    yPos = addPageIfNeeded(yPos);
    const payerColumnWidth = (contentWidth - 20) / 2;

    // Display CPF/RG or Passport based on what's available
    if (detalhesPagamento.passaportePagador) {
        createDataBox('Passaporte do Pagador', detalhesPagamento.passaportePagador,
            leftMargin + 10, yPos, payerColumnWidth - 5);
    } else {
        if (detalhesPagamento.cpfPagador) {
            createDataBox('CPF do Pagador', formatCPF(detalhesPagamento.cpfPagador),
                leftMargin + 10, yPos, payerColumnWidth - 5);
        }
        if (detalhesPagamento.rgPagador) {
            createDataBox('RG do Pagador', detalhesPagamento.rgPagador,
                leftMargin + payerColumnWidth + 15, yPos, payerColumnWidth - 5);
        }
    }

    // Travel Information
    yPos += 45;
    yPos = addPageIfNeeded(yPos);
    const columnWidth = (contentWidth - 20) / 2;

    // Origem e Destino em boxes separados
    createDataBox('Local de Origem', travel?.origem || '',
        leftMargin + 10, yPos, columnWidth - 5);

    createDataBox('Local de Destino', travel?.destino || '',
        leftMargin + columnWidth + 15, yPos, columnWidth - 5);

    // Travel Dates
    yPos += 45;
    yPos = addPageIfNeeded(yPos);
    const saidaFormatted = travel?.dataIda
        ? `${safeFormatDate(travel.dataIda)}${travel.horarioIda ? ' ' + travel.horarioIda : ''}`
        : 'Não informado';
    createDataBox('Data e Horário de Saída', saidaFormatted,
        leftMargin + 10, yPos, columnWidth - 5);

    // Format return date and time
    let retornoFormatted;
    if (travel?.somenteIda) {
        retornoFormatted = 'SOMENTE IDA';
    } else if (travel?.dataRetorno) {
        retornoFormatted = `${safeFormatDate(travel.dataRetorno)}${travel.horarioRetorno ? ' ' + travel.horarioRetorno : ''}`;
    } else {
        retornoFormatted = 'Não informado';
    }
    createDataBox('Data e Horário de Retorno', retornoFormatted,
        leftMargin + columnWidth + 15, yPos, columnWidth - 5);

    if (travel?.descricao && travel.descricao.trim()) {
        yPos += 45;
        yPos = addPageIfNeeded(yPos);

        // Create a box for description
        pdfDoc.setDrawColor(200);
        pdfDoc.setLineWidth(0.5);

        // Split description text into multiple lines if needed
        pdfDoc.setFontSize(10);
        pdfDoc.setFont('helvetica', 'normal');
        const textLines = pdfDoc.splitTextToSize(travel.descricao, contentWidth - 30);

        // Calculate box height based on number of lines (each line ~15pt + padding)
        const lineHeight = 15;
        const boxHeight = Math.max(35, textLines.length * lineHeight + 20); // Min height 35px

        // Draw the box
        pdfDoc.rect(leftMargin + 10, yPos, contentWidth - 20, boxHeight);

        // Add the label
        pdfDoc.setFontSize(8);
        pdfDoc.setFont('helvetica', 'bold');
        pdfDoc.text('Descrição', leftMargin + 15, yPos + 12);

        // Add the text content with proper line breaks
        pdfDoc.setFontSize(10);
        pdfDoc.setFont('helvetica', 'normal');
        pdfDoc.text(textLines, leftMargin + 15, yPos + 27);

        // Update yPos for next section
        yPos += boxHeight + 15;
    } else {
        // Add spacing even when there's no description
        yPos += 50; // Add the same spacing as if there were a description field
        yPos = addPageIfNeeded(yPos);
    }

    // Reservation Details Section
    pdfDoc.setFontSize(11);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Detalhes das Reservas', leftMargin + 10, yPos);

    yPos += 15;
    const activeReservations = order.reservations?.filter(reservation => reservation.status !== 'Cancelada') || [];

    if (activeReservations.length > 0) {
        const reservationTableData = activeReservations.map(reservation => {
            const passenger = passengers.find(p => p.id === reservation.passengerId) || {};
            const seatInfo = reservation.lapPassenger ? 'Ocupante de Colo' :
                (reservation.numeroAssento !== null ? reservation.numeroAssento.toString() : 'Sem Assento');
            const docInfo = passenger.estrangeiro ?
                `Passaporte: ${passenger.passaporte || 'Não informado'}` :
                `RG: ${passenger.rg || 'Não informado'}`;

            return [
                seatInfo,
                passenger.nome || 'Não informado',
                passenger.cpf ? formatCPF(passenger.cpf) : 'Não informado',
                docInfo
            ];
        });

        pdfDoc.autoTable({
            startY: yPos,
            head: [['Assento', 'Nome do Passageiro', 'CPF', 'Documento']],
            body: reservationTableData,
            styles: {
                fontSize: 8,
                cellPadding: 5
            },
            headStyles: {
                fillColor: [240, 240, 240],
                textColor: [0, 0, 0],
                fontSize: 9,
                fontStyle: 'bold'
            },
            margin: { left: leftMargin + 10, right: rightMargin - 10 },
            tableWidth: contentWidth - 20,
            didDrawPage: () => {
                drawPageBorder();
            }
        });

        // Update yPos after the reservation table
        yPos = pdfDoc.lastAutoTable.finalY + 20;
        yPos = addPageIfNeeded(yPos);
    }

    // Payment Details Section
    pdfDoc.setFontSize(11);
    pdfDoc.setFont('helvetica', 'bold');
    pdfDoc.text('Detalhes dos Pagamentos', leftMargin + 10, yPos);

    yPos += 15;
    if (detalhesPagamento.pagamentos?.length > 0) {
        pdfDoc.autoTable({
            startY: yPos,
            head: [['Data', 'Valor', 'Método de Pagamento', 'Status']], 
            body: detalhesPagamento.pagamentos.map(registro => [
                safeFormatDate(registro.data),
                `R$ ${normalizeValue(registro.valor)}`,
                registro.metodoPagamento,
                registro.statusPagamento || 'Pago' 
            ]),
            styles: {
                fontSize: 9,
                cellPadding: 5
            },
            headStyles: {
                fillColor: [240, 240, 240],
                textColor: [0, 0, 0],
                fontSize: 9,
                fontStyle: 'bold'
            },
            margin: { left: leftMargin + 10, right: rightMargin - 10 },
            tableWidth: contentWidth - 20,
            didDrawPage: () => {
                drawPageBorder();
            }
        });

        // Update yPos after the payment table
        yPos = pdfDoc.lastAutoTable.finalY + 20;
        yPos = addPageIfNeeded(yPos);
    }

    // Summary boxes at the bottom right
    const summaryY = pageHeight - 140;
    const summaryWidth = 160;
    createDataBox('Total Pago', `R$ ${normalizeValue(valorPago.toFixed(2))}`,
        rightMargin - summaryWidth - 10, summaryY, summaryWidth, 30);
    createDataBox('Valor Restante', `R$ ${normalizeValue(valorRestante.toFixed(2))}`,
        rightMargin - summaryWidth - 10, summaryY + 40, summaryWidth, 30);

    // Footer with clickable link
    pdfDoc.setFontSize(8);
    pdfDoc.setFont('helvetica', 'normal');
    pdfDoc.setTextColor(0, 0, 0); // Reset to black color

    // Calculate text widths for proper positioning
    const prefix = 'Recibo gerado automaticamente por ';
    const linkText = 'Travell - Gerenciamento de Viagens e Excursões';
    const prefixWidth = pdfDoc.getTextWidth(prefix);
    const linkWidth = pdfDoc.getTextWidth(linkText);

    // Calculate starting position to center the entire text
    const totalWidth = prefixWidth + linkWidth;
    const startX = (pageWidth - totalWidth) / 2;

    // Draw the first part of text
    pdfDoc.text(prefix, startX, pageHeight - 40);

    // Add blue clickable text
    pdfDoc.setTextColor(0, 0, 255); // Set to blue color
    pdfDoc.link(startX + prefixWidth, pageHeight - 45, linkWidth, 10, { url: 'https://travell.com.br' });
    pdfDoc.text(linkText, startX + prefixWidth, pageHeight - 40);

    // Save the PDF
    const filename = `pedido_${order.id}.pdf`;
    pdfDoc.save(filename);
};