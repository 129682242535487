import * as XLSX from 'xlsx';

export const exportPassengersToExcel = (passengers, fileName = 'passageiros-inativos') => {
    try {
        const formattedData = passengers.map(passenger => {
            let formattedBirthDate = '';
            if (passenger.dataNascimento) {
                const [year, month, day] = passenger.dataNascimento.split('-');
                formattedBirthDate = `${day}/${month}/${year}`;
            }

            return {
                'Nome': passenger.nome || '',
                'Data de Nascimento': formattedBirthDate,
                'Telefone': passenger.telefone || '',
                'Dias Sem Viajar': passenger.lastTravelDays === null ? 'Nunca viajou' : passenger.lastTravelDays,
                'Última Viagem': passenger.lastTravelDate
                    ? formatDate(passenger.lastTravelDate)
                    : 'Nunca viajou'
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Passageiros Inativos');

        const today = new Date();
        const dateStr = today.toISOString().split('T')[0]; 
        const fullFileName = `${fileName}_${dateStr}.xlsx`;

        XLSX.writeFile(workbook, fullFileName);

        return true;
    } catch (error) {
        console.error('Error exporting data to Excel:', error);
        return false;
    }
};

const formatDate = (dateString) => {
    if (!dateString) return '';

    try {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    } catch (error) {
        return dateString;
    }
};