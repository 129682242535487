import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Collapse,
  Typography,
  Divider,
  Paper,
  Grid
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

import TravelLoading from '../../../shared/common/TravelLoading';

import { uploadAgencyLogo, getAgencyLogo, deleteAgencyLogo } from '../services/LogoService';
import { db } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../../core/contexts/useAuthState';
import { formatTelefone } from '../../../shared/utils/utils';

const UpdateAgencyPage = () => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    agencyName: '',
    phone: '',
    registrationType: '',
    stats: {
      totalUsers: 0,
      totalVehicles: 0,
      totalTravels: 0
    },
    subscription: {
      planId: '',
      status: '',
      startDate: '',
      endDate: null
    }
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);
  const [logoData, setLogoData] = useState(null);
  const [logoLoading, setLogoLoading] = useState(false);
  const [logoError, setLogoError] = useState('');

  useEffect(() => {
    const fetchAgencyData = async () => {
      try {
        if (currentUser?.uid) {
          const [agencyDoc, logo] = await Promise.all([
            getDoc(doc(db, 'agencies', currentUser.uid)),
            getAgencyLogo(currentUser.uid)
          ]);

          const agencyData = agencyDoc.data();

          if (agencyData) {
            setFormData({
              agencyName: agencyData.name || '',
              phone: agencyData.phone || '',
              registrationType: agencyData.metadata?.registrationType || 'personal',
              stats: {
                totalUsers: agencyData.stats?.totalUsers || 0,
                totalVehicles: agencyData.stats?.totalVehicles || 0,
                totalTravels: agencyData.stats?.totalTravels || 0
              },
              subscription: {
                planId: agencyData.subscription?.planId || '',
                status: agencyData.subscription?.status || '',
                startDate: agencyData.subscription?.startDate || '',
                endDate: agencyData.subscription?.endDate || null
              }
            });

            if (logo) {
              setLogoData(logo);
            }
          }
          setInitialLoad(false);
        }
      } catch (error) {
        console.error('Error fetching agency data:', error);
        setError('Erro ao carregar dados da agência');
        setInitialLoad(false);
      }
    };

    fetchAgencyData();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      setFormData(prev => ({
        ...prev,
        [name]: formatTelefone(value)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const validateForm = () => {
    if (!formData.agencyName.trim()) {
      setError('O nome da agência não pode estar vazio');
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const agencyRef = doc(db, 'agencies', currentUser.uid);
      await updateDoc(agencyRef, {
        name: formData.agencyName.trim(),
        phone: formData.phone,
        'metadata.updatedAt': new Date().toISOString()
      });

      setSuccessMessage('Informações da agência atualizadas com sucesso!');
    } catch (error) {
      console.error('Error updating agency:', error);
      setError('Erro ao atualizar informações da agência');
    } finally {
      setLoading(false);
    }
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLogoLoading(true);
    setLogoError('');

    try {
      const base64 = await uploadAgencyLogo(currentUser.uid, file);
      setLogoData({ base64, fileName: file.name });
      setSuccessMessage('Logo atualizada com sucesso!');
    } catch (error) {
      setLogoError(error.message);
    } finally {
      setLogoLoading(false);
    }
  };

  const handleLogoDelete = async () => {
    setLogoLoading(true);
    setLogoError('');

    try {
      await deleteAgencyLogo(currentUser.uid);
      setLogoData(null);
      setSuccessMessage('Logo removida com sucesso!');
    } catch (error) {
      setLogoError(error.message);
    } finally {
      setLogoLoading(false);
    }
  };

  if (initialLoad) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <TravelLoading />
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
      <Paper sx={{ p: 3, mb: 3 }} elevation={0}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Informações da Agência
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            {logoData && (
              <Box mb={2} display="flex" justifyContent="center">
                <img
                  src={logoData.base64}
                  alt="Logo da agência"
                  style={{
                    width: '100%',
                    height: '80px',
                    objectFit: 'contain'
                  }}
                />
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="column"
              gap={1}
            >
              <input
                type="file"
                accept="image/jpeg,image/png,image/svg+xml"
                style={{ display: 'none' }}
                id="logo-upload"
                onChange={handleLogoUpload}
                disabled={logoLoading}
              />
              <label htmlFor="logo-upload" style={{ width: '100%' }}>
                <Button
                  variant="contained"
                  component="span"
                  startIcon={logoLoading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
                  disabled={logoLoading}
                  sx={{ borderRadius: '50px' }}
                >
                  {logoData ? 'Alterar Logo' : 'Cadastrar Logo'}
                </Button>
              </label>

              {logoData && (
                <label htmlFor="logo-upload" style={{ width: '100%' }}>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleLogoDelete}
                    disabled={logoLoading}
                    sx={{ borderRadius: '50px' }}
                  >
                    Remover Logo
                  </Button>
                </label>
              )}
            </Box>

            {logoError && (
              <Typography
                color="error"
                variant="caption"
                sx={{
                  display: 'block',
                  mt: 1,
                  fontSize: '0.75rem'
                }}
              >
                {logoError}
              </Typography>
            )}

            <Typography
              variant="caption"
              sx={{
                display: 'block',
                mt: 1,
                fontSize: '0.75rem',
                color: 'text.secondary'
              }}
            >
              Formatos aceitos: JPG, PNG e SVG. Tamanho máximo: 2MB
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="agencyName"
              label="Nome da Agência"
              id="agencyName"
              value={formData.agencyName}
              onChange={handleChange}
              error={!formData.agencyName.trim()}
              helperText={!formData.agencyName.trim() ? 'Nome da agência é obrigatório' : ''}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="phone"
              label="Telefone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="(00) 00000-0000"
            />


            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="registrationType"
              label="Tipo de Registro"
              value={formData.registrationType === 'personal' ? 'Pessoa Física' : 'Empresa'}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }} elevation={0}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Estatísticas
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Total de Usuários"
              value={formData.stats.totalUsers}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Total de Veículos"
              value={formData.stats.totalVehicles}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Total de Viagens"
              value={formData.stats.totalTravels}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }} elevation={0}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Assinatura
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Plano"
              value={formData.subscription.planId}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Status"
              value={formData.subscription.status}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Data de Início"
              value={new Date(formData.subscription.startDate).toLocaleDateString()}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Data de Término"
              value={formData.subscription.endDate ? new Date(formData.subscription.endDate).toLocaleDateString() : 'N/A'}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Button
        type="submit"
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          borderRadius: '50px',
          ml: 'auto',
          display: 'block'
        }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Salvar Alterações'}
      </Button>

      <Collapse in={!!error} timeout={500}>
        <Box sx={{ width: '100%', mb: 2 }}>
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      </Collapse>

      <Collapse in={!!successMessage} timeout={500}>
        <Box sx={{ width: '100%', mb: 2 }}>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
        </Box>
      </Collapse>
    </Box>
  );
};

export default UpdateAgencyPage;